/* eslint-disable react/jsx-no-bind */
import formatRupiah from 'helpers/formatRupiah';
import React from 'react';
import { Label } from 'reactstrap';

export default function Inputs(props) {
  const {
    placeholder,
    type,
    name,
    id,
    label,
    formik,
    disabled,
      value,
    isCurrency,
    currency_name,
    accept,
    readOnly,
  } = props;

  const handleOnKeyUp = e => {
    
    if (isCurrency && currency_name) {
      const format = formatRupiah(e.target.value);
      props.formik.setFieldValue(props.currency_name, format);
      const value = e.target.value.split('.').join('').replace('Rp ', '');
      props.formik.setFieldValue(props.name, value);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  return (
    <div>
      <Label>{ label }</Label>
      <input
        className='form-control'
        id={ id }
        accept={ accept ?? '' }
        type={ type }
        placeholder={ placeholder }
        name={ name }
        value={ value }
        readOnly={ readOnly }
        // onChange={ e => formik.setFieldValue(name, e.target.value) }
        onChange={ e => handleOnKeyUp(e) }
        autoComplete='off'
        // onKeyUp={ event => console.log(event.target.value) }
        disabled={ disabled }
      />
      { formik.touched[name] && formik.errors[name] ? (
        <div style={ { color: 'red' } }>{ formik.errors[name] }</div>
      ) : null }
      
    </div>
  );
}
