/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import ModalPhotos from 'components/Modal/ModalPhotos';
import { addDefaultSrc } from 'helpers/brokenImg';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

export default function TableArrow(props) {
  const [urlImg, setUrlImg] = useState('');
  const [isOpenImg, setIsOpenImg] = useState(false);
  const { title, subTitle, infoDate, content, img } = props;

  const handleShowImage = url => {
    setUrlImg(url);
    setIsOpenImg(true);
  };
  
  return (
    <Row className='sec-list-tab'>
      <Col md='3'>
        <div className='sub-col-tabs-list'>
          <span
            className='sub-text-tabs-list'
            style={ { textTransform: 'capitalize' } }
          >
            { title }
          </span>
          <span
            className='sub-sec-tabs-list'
            style={ { textTransform: 'capitalize' } }
          >
            { subTitle }
          </span>
          <br />
          <span className='sub-sec-tabs-list'>{ infoDate }</span>
        </div>
      </Col>
      <Col md='9'>
        <div className='sub-col-tabs-list'>
          <Row>
            { img.map(path => {
              return (
                <div
                  className='img-list mx-2'
                  onClick={ () => handleShowImage(path.image_link) }
                  style={ { cursor: 'pointer' } }
                  >
                  <img
                    onError={ addDefaultSrc }
                    src={ `${process.env.REACT_APP_BE_URL}/${path.image_link}` }
                    height={ 60 }
                    width={ 60 }
                    alt='img-default'
                    className='img-thumbnail'
                  />
                </div>
              );
            }) }
          </Row>
          <span>{ content }</span>
        </div>
      </Col>
      <ModalPhotos
        isOpen={ isOpenImg }
        toggle={ () => setIsOpenImg(!isOpenImg) }
        isDetailPhotos={ urlImg }
      />
    </Row>
  );
}
