/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
// react plugin that prints a given react component
// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Card, Container } from 'reactstrap';

// core components
import API from 'service/api/config';
import { Link } from 'react-router-dom';
import ModalSaveFormClient from 'components/Molecules/client/SaveFormClient';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({  onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{ ' ' }
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={ e => onSizePerPageChange(e.target.value) }
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{ ' ' }
        entries.
      </label>
    </div>
  ),
});

function SecurityDetailPages(props) {
  const [userData, setUserData] = React.useState([]);
  const componentRef = React.useRef(null);
  const [modalTambahClient, setModalTambahClient] = React.useState(false);

  const toggleModalTambahClient = () => {
    setModalTambahClient(!modalTambahClient);
  };

  useEffect(() => {
    API.getUserData('SECURITY', props?.data).then(res => {
      setUserData(res?.data?.data);
    });
  }, []);

  const detailHandleEvaluasi = user_id => {
    return (
      <div
        style={ { textAlign: 'center', cursor: 'pointer', lineHeight: 'normal' } }
      >
        <button
          style={ {
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
          } }
        >
          <Link
            to={ `profiles/${user_id}` }
            style={ { color: '#6174DD', fontWeight: 'bold' } }
          >
            Lihat Profile
          </Link>
        </button>
      </div>
    );
  };

  return (
    <>
      <ModalSaveFormClient
        toggle={ toggleModalTambahClient }
        modal={ modalTambahClient }
      />
        <Card>
          <ToolkitProvider
            data={ userData }
            keyField={ userData }
            columns={ [
              {
                dataField: 'name',
                text: 'NAMA',
                sort: true,
              },
              {
                dataField: 'role.role_name',
                text: 'ROLE',
                sort: true,
              },
              {
                dataField: 'phone',
                text: 'NO. TELEPON',
                sort: true,
              },
              {
                dataField: 'cluster.cluster_name',
                text: 'CLUSTER',
                sort: true,
              },
              {
                dataField: '',
                text: '',
              },
              {
                dataField: 'user_id',
                text: '',
                sort: false,
                formatter: user_id => detailHandleEvaluasi(user_id),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: 'EditRow' },
              },
              {
                dataField: '',
                text: '',
              },
            ] }
            search
          >
            { props => (
              <div className='py-4 table-responsive'>
                <BootstrapTable
                  ref={ componentRef }
                  { ...props.baseProps }
                  bootstrap4={ true }
                  pagination={ pagination }
                  bordered={ false }
                  id='react-bs-table'
                />
              </div>
            ) }
          </ToolkitProvider>
        </Card>
    </>
  );
}

export default SecurityDetailPages;
