/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import DetailTemuanPatroli from './DetailTemuanPatroli';

export default function TableArrowTerlapor(props) {
  const { data } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isDetail, setDetail] = useState();
  
  return (
    <Row className='sec-list-tab'>
      <Col md='3'>
        <div>
          <span className='title-text' style={ { textTransform: 'capitalize' } }>
            { data.nama }
          </span>
        </div>
        <div className='py-1'>
          <p className='title-sub'>{ data.user.name }</p>
        </div>
        <div>
          <p className='title-sub'>{ data.jam_mulai }</p>
        </div>
        <div>
          <p className='title-sub'>{ data.cluster.cluster_name }</p>
        </div>
        <div className='title-sub mt-3'>
          <p className='title-sub'>{ data.tanggal }</p>
        </div>
      </Col>
      <Col md='9' style={ { borderLeft: '1px solid #E9ECEF' } }>
        {
          data.titik_data.map((val, i) => {
            return (
              <div
                key={ i }
                onClick={ () => { setIsOpen(!isOpen), setDetail(val); } }
                style={ { cursor: 'pointer' } }
                className='d-flex justify-content'
              >
                <div>
                  <span className='dot' style={ { backgroundColor: val.status ? '#54D68E' : '#9B9FB7' } } />
                </div>
                <div className='pl-3'>
                  <div>
                    <p className='title-text'>{ val.titik?.nama_titik }</p>
                  </div>
                  <div>
                    <p className='title-sub'>{ val.status ? val.jam : '' }</p>
                  </div>
                </div>
              </div>
            );
          })
        }
      </Col>
      <DetailTemuanPatroli data={ isDetail } isOpen={ isOpen } toggle={ () => setIsOpen(!isOpen) } />
    </Row>
  );
}
