/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Button, Card, Container, Row, Col } from "reactstrap";
import ModalEvaluasi from "components/Modal/Evaluasi";
import API from "service/api/config";
import Paginations from "components/Tabels/Paginations";
import { GET_DETAIL_SECURITY } from "../../../../store/security/actionTypes";
import ModalDeleteSecurity from "../../../../components/Molecules/security/ModalDeleteSecurity";
import { useDispatch } from "react-redux";
import { ROLE_ADMIN } from "helpers/types";

function SecurityPages() {
  const componentRef = React.useRef(null);
  const [userData, setUserData] = useState([]);
  const [evaluasiData, setEvaluasiData] = useState({});
  const [modalTambahClient, setModalTambahClient] = React.useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [reRender, setReRender] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = (row) => {
    dispatch({
      type: GET_DETAIL_SECURITY,
      payload: row,
    });
    setModalDelete(!modalDelete);
  };
  const roles = localStorage.getItem("role");
  const data = JSON.parse(localStorage.getItem("userData"));
  const valueData = data?.client?.client_id;
  const { SearchBar } = Search;

  function evaluasiHandle(user_id) {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
          margin: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          <span
            style={{ color: "#6174DD", fontWeight: "bold" }}
            onClick={() => toggleEvaluasi(user_id)}
          >
            Evaluasi
          </span>
        </button>
      </div>
    );
  }

  function absensiHandle(user_id) {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
          margin: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          <Link
            to={`detailAbsensi-${user_id}`}
            style={{ color: "#6174DD", fontWeight: "bold" }}
          >
            Absensi
          </Link>
        </button>
      </div>
    );
  }

  const detailHandleEvaluasi = (user_id) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
          margin: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          <Link
            to={`profiles/${user_id}`}
            style={{ color: "#6174DD", fontWeight: "bold" }}
          >
            Lihat Profile
          </Link>
        </button>
      </div>
    );
  };

  const detailHandleDelete = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
          margin: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          }}
          onClick={() => handleDelete(row)}
        >
          {/* <span style={{color: "#6174DD", fontWeight:"bold"}}>Profile</span> */}
          <i
            className="fas fa-trash-alt"
            style={{ color: "rgb(155, 159, 183)" }}
          />
        </button>
      </div>
    );
  };

  const toggleEvaluasi = (props) => {
    setEvaluasiData(props);
    setModalTambahClient(!modalTambahClient);
  };

  const toggleAbsensi = (props) => {};

  useEffect(() => {
    API.getUserData(
      "SECURITY",
      roles !== "SUPERADMIN" && roles !== ROLE_ADMIN ? valueData : "",
    ).then((res) => {
      setUserData(res?.data?.data);
    });
  }, [reRender]);

  return (
    <>
      <ModalEvaluasi
        toggle={toggleEvaluasi}
        modal={modalTambahClient}
        data={evaluasiData}
      />
      <ModalDeleteSecurity
        isOpen={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        setReRender={() => setReRender(!reRender)}
      />
      <Card body>
        {roles === "CLIENT" ? (
          <ToolkitProvider
            data={userData}
            keyField={userData}
            columns={[
              {
                dataField: "name",
                text: "NAMA",
                sort: true,
              },
              {
                dataField: "role.role_name",
                text: "ROLE",
                sort: true,
              },
              {
                dataField: "phone",
                text: "NO. TELEPON",
                sort: true,
              },
              {
                dataField: "client.client_name",
                text: "CLIENT",
                sort: true,
              },
              {
                dataField: "cluster.cluster_name",
                text: "CLUSTER",
                sort: true,
              },
              {
                dataField: "user_id",
                text: "",
                sort: false,
                formatter: (user_id) => detailHandleEvaluasi(user_id),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
            ]}
            search
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <Container fluid>
                  <Row>
                    <Col xs={12} sm={5}>
                      {roles !== "CLIENT" ? (
                        <>
                          <button className="btn-head-table">
                            <Link
                              to={"tambah-pengguna"}
                              style={{ color: "#fff", fontWeight: "bold" }}
                              className="btn-head-table"
                            >
                              + Security
                            </Link>
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col className="coloumn-table-head">
                      <div className="wrap-head-table">
                        <Button
                          className="buttons-copy buttons-html5"
                          color="black"
                          size="sm"
                          id="copy-tooltip"
                        >
                          <span>XLS</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <BootstrapTable
                  ref={componentRef}
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                  id="react-bs-table"
                />
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <ToolkitProvider
            data={userData}
            keyField={userData}
            columns={[
              {
                dataField: "name",
                text: "NAMA",
                sort: true,
              },
              {
                dataField: "role.role_name",
                text: "ROLE",
                sort: true,
              },
              {
                dataField: "phone",
                text: "NO. TELEPON",
                sort: true,
              },
              {
                dataField: "client.client_name",
                text: "CLIENT",
                sort: true,
              },
              {
                dataField: "cluster.cluster_name",
                text: "CLUSTER",
                sort: true,
              },
              {
                dataField: "user_id",
                text: "",
                sort: false,
                formatter: (user_id) => absensiHandle(user_id),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
              {
                dataField: "user_id",
                text: "",
                sort: false,
                formatter: (user_id) => evaluasiHandle(user_id),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
              {
                dataField: "user_id",
                text: "",
                sort: false,
                formatter: (user_id) => detailHandleEvaluasi(user_id),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
              {
                dataField: "",
                text: "",
                sort: false,
                formatter: detailHandleDelete,
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
            ]}
            search
          >
            {(props) => (
              <div className="table-responsive">
                <Container fluid>
                  <Row>
                    <Col xs={12} sm={5} className="mb-3">
                      {localStorage.getItem("role") == "SUPERADMIN" && (
                        <button className="btn-head-table">
                          <Link
                            to={"tambah-pengguna"}
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            className="btn-head-table"
                          >
                            + Security
                          </Link>
                        </button>
                      )}
                    </Col>
                    <Col className="mb-3"></Col>
                    <Col className="mb-3">
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <SearchBar {...props.searchProps} />
                      </div>
                    </Col>
                  </Row>
                </Container>
                <BootstrapTable
                  ref={componentRef}
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={Paginations}
                  bordered={false}
                  id="react-bs-table"
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </Card>
    </>
  );
}

export default SecurityPages;
