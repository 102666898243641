/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

export default function MySelect(props) {
  const { label, name, formik, labelHidden } = props;
  return (
    <div style={ { width: '200px' } }>
      <Label hidden={ labelHidden }>{ label }</Label>
      <Select
         defaultValue={ props.selected }
         name={ props.name }
         placeholder={ props.placeholder }
         options={ props.options }
         isDisabled={ props.isDisabled }
         isOptionDisabled={ props.disableProps }
         components={ {
           IndicatorSeparator: () => null,
         } }
         onChange={ props.isCustome ? (value) => props.onChange(value) : (value) => {
           props.formik.setFieldValue(props.name, value.value);
         } }
      />
      { formik.touched[name] && formik.errors[name] ? (
        <div style={ { color: 'red' } }>{ formik.errors[name] }</div>
      ) : null }
    </div>
  );
}
