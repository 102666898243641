/* eslint-disable react/jsx-no-bind */
import AdminLayout from '../layouts/Admin';
import SuperAdmin from '../layouts/SuperAdmin';
import Client from '../layouts/Client';
import ProfilePages from '../views/pages/profile';
import LoginPages from 'views/pages/auth/login/Login';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import NonAuthmiddleware from './NonAuthmiddleware';
import { publicRoute } from './publicRoutes';

const Router = () => {
  const role = window.localStorage.getItem('role');
  return (
      <BrowserRouter >
        <Switch>
          {/* <Route path='/admin' render={ props => <AdminLayout { ...props } /> } />
          <Route
            path='/superadmin'
            render={ props => {
              return <SuperAdmin { ...props } />;
            } }
          />
          <Route path='/client' render={ props => <Client { ...props } /> } /> */}
          <Route path={'/'+ role?.toLowerCase()} render={ props =>{
            let component;
            switch (role) {
              case 'ADMIN':
                component = <AdminLayout { ...props } />;
                break;
              case 'SUPERADMIN':
                component = <SuperAdmin { ...props } />;
                break;
              case 'CLIENT':
                component = <Client { ...props } />;
                break;
              default:
                component = <LoginPages { ...props } />;
                break;
            }


            return component;

          } }/>
          { publicRoute.map((route, idx) => {
            return (
              <NonAuthmiddleware
                path={ route.path }
                component={ route.component }
                key={ idx }
              />
            );
          }) }
          <Route
            path='/profile'
            render={ props => <ProfilePages { ...props } /> }
          />
          <Route path='/' render={ props => <LoginPages { ...props } /> } />
          <Redirect from='*' to='/' />
        </Switch>
      </BrowserRouter>
  );
};

export default Router;
