/* eslint-disable react/jsx-no-bind */
import Inputs from "components/Forms";
import React from "react";
import { Col, Input, Label, ModalBody, Row } from "reactstrap";

export default function ComponentFormClient(props) {
  const { formik, onChangePhoto, onChangeFiles } = props;
  return (
    <ModalBody>
      <div className="wrap-form">
        <Inputs
          label="Nama Perusahaan"
          formik={formik}
          placeholder="Your Name"
          type="text"
          name="client_name"
          id="companyName"
          value={formik.values.client_name}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Periode Kontrak"
          formik={formik}
          placeholder="Periode Kontrak"
          type="text"
          name="periode_kontrak"
          id="periode_kontrak"
          value={formik.values.periode_kontrak}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Nomor Kontrak"
          formik={formik}
          placeholder="Nomor Kontrak"
          type="text"
          name="no_kontrak"
          id="no_kontrak"
          value={formik.values.no_kontrak}
        />
      </div>
      <div className="wrap-form">
        <Row>
          <Col>
            <Inputs
              label="Email"
              formik={formik}
              placeholder="email"
              type="text"
              name="client_email"
              value={formik.values.client_email}
            />
          </Col>
          <Col>
            <Inputs
              label="No. Telepon"
              formik={formik}
              placeholder="No. Telepon"
              type="number"
              name="client_phone"
              value={formik.values.client_phone}
            />
          </Col>
        </Row>
      </div>
      <div className="wrap-form">
        <Inputs
          label="Alamat"
          formik={formik}
          placeholder="Jl.Kemang"
          type="text"
          name="alamat"
          value={formik.values.alamat}
        />
      </div>
      <Row>
        <Col>
          <div className="wrap-form">
            <Inputs
              label="Kota"
              formik={formik}
              placeholder="Jakarta"
              type="text"
              name="kota"
              value={formik.values.kota}
            />
          </div>
        </Col>
        <Col>
          <div className="wrap-form">
            <Inputs
              label="Provinsi"
              formik={formik}
              placeholder="DKI Jakarta"
              type="text"
              name="provinsi"
              value={formik.values.provinsi}
            />
          </div>
        </Col>
        <Col>
          <div className="wrap-form">
            <Inputs
              label="Kode Pos"
              formik={formik}
              placeholder="40161"
              type="number"
              name="kode_pos"
              value={formik.values.kode_pos}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <div className="wrap-form">
        <Label className="tit-upload">Document Penunjang</Label>
        <Input
          name="document_penunjang"
          className="upload-doc"
          type="file"
          accept="application/pdf"
          onChange={(e) => {
            formik.handleChange(e);
            onChangeFiles(e);
          }}
        />
        {formik.touched.document_penunjang &&
        formik.errors.document_penunjang ? (
          <div style={{ color: "red" }}>{formik.errors.document_penunjang}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label className="tit-upload">Logo Perushaan</Label>
        <Input
          name="image_link"
          className="upload-doc"
          type="file"
          accept="image/*"
          onChange={(e) => {
            formik.handleChange(e);
            onChangePhoto(e);
          }}
        />
        {formik.touched.image_link && formik.errors.image_link ? (
          <div style={{ color: "red" }}>{formik.errors.image_link}</div>
        ) : null}
      </div>
      <hr />
      <div className="wrap-form">
        <Inputs
          label="Jam Kerja"
          formik={formik}
          placeholder="jam"
          type="number"
          name="jam_kerja"
          value={formik.values.jam_kerja}
        />
      </div>
      <hr />
      <div className="wrap-form">
        <Inputs
          label="Gaji Pokok (Sebelum 2 Tahun)"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="gaji_pokok"
          value={formik.values.gaji_pokok_format}
          isCurrency={true}
          currency_name="gaji_pokok_format"
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Gaji Pokok (Setelah 2 Tahun)"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="gaji_pokok_2"
          value={formik.values.gaji_pokok_2_format}
          isCurrency={true}
          currency_name="gaji_pokok_2_format"
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Jabatan (Danru)"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_jabatan_danru"
          value={formik.values.tunjangan_jabatan_danru_format}
          isCurrency={true}
          currency_name="tunjangan_jabatan_danru_format"
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Jabatan (Chief)"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_jabatan_chief"
          value={formik.values.tunjangan_jabatan_chief_format}
          isCurrency={true}
          currency_name="tunjangan_jabatan_chief_format"
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Makanan"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_makan"
          currency_name="tunjangan_makan_format"
          isCurrency={true}
          value={formik.values.tunjangan_makan_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Kehadiran"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_kehadiran"
          isCurrency={true}
          currency_name="tunjangan_kehadiran_format"
          value={formik.values.tunjangan_kehadiran_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Ops"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_ops"
          isCurrency={true}
          currency_name="tunjangan_ops_format"
          value={formik.values.tunjangan_ops_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Lembur"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_lembur"
          isCurrency={true}
          currency_name="tunjangan_lembur_format"
          value={formik.values.tunjangan_lembur_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Tunjangan Lain"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="tunjangan_lain"
          isCurrency={true}
          currency_name="tunjangan_lain_format"
          value={formik.values.tunjangan_lain_format}
        />
      </div>
      <hr />
      <div className="wrap-form">
        <Inputs
          label="BPJS Kesehatan"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="bpjs_kesehatan"
          isCurrency={true}
          currency_name="bpjs_kesehatan_format"
          value={formik.values.bpjs_kesehatan_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="BPJS Ketenagakerjaan"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          isCurrency={true}
          name="bpjs_ketenagakerjaan"
          currency_name="bpjs_ketenagakerjaan_format"
          value={formik.values.bpjs_ketenagakerjaan_format}
        />
      </div>
      <hr />
      <div className="wrap-form">
        <Inputs
          label="Potong Absen"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="potongan_absen"
          isCurrency={true}
          currency_name="potongan_absen_format"
          value={formik.values.potongan_absen_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Potongan Terlambat & Pulang Cepat"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="potongan_terlambat"
          isCurrency={true}
          currency_name="potongan_terlambat_format"
          value={formik.values.potongan_terlambat_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Potongan Administrasi"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="potongan_seragam"
          isCurrency={true}
          currency_name="potongan_seragam_format"
          value={formik.values.potongan_seragam_format}
        />
      </div>
      <div className="wrap-form">
        <Inputs
          label="Potongan Sertifikat"
          formik={formik}
          placeholder="Rp. * Jika tidak ada, silahkan isi angka 0 (Nol)"
          type="text"
          name="potongan_sertifikat"
          isCurrency={true}
          currency_name="potongan_sertifikat_format"
          value={formik.values.potongan_sertifikat_format}
        />
      </div>
    </ModalBody>
  );
}
