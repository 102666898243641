/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import MyModal from 'components/Modal/MyModal';
import React from 'react';
import { addDefaultSrc } from 'helpers/brokenImg';

export default function DetailTemuanPatroli(props) {
  const { isOpen, toggle, data } = props;
  const value =  data !== undefined ? data : '';
  return (
   <MyModal
    modal={ isOpen }
    toggle={ toggle }
    title='Detail Temuan'
    size='lg'
   >
    <div>
      <p className='title-text'>
        { value.titik?.nama_titik }
      </p>
      <p className='title-sub'>{ value.jam }</p>
    </div>
    <div >
      <p className='content'>{  value.keterangan }</p>
    </div>
    <div className='d-flex'>
      <div className='mr-3'>
        <img
          onError={ e => addDefaultSrc(e) }
          src={ process.env.REACT_APP_BE_URL + '/' + value.image_link }
          className='img-thumbnail' alt='icon'
          width={ 200 }
          height={ 200 }
        />
      </div>
      <div>
        {
          value.image_link_2 !== null ? (
            <img
              onError={ e => addDefaultSrc(e) }
              src={ process.env.REACT_APP_BE_URL + '/' + value.image_link_2 }
              className='img-thumbnail' alt='icon'
              width={ 200 }
              height={ 200 }
            />
          ) : null
        }
      </div>
    </div>
   </MyModal>
  );
}
