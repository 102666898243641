import { SAVE_DATA_PATROLI, SAVE_TOTAL_PATROLI } from './actionTypes';

const initialState = {
  loading: false,
  patroli: [],
  total: ''
};

const patroliReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case SAVE_DATA_PATROLI:
      state = {
        ...state,
        patroli: action.payload
      };
      break;
    case SAVE_TOTAL_PATROLI:
      state = {
        ...state,
        total: action.payload
      };
      break;
  }
  return state;
};


export default patroliReducer;
