import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

export default function Section3(props) {
  const { formik } = props;
  return (
    <Fragment>
      <div className='wrap-form'>
        <Label>NIK</Label>
        <Input
          placeholder=''
          type='number'
          name='numberId'
          onChange={ formik.handleChange }
        />
        { formik.touched.numberId && formik.errors.numberId ? (
          <div style={ { color: 'red' } }>{ formik.errors.numberId }</div>
        ) : null }
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Label>Agama</Label>
            <Input
              placeholder=''
              type='text'
              name='religion'
              onChange={ formik.handleChange }
            />
            { formik.touched.religion && formik.errors.religion ? (
              <div style={ { color: 'red' } }>{ formik.errors.religion }</div>
            ) : null }
          </div>
        </Col>
      </Row>
      <hr />
      <div className='wrap-form'>
        <Label>Alamat</Label>
        <Input
          placeholder=''
          type='text'
          name='address'
          onChange={ formik.handleChange }
        />
        { formik.touched.address && formik.errors.address ? (
          <div style={ { color: 'red' } }>{ formik.errors.address }</div>
        ) : null }
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Label>Kota</Label>
            <Input
              placeholder=''
              type='text'
              name='kota'
              onChange={ formik.handleChange }
            />
            { formik.touched.kota && formik.errors.kota ? (
              <div style={ { color: 'red' } }>{ formik.errors.kota }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Provinsi</Label>
            <Input
              placeholder=''
              type='text'
              name='province'
              onChange={ formik.handleChange }
            />
            { formik.touched.province && formik.errors.province ? (
              <div style={ { color: 'red' } }>{ formik.errors.province }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Kode Pos</Label>
            <Input
              placeholder=''
              type='number'
              name='post_code'
              onChange={ formik.handleChange }
            />
            { formik.touched.post_code && formik.errors.post_code ? (
              <div style={ { color: 'red' } }>{ formik.errors.post_code }</div>
            ) : null }
          </div>
        </Col>
      </Row>
      <div className='wrap-form'>
        <Label>No. Telepon</Label>
        <Input
          placeholder=''
          type='number'
          name='telephone'
          onChange={ formik.handleChange }
        />
        { formik.touched.telephone && formik.errors.telephone ? (
          <div style={ { color: 'red' } }>{ formik.errors.telephone }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>No. Rekening</Label>
        <Input
          placeholder=''
          type='number'
          name='rekening'
          onChange={ formik.handleChange }
        />
        { formik.touched.rekening && formik.errors.rekening ? (
          <div style={ { color: 'red' } }>{ formik.errors.rekening }</div>
        ) : null }
      </div>
    </Fragment>
  );
}
