/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";
import API from "service/api/config";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import formatRupiah from "helpers/formatRupiah";
import ComponentFormClient from "./ComponentFormClient";
import { validateAddClient } from "validates";

function ModalSaveFormClient(props) {
  const { data, setClientData } = props;
  const [uploadPhoto, setUploadPhoto] = useState("");
  const [uploadFiles, setUploadFiles] = useState("");
  const [textHeader, setTextHeader] = useState("");
  const [textButton, setTextButton] = useState("");
  const history = useHistory();

  const onChangePhoto = (e) => {
    setUploadPhoto(e.target.files[0]);
  };

  const onChangeFiles = (e) => {
    setUploadFiles(e.target.files[0]);
  };

  const formik = useFormik({
    initialValues: {
      client_name: "",
      no_kontrak: "",
      periode_kontrak: "",
      client_email: "",
      client_phone: "",
      alamat: "",
      kota: "",
      provinsi: "",
      kode_pos: "",
      document_penunjang: "",
      image_link: "",
      jam_kerja: "",
      gaji_pokok: "",
      gaji_pokok_2: "",
      tunjangan_jabatan_danru: "",
      tunjangan_jabatan_chief: "",
      tunjangan_makan: "",
      tunjangan_kehadiran: "",
      tunjangan_ops: "",
      tunjangan_lembur: "",
      tunjangan_lain: "",
      potongan_absen: "",
      potongan_absen_format: "",
      potongan_terlambat: "",
      potongan_terlambat_format: "",
      potongan_seragam: "",
      potongan_seragam_format: "",
      potongan_sertifikat: "",
      potongan_sertifikat_format: "",
      bpjs_kesehatan: "",
      bpjs_kesehatan_format: "",
      bpjs_ketenagakerjaan: "",
      bpjs_ketenagakerjaan_format: "",
      gaji_pokok_2_format: "",
      gaji_pokok_format: "",
      tunjangan_jabatan_danru_format: "",
      tunjangan_jabatan_chief_format: "",
      tunjangan_makan_format: "",
      tunjangan_kehadiran_format: "",
      tunjangan_ops_format: "",
      tunjangan_lembur_format: "",
      tunjangan_lain_format: "",
    },
    validationSchema: validateAddClient,
    onSubmit: async (values) => {
      try {
        let response;

        const formData = new FormData();
        formData.append("client_name", values.client_name);
        formData.append("client_email", values.client_email);
        formData.append("no_kontrak", values.no_kontrak);
        formData.append("periode_kontrak", values.periode_kontrak);
        formData.append("client_phone", values.client_phone);
        formData.append("alamat", values.alamat);
        formData.append("kota", values.kota);
        formData.append("provinsi", values.provinsi);
        formData.append("kode_pos", values.kode_pos);
        formData.append("doc_penunjang", uploadFiles);
        formData.append("image_link", uploadPhoto);
        formData.append("jam_kerja", values.jam_kerja);
        formData.append("gaji_pokok", values.gaji_pokok);
        formData.append("gaji_pokok_2", values.gaji_pokok_2);
        formData.append(
          "tunjangan_jabatan_danru",
          values.tunjangan_jabatan_danru,
        );
        formData.append(
          "tunjangan_jabatan_chief",
          values.tunjangan_jabatan_chief,
        );
        formData.append("tunjangan_makan", values.tunjangan_makan);
        formData.append("tunjangan_kehadiran", values.tunjangan_kehadiran);
        formData.append("tunjangan_ops", values.tunjangan_ops);
        formData.append("tunjangan_lembur", values.tunjangan_lembur);
        formData.append("tunjangan_lain", values.tunjangan_lain);
        formData.append("potongan_absen", values.potongan_absen);
        formData.append("potongan_terlambat", values.potongan_terlambat);
        formData.append("potongan_seragam", values.potongan_seragam);
        formData.append("potongan_sertifikat", values.potongan_sertifikat);
        formData.append("bpjs_kesehatan", values.bpjs_kesehatan);
        formData.append("bpjs_ketenagakerjaan", values.bpjs_ketenagakerjaan);

        if (Object.keys(data).length > 0) {
          if (uploadFiles === "") formData.delete("doc_penunjang");
          if (uploadPhoto === "") formData.delete("image_link");

          response = await API.putUpdateClient(data.client_id, formData);
        } else {
          response = await API.postAddClient(formData);
        }

        if (response.status < 299) {
          toast.success(response.data.message);
          history.go(0);
        } else if (response.status === 400) {
          toast.error(response.data.message);
          history.go(0);
        }
      } catch (err) {
        if (err.status === 500) {
          toast.error(err.message);
        }
        toast.error(err.data.message);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (data && Object.keys(data).length > 0) {
      setTextHeader("Edit Client");
      setTextButton("Perbarui");
      formik.setFieldValue("client_name", data.client_name);
      formik.setFieldValue("client_email", data.client_email);
      formik.setFieldValue("client_phone", data.client_phone);
      formik.setFieldValue("alamat", data.alamat);
      formik.setFieldValue("kota", data.kota);
      formik.setFieldValue("provinsi", data.provinsi);
      formik.setFieldValue("kode_pos", data.kode_pos);
      formik.setFieldValue("document_penunjang", data.doc_penunjang);
      formik.setFieldValue("image_link", data.image_link);
      formik.setFieldValue("jam_kerja", data.jam_kerja);
      formik.setFieldValue("gaji_pokok", data.gaji_pokok);
      formik.setFieldValue(
        "gaji_pokok_format",
        formatRupiah(data.gaji_pokok.toString()),
      );
      formik.setFieldValue("gaji_pokok_2", data.gaji_pokok_2);
      formik.setFieldValue(
        "gaji_pokok_2_format",
        formatRupiah(data.gaji_pokok_2.toString()),
      );
      formik.setFieldValue(
        "tunjangan_jabatan_danru",
        data.tunjangan_jabatan_danru,
      );
      formik.setFieldValue(
        "tunjangan_jabatan_danru_format",
        formatRupiah(data.tunjangan_jabatan_danru.toString()),
      );
      formik.setFieldValue(
        "tunjangan_jabatan_chief",
        data.tunjangan_jabatan_chief,
      );
      formik.setFieldValue(
        "tunjangan_jabatan_chief_format",
        formatRupiah(data.tunjangan_jabatan_chief.toString()),
      );
      formik.setFieldValue("tunjangan_makan", data.tunjangan_makan);
      formik.setFieldValue(
        "tunjangan_makan_format",
        formatRupiah(data.tunjangan_makan.toString()),
      );
      formik.setFieldValue("tunjangan_kehadiran", data.tunjangan_kehadiran);
      formik.setFieldValue(
        "tunjangan_kehadiran_format",
        formatRupiah(data.tunjangan_kehadiran.toString()),
      );
      formik.setFieldValue("tunjangan_ops", data.tunjangan_ops);
      formik.setFieldValue(
        "tunjangan_ops_format",
        formatRupiah(data.tunjangan_ops.toString()),
      );
      formik.setFieldValue("tunjangan_lembur", data.tunjangan_lembur);
      formik.setFieldValue(
        "tunjangan_lembur_format",
        formatRupiah(data.tunjangan_lembur.toString()),
      );
      formik.setFieldValue("tunjangan_lain", data.tunjangan_lain);
      formik.setFieldValue(
        "tunjangan_lain_format",
        formatRupiah(data.tunjangan_lain.toString()),
      );
      formik.setFieldValue("potongan_absen", data.potongan_absen);
      formik.setFieldValue(
        "potongan_absen_format",
        formatRupiah(data.potongan_absen.toString()),
      );
      formik.setFieldValue("potongan_terlambat", data.potongan_terlambat);
      formik.setFieldValue(
        "potongan_terlambat_format",
        formatRupiah(data.potongan_terlambat.toString()),
      );
      formik.setFieldValue("potongan_seragam", data.potongan_seragam);
      formik.setFieldValue(
        "potongan_seragam_format",
        formatRupiah(data.potongan_seragam.toString()),
      );
      formik.setFieldValue("potongan_sertifikat", data.potongan_sertifikat);
      formik.setFieldValue(
        "potongan_sertifikat_format",
        formatRupiah(data.potongan_sertifikat.toString()),
      );
      formik.setFieldValue("bpjs_kesehatan", data.bpjs_kesehatan);
      formik.setFieldValue(
        "bpjs_kesehatan_format",
        formatRupiah(data.bpjs_kesehatan.toString()),
      );
      formik.setFieldValue("bpjs_ketenagakerjaan", data.bpjs_ketenagakerjaan);
      formik.setFieldValue(
        "bpjs_ketenagakerjaan_format",
        formatRupiah(data.bpjs_ketenagakerjaan.toString()),
      );
      formik.setFieldValue("no_kontrak", data.no_kontrak);
      formik.setFieldValue("periode_kontrak", data.periode_kontrak);
    } else {
      setTextHeader("Tambah Client");
      setTextButton("Tambahkan");
    }
  }, [data]);

  return (
    <div>
      <Modal
        size="lg"
        isOpen={props.modal}
        toggle={props.toggle}
        centered={true}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            props.toggle();
            setClientData({});
          }}
        >
          <div
            className="d-flex justify-content-left"
            style={{ width: "220px" }}
          >
            <span style={{ fontSize: "22px", marginLeft: "2%" }}>
              {textHeader}
            </span>
          </div>
        </ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ComponentFormClient
            formik={formik}
            onChangePhoto={(e) => onChangePhoto(e)}
            onChangeFiles={(e) => onChangeFiles(e)}
          />
          <ModalFooter className="btn-modal-wrapper">
            <div className="wrap-btn-modal-footer">
              <button type="submit" className="btn btn-modal btn-rounded">
                {textButton}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default ModalSaveFormClient;
