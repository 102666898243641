/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Card } from 'reactstrap';

import API from 'service/api/config';
import ModalSaveFormClient from 'components/Molecules/client/SaveFormClient';
import ModalDeletePIC from '../../../../components/Molecules/client/ModalDeletePIC';
import { useDispatch } from 'react-redux';
import { GET_DETAIL_PIC } from '../../../../store/client/actionTypes';
import { Link } from 'react-router-dom';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{ ' ' }
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={ e => onSizePerPageChange(e.target.value) }
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{ ' ' }
        entries.
      </label>
    </div>
  ),
});

function SecurityPages(props) {
  const [userData, setUserData] = React.useState([]);
  const componentRef = React.useRef(null);
  const [modalTambahClient, setModalTambahClient] = React.useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [reRender, setReRender] = useState(false);
    const dispatch = useDispatch();

  const toggleModalTambahClient = () => {
    setModalTambahClient(!modalTambahClient);
  };

    const detailHandleDelete = (cell, row) => {
        return (
            <div
                style={ {
                    textAlign: 'center',
                    cursor: 'pointer',
                    lineHeight: 'normal',
                    margin: '10px',
                } }
            >
                <button
                    style={ {
                        backgroundColor: 'transparent',
                        outline: 'none',
                        border: 'none',
                    } }
                    onClick={ () => handleDelete(row) }
                >
                    { /* <span style={{color: "#6174DD", fontWeight:"bold"}}>Profile</span> */ }
                    <i
                        className='fas fa-trash-alt'
                        style={ { color: 'rgb(155, 159, 183)' } }
                    />
                </button>
            </div>
        );
    };

    const handleDelete = row => {
        dispatch({
            type: GET_DETAIL_PIC,
            payload: row,
        });
        setModalDelete(!modalDelete);
    };

    const handleDetail = user_id => {
        return (
            <div
                style={ {
                    textAlign: 'center',
                    cursor: 'pointer',
                    lineHeight: 'normal',
                    margin: '10px',
                } }
            >
                <button
                    style={ {
                        backgroundColor: 'transparent',
                        outline: 'none',
                        border: 'none',
                    } }
                >
                    <Link
                        to={ `profiles/${user_id}` }
                        style={ { color: '#6174DD', fontWeight: 'bold' } }
                    >
                        Lihat Profile
                    </Link>
                </button>
            </div>
        );
    };


  useEffect(() => {
    API.getUserData('CLIENT', props?.data).then(res => {
      setUserData(res?.data?.data);
    });
  }, []);

  return (
    <>
      <ModalSaveFormClient
        toggle={ toggleModalTambahClient }
        modal={ modalTambahClient }
      />
      <ModalDeletePIC
          isOpen={ modalDelete }
          toggle={ () => setModalDelete(!modalDelete) }
          setReRender={ () => setReRender(!reRender) }
      />
      <Card body>
        <ToolkitProvider
          data={ userData }
          keyField={ userData }
          columns={ [
            {
              dataField: 'name',
              text: 'NAMA',
              sort: true,
            },
            {
              dataField: 'email',
              text: 'EMAIL',
              sort: true,
            },
            {
              dataField: 'phone',
              text: 'NO. TELEPON',
              sort: true,
            },
          {
              dataField: 'user_id',
              text: '',
              sort: false,
              formatter: user_id => handleDetail(user_id),
              headerAttrs: { width: 50 },
              attrs: { width: 50, class: 'EditRow' },
          },
            {
              dataField: '',
              text: '',
              sort: false,
              formatter: detailHandleDelete,
              headerAttrs: { width: 50 },
              attrs: { width: 50, class: 'EditRow' },
            },
          ] }
          search
        >
          { props => (
            <div className='py-4 table-responsive'>
              <BootstrapTable
                ref={ componentRef }
                { ...props.baseProps }
                bootstrap4={ true }
                pagination={ pagination }
                bordered={ false }
                id='react-bs-table'
              />
            </div>
          ) }
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default SecurityPages;
