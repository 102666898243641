/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import DatePicker from "react-datepicker";
import { Card, Container, Media } from "reactstrap";
import ModalEditKeterangan from "components/Modal/EditKeterangan";
import API from "service/api/config";
import moment from "moment";
import Paginations from "components/Tabels/Paginations";
import {
  GET_DETAIL_ABSENSI,
  NAME_INPUT_KETERANGAN,
  NAME_KEHADIRAN,
} from "../../../../store/client/actionTypes";
import { useDispatch } from "react-redux";
import ModalDownload from "components/Modal/ModalDownload";
import SelectCustome from "components/Select/selectCustome";
import { actionCluster } from "store/cluster/actions";
import { addDefaultSrc } from "helpers/brokenImg";
import ModalViewItemDaftarKehadiran from "components/Modal/ViewItemDaftarKehadiran";
import ModalKeterangan from "components/Modal/ModalKeterangan";
import ModalPhotos from "components/Modal/ModalPhotos";

function Absensi(props) {
  const { formik, userDataClient } = props;
  const componentRef = React.useRef(null);
  const [modalEditKeterangan, setModalEditKeterangan] = React.useState(false);
  const [modalDownload, setModalDownload] = React.useState(false);
  const [isType, setIsType] = React.useState(false);
  const [dates, setDates] = useState(new Date());
  const [absensiData, setAbsensiData] = useState([]);
  const [clusterResponse, setClusterResponse] = useState([]);
  const [cluster, setCluster] = useState([]);
  const getDate = moment(dates).format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const [reRender, setReRender] = useState(false);
  const [isOpenViewItemKehadiran, setIsOpenViewItemKehadiran] = useState(false);
  const [isOpenKeterangan, setIsOpenKeterangan] = useState(false);
  const [allKelengkapan, setAllKelengkapan] = useState({});
  const [isOpenPicture, setIsOpenPicture] = useState(false);
  const [valuePicture, setValuePicture] = useState("");

  useEffect(() => {
    const titik = "";
    API.getAbsensi(props?.data, getDate, formik.values.name_cluster).then(
      (res) => {
        setAbsensiData(res?.data?.data);
      },
    );
    API.getClientCluster(userDataClient?.client_name, titik, props?.data).then(
      (res) => {
        if (res.status === 200) {
          setClusterResponse(res.data.data);
        }
      },
    );
    dispatch(
      actionCluster(userDataClient?.client_name, (response) => {
        setCluster(response);
      }),
    );
  }, [getDate, reRender, userDataClient]);

  const toggleModalEditKeterangan = (absensi) => {
    dispatch({
      type: GET_DETAIL_ABSENSI,
      payload: absensi,
    });
    setModalEditKeterangan(!modalEditKeterangan);
  };

  const checkCheckin = (absensis) => {
    return (
      <div>
        {absensis?.map((val, idx) => {
          return (
            <>
              <div className="wrp-value-table" key={idx}>
                {val?.tipe_absensi === "absen" ? (
                  val?.check_in !== null ? (
                    <span>{val?.check_in}</span>
                  ) : (
                    "-"
                  )
                ) : (
                  <span>{val?.tipe_absensi ?? "-"}</span>
                )}
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const checkCheckOut = (absensis) => {
    return (
      <div>
        {absensis?.map((val, idx) => {
          return (
            <>
              <div className="wrp-value-table" key={idx}>
                {val?.tipe_absensi === "absen" ? (
                  val?.check_out !== null ? (
                    <span>{val?.check_out}</span>
                  ) : (
                    "-"
                  )
                ) : (
                  <span>{val?.tipe_absensi ?? "-"}</span>
                )}
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const kelengkapan = (message, value) => {
    const handleKelengkapanButton = () => {
      if (message === "Tidak Lengkap" || message === "Lengkap") {
        setAllKelengkapan(value?.absensi?.kelengkapan);
        setIsOpenViewItemKehadiran(!isOpenViewItemKehadiran);
        dispatch({
          type: NAME_KEHADIRAN,
          payload: value.name,
        });
      }
    };
    return (
      <div
        onClick={handleKelengkapanButton}
        style={{ cursor: "pointer", margin: "10px" }}
      >
        <p
          className={
            message === "Belum Check in"
              ? "text-kelengkapan"
              : "text-kelengkapan-active"
          }
        >
          {message}
        </p>
      </div>
    );
  };

  const picture = (value) => {
    return (
      <Media>
        <div
          className="cursor-pointer"
          onClick={() => {
            value?.kelengkapan?.image_link !== undefined
              ? setIsOpenPicture(!isOpenPicture)
              : null;
            setValuePicture(value?.kelengkapan?.image_link);
          }}
        >
          <img
            onError={(e) => addDefaultSrc(e)}
            alt="img"
            className="img-thumbnail"
            src={
              `${process.env.REACT_APP_BE_URL}/` +
              value?.kelengkapan?.image_link
            }
          />
        </div>
      </Media>
    );
  };

  const handleEditKeterangan = (values, val) => {
    const handleBtnEdit = () => {
      let payload = {
        keterangan:
          val?.absensi?.desc_absensi === null ? "" : val?.absensi?.desc_absensi,
        absen_id: val?.absensi?.absensi_id ? val?.absensi?.absensi_id : "",
      };
      setIsOpenKeterangan(!isOpenKeterangan);
      dispatch({
        type: NAME_INPUT_KETERANGAN,
        payload,
      });
    };

    if (val.kelengkapanMessage !== "Belum Check in") {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
            margin: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
            }}
            onClick={handleBtnEdit}
          >
            <i
              className="fas fa-edit"
              style={{
                color:
                  val?.absensi?.desc_absensi === null ||
                  val?.absensi?.desc_absensi === undefined
                    ? "#2E2D2D"
                    : "#C4C4C4",
              }}
            />
          </button>
        </div>
      );
    }
  };

  const handleModalDownload = (type) => {
    if (type === "xls") {
      setModalDownload(!modalDownload);
      setIsType(false);
    } else {
      setModalDownload(!modalDownload);
      setIsType(true);
    }
  };

  const listCluster = clusterResponse.map((val) => {
    return {
      label: val.cluster_name,
      value: val.cluster_id,
    };
  });

  const optionCluster = cluster.map((value) => {
    return {
      label: value.cluster_name,
      value: value.cluster_id,
    };
  });

  const handleSelectClient = (val) => {
    API.getAbsensi(props?.data, getDate, val.value).then((res) => {
      setAbsensiData(res?.data?.data);
    });
  };

  useEffect(() => {}, [absensiData]);

  return (
    <>
      <ModalEditKeterangan
        toggle={toggleModalEditKeterangan}
        modal={modalEditKeterangan}
        setReRender={() => setReRender(!reRender)}
      />
      <ModalDownload
        toggle={() => setModalDownload(!modalDownload)}
        isOpen={modalDownload}
        setReRender={() => setReRender(!reRender)}
        isType={isType}
        listCluster={listCluster}
      />
      <ModalViewItemDaftarKehadiran
        toggle={() => setIsOpenViewItemKehadiran(!isOpenViewItemKehadiran)}
        isOpen={isOpenViewItemKehadiran}
        setReRender={() => setReRender(!reRender)}
        allKelengkapan={allKelengkapan}
      />
      <ModalKeterangan
        toggle={() => setIsOpenKeterangan(!isOpenKeterangan)}
        isOpen={isOpenKeterangan}
        setReRender={() => setReRender(!reRender)}
      />
      <ModalPhotos
        toggle={() => setIsOpenPicture(!isOpenPicture)}
        isOpen={isOpenPicture}
        isDetailPhotos={valuePicture}
      />
      <Card body>
        <ToolkitProvider
          data={absensiData}
          keyField="absen_tab"
          columns={[
            {
              dataField: "name",
              text: "NAMA",
              sort: true,
            },
            {
              dataField: "cluster.cluster_name",
              text: "CLUSTER",
              sort: true,
            },
            {
              dataField: "absensis",
              text: "CHECK-IN",
              sort: true,
              formatter: (absensis) => checkCheckin(absensis),
              headerAttrs: { width: 150 },
              attrs: { width: 150, class: "EditRow" },
            },
            {
              dataField: "absensis",
              text: "CHECK-OUT",
              sort: true,
              formatter: (absensis) => checkCheckOut(absensis),
              headerAttrs: { width: 150 },
              attrs: { width: 150, class: "EditRow" },
            },
            {
              dataField: "desc_absensi",
              text: "KETERANGAN",
              sort: false,
              formatter: (value, val) => handleEditKeterangan(value, val),
              headerAttrs: { width: 50 },
              attrs: { width: 50, class: "EditRow" },
            },
            {
              dataField: "kelengkapanMessage",
              text: "KELENGKAPAN",
              sort: false,
              formatter: (value, val) => kelengkapan(value, val),
              headerAttrs: { width: 50 },
              attrs: { width: 50, class: "EditRow" },
            },
            {
              dataField: "absensi",
              text: "FOTO",
              sort: false,
              formatter: (value) => picture(value),
              headerAttrs: { width: 50 },
              attrs: { width: 50, class: "EditRow" },
            },
          ]}
          search
        >
          {(props) => (
            <div className="table-responsive">
              <Container fluid>
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <div>
                    <div className="d-flex">
                      <div className="wrap-datepick">
                        <DatePicker
                          selected={dates}
                          onChange={(date) => setDates(date)}
                          dateFormat="yyyy-MM-dd"
                          className="datepicks"
                        />
                      </div>
                      <div className="mx-6">
                        <SelectCustome
                          labelHidden={true}
                          formik={formik}
                          placeholder="Cluster"
                          name="name_cluster"
                          options={optionCluster}
                          isCustome={true}
                          onChange={handleSelectClient}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-end">
                      <div className="wrap-label-place-detail">
                        <button
                          className="btn-ubah-jabatan"
                          onClick={() => handleModalDownload("xls")}
                        >
                          <span>XLS</span>
                        </button>
                      </div>
                      <div className="wrap-label-place-detail">
                        <button
                          className="btn-ubah-jabatan"
                          onClick={() => handleModalDownload("pdf")}
                        >
                          <span>PDF</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={Paginations}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default Absensi;
