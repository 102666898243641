import { LAPORAN_CLIENT_ID, LAPORAN_SUCCESS, SAVE_DATA_TITIK_PATROLI } from './actionTypes';

const initialState = {
  loading: false,
  laporan: [],
  name_client: '',
  titik_patroli: []
};

const laporanReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case LAPORAN_SUCCESS:
      state = {
        ...state,
        laporan: action.payload
      };
      break;
    case LAPORAN_CLIENT_ID:
      state = {
        ...state,
        name_client: action.payload
      };
      break;
    case SAVE_DATA_TITIK_PATROLI:
      state = {
        ...state,
        titik_patroli: action.payload
      };
      break;
    
  }

  return state;
};


export default laporanReducer;
