/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import ReactMapGL from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { ADD_MARKER } from 'store/maps/actionTypes';
import { Markers } from './marker';
// import 'mapbox-gl/dist/mapbox-gl.css';

function Map(props) {
  const dispatch = useDispatch();
  const [mapViewport, setMapViewport] = useState({
    height: '100vh',
    width: '100wh',
    longitude: 116.17276785567509,
    latitude: -2.9407186958177816,
    zoom: 3,
  });

  useEffect(() => {}, [props.reRender]);

  return (
    <div>
      <ReactMapGL
        { ...mapViewport }
        width='600px'
        height='400px'
        mapStyle='mapbox://styles/mapbox/streets-v11'
        onViewportChange={ setMapViewport }
        mapboxApiAccessToken={ process.env.REACT_APP_MAPBOX }
        onClick={ x => {
          x.target.localName !== 'img' && props.active &&
          dispatch({
            type: ADD_MARKER,
            payload: { marker: x.lngLat }
          });
        } }
      >
        <Markers active={ props.active } action={ props.action } />
      </ReactMapGL>
    </div>
  );
}

export default Map;
