/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import { SAVE_DATA_PATROLI, SAVE_TOTAL_PATROLI } from './actionTypes';

export const actionGetPatroli = (date, id) => async dispatch => {
  try {
    const res = await API.getPatroli(`tanggal=${date}&cluster_id=${id}`);
    if (res.status < 299) {
      dispatch({
        type: SAVE_DATA_PATROLI,
        payload: res.data.data,
      });
      dispatch({
        type: SAVE_TOTAL_PATROLI,
        payload: res.data.count
      });
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

// export const actionDownloadXLS = (body) => async dispatch => {
//   try {
//     const res = await API.postPatroliDownloadXLS(body);
//     // if (res.status < 299) {
//     //   dispatch({
//     //     type: SAVE_DATA_PATROLI,
//     //     payload: res.data.data,
//     //   });
//     //   dispatch({
//     //     type: SAVE_TOTAL_PATROLI,
//     //     payload: res.data.count
//     //   });
//     // }
//   } catch (e) {
//     if (e !== undefined) {
//       if (e.status === 401) {
//         toast.error(e.data.message);
//       } else if (e.status === 500) {
//         toast.error(e.data.message);
//       } else if (e.status === 400) {
//         toast.error(e.data.message);
//       }
//     } else {
//       toast.error('Opsss. Something When Wrong !!!');
//     }
//   }
// };
