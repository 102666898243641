import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function MyModal(props) {
  return (
    <Modal size={ props.size } isOpen={ props.modal } toggle={ props.toggle } centered={ true } backdrop='static'>
      <ModalHeader toggle={ props.toggle } style={ { borderBottom: '1px solid #E9ECEF' } }>
        <div className='d-flex justify-content-beetwen'>
          <div>
            { props.title }
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
          { props.children }
      </ModalBody>
    </Modal>
  );
}
