import { ButtonDefault } from 'components/Buttons/indes';
import Inputs from 'components/Forms';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { actionChangePassword } from 'store/auth/actions';

export default function ModalChangePassword(props) {
  let userData = JSON.parse(localStorage.getItem('userData'));
  const dispatch = useDispatch();
  
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Password lama harus di isi'),
      newPassword: Yup.string().required('Password baru harus di isi'),
      confirmPassword: Yup.string()
        .required('Ulang Password harus di isi')
        .oneOf(
          [Yup.ref('newPassword'), null],
          'Password anda tidak sesuai'
        ),
    }),
    onSubmit: values => {
      const body = {
        email: userData.email,
        newpassword: values.newPassword,
        oldpassword: values.oldPassword,
      };
      dispatch(
        actionChangePassword(body, () => {
          props.toggle();
        })
      );
    },
  });

  return (
    <Modal
      size='md'
      isOpen={ props.modal }
      toggle={ props.toggle }
      centered={ true }
      backdrop='static'
    >
      <ModalHeader toggle={ props.toggle }>
        <div className='d-flex justify-content-left align-items-center'>
          <span>Ubah Password</span>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={ formik.handleSubmit }>
          <Inputs
            label='Password Lama'
            type='password'
            name='oldPassword'
            formik={ formik }
          />
          <Inputs
            label='Password Baru'
            type='password'
            name='newPassword'
            formik={ formik }
          />
          <Inputs
            label='Ulangi Password'
            type='password'
            name='confirmPassword'
            formik={ formik }
          />
          <hr />
          <Col xs='5'>
            <ButtonDefault
              name='Ubah Password'
              background='#6174DD'
              color='#fff'
              type='submit'
            />
          </Col>
        </form>
      </ModalBody>
    </Modal>
  );
}
