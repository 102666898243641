/* eslint-disable react/jsx-no-bind */
import Inputs from 'components/Forms';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  actionAddCluster,
  actionUpdateCluster,
} from '../../../../store/cluster/actions';

export default function FormAddCluster(props) {
  const { setActive, setReRender, action, propSetReRender } = props;
  const dispatch = useDispatch();
  const markers = useSelector(state => state.clusterReducer.newMarker);
  const detail = useSelector(state => state.clusterReducer.detail);

  const formik = useFormik({
    initialValues: {
      cluster_name: '',
      latitude: 0,
      longitude: 0,
      phone: '',
    },
    validationSchema: Yup.object({
      latitude: Yup.string().required('Required'),
      longitude: Yup.string().required('Required'),
      cluster_name: Yup.string().required('Required'),
      phone: Yup.number().required('Required')
    }),
    onSubmit: values => {
      try {
        if (action === 'Add') {
          const formData = {
            client_id: props?.clientId,
            cluster_name: values.cluster_name,
            latitude: values.latitude,
            longitude: values.longitude,
            phone: values.phone,
          };
          dispatch(
            actionAddCluster(formData, () => {
              setReRender();
              setActive();
              props.toggle();
              propSetReRender();
            })
          );
        } else {
          const formData = {
            cluster_name: values.cluster_name,
            latitude: values.latitude,
            longitude: values.longitude,
            phone: values.phone,
          };
          dispatch(
            actionUpdateCluster(detail?.cluster_id, formData, () => {
              setReRender();
              setActive();
              props.toggle();
              propSetReRender();
            })
          );
        }
      } catch (e) {
        if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    switch (action) {
      case 'Edit':
        if (Object.keys(detail).length > 0) {
          if (markers.length > 0) {
            formik.setFieldValue('latitude', markers[1]);
            formik.setFieldValue('longitude', markers[0]);
            formik.setFieldValue('cluster_name', detail.cluster_name);
            formik.setFieldValue('phone', detail.phone);
          } else {
            formik.setFieldValue('cluster_name', detail.cluster_name);
            formik.setFieldValue('latitude', detail.latitude);
            formik.setFieldValue('longitude', detail.longitude);
            formik.setFieldValue('phone', detail.phone);
          }
        }
        break;
      case 'Add':
        if (markers.length > 0) {
          formik.setFieldValue('latitude', markers[1]);
          formik.setFieldValue('longitude', markers[0]);
        }

        break;
    }
  }, [markers, detail]);

  return (
    <div>
      <form onSubmit={ formik.handleSubmit }>
        <Card body>
          <Row>
            <Col>
              <Inputs
                label='Latitude'
                name='latitude'
                type='text'
                value={ formik.values.latitude }
                formik={ formik }
                disabled={ true }
              />
            </Col>
            <Col>
              <Inputs
                label='Longitude'
                placeholder='longitude'
                name='longitude'
                type='text'
                value={ formik.values.longitude }
                formik={ formik }
                disabled={ true }
              />
            </Col>
          </Row>
          <div className='mt-3'>
            <Inputs
              label='Nama Cluster'
              name='cluster_name'
              type='text'
              formik={ formik }
              value={ formik.values.cluster_name }
              onChange={ formik.handleChange }
            />
          </div>
          <div className='mt-3'>
            <Inputs
              label='Nomor HP Cluster'
              name='phone'
              type='number'
              formik={ formik }
              value={ formik.values.phone }
              onChange={ formik.handleChange }
            />
          </div>
          <div className='mt-3 d-flex justify-content-center'>
            <Button color='primary'>
              { ' ' }
              { action === 'Add' ? 'Tambahkan' : 'Perbarui' }{ ' ' }
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
}
