/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';

export default function Section5(props) {
  const {
    formik,
    optionsTypeClient,
    handleSelectCluster,
    joinDay,
    setJoinDay,
    joinMonth,
    setJoinMonth,
    joinYear,
    setJoinYear,
    optionsTypeCluster,
    typeId
   } = props;
  return (
    <Fragment>
      <label>Penempatan</label>
      <Row>
        <Col>
          <label>Client</label>
          <Select
            options={ optionsTypeClient }
            name='tipeClient'
            onChange={ handleSelectCluster }
          />
          { formik.touched.tipeClient && formik.errors.tipeClient ? (
            <div style={ { color: 'red' } }>{ formik.errors.tipeClient }</div>
          ) : null }
        </Col>
        {
          typeId === 4 || typeId === 6 ? (
            <Col>
              <label>Cluster</label>
              <Select
                options={ optionsTypeCluster }
                name='tipeCluster'
                onChange={ val => {
                  formik.setFieldValue('tipeCluster', val.value);
                } }
              />
              { formik.touched.tipeCluster && formik.errors.tipeCluster ? (
                <div style={ { color: 'red' } }>{ formik.errors.tipeCluster }</div>
              ) : null }
            </Col>
          ) : null
        }
      </Row>
      <label className='mt-4'>Tanggal Bergabung</label>
      <Row>
        <Col>
          <Label>Hari</Label>
          <DatePicker
            name='hari_bergabung'
            selected={ joinDay }
            onChange={ date => {
              const dates = moment(date).format('DD');
              formik.setFieldValue('hari_bergabung', dates);
              setJoinDay(date);
            } }
            placeholderText='Hari'
            dateFormat='dd'
            showDayDropdown
            className='input-date-picker-customs'
          />
          { formik.touched.hari_bergabung && formik.errors.hari_bergabung ? (
            <div style={ { color: 'red' } }>{ formik.errors.hari_bergabung }</div>
          ) : null }
        </Col>
        <Col>
          <Label>Bulan</Label>
          <DatePicker
            name='bulan_bergabung'
            selected={ joinMonth }
            placeholderText='Bulan'
            onChange={ date => {
              const dates = moment(date).format('MM');
              formik.setFieldValue('bulan_bergabung', dates);
              setJoinMonth(date);
            } }
            dateFormat='MM'
            showMonthYearPicker
            showFullMonthYearPicker
            showFourColumnMonthYearPicker
            className='input-date-picker-customs'
          />
          { formik.touched.bulan_bergabung && formik.errors.bulan_bergabung ? (
            <div style={ { color: 'red' } }>{ formik.errors.bulan_bergabung }</div>
          ) : null }
        </Col>
        <Col>
          <Label>Tahun</Label>
          <DatePicker
            name='tahun_bergabung'
            selected={ joinYear }
            placeholderText='Tahun'
            onChange={ date => {
              const dates = moment(date).format('YYYY');
              formik.setFieldValue('tahun_bergabung', dates);
              setJoinYear(date);
            } }
            showYearPicker
            dateFormat='yyyy'
            className='input-date-picker-customs'
          />
          { formik.touched.tahun_bergabung && formik.errors.tahun_bergabung ? (
            <div style={ { color: 'red' } }>{ formik.errors.tahun_bergabung }</div>
          ) : null }
        </Col>
      </Row>
    </Fragment>
  );
}
