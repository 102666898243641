/* eslint-disable react/jsx-no-bind */
import Inputs from 'components/Forms';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  actionPatrolPoint,
  actionUpdatePatrolPoint,
} from '../../../../store/maps/actions';
import {
  CANCEL_EDIT_PATROL_POINT,
  REMOVE_MARKER,
} from '../../../../store/maps/actionTypes';
import { validateLaporan } from 'validates';

export default function FormAdd(props) {
  const { setActive, setReRender, action, setAction } = props;
  const dispatch = useDispatch();
  const markers = useSelector(state => state.mapsReducer.newMarker);
  const detail = useSelector(state => state.mapsReducer.detail);
  
  const formik = useFormik({
    initialValues: {
      name: '',
      latitude: '',
      longitude: '',
    },
    validationSchema: validateLaporan.titikPatroli,
    onSubmit: values => {
      try {
        if (props.action === 'Add') {
          const formData = {
            cluster_id: props.clusterId,
            nama_titik: values.name,
            latitude: values.latitude,
            longitude: values.longitude,
          };
          dispatch(
            actionPatrolPoint(formData, () => {
              setReRender();
              setActive();
              props.toggle();
            })
          );
        } else {
          const formData = {
            code: detail.code,
            nama_titik: values.name,
            latitude: values.latitude,
            longitude: values.longitude,
          };
          dispatch(
            actionUpdatePatrolPoint(formData, () => {
              setReRender();
              setActive();
              props.toggle();
            })
          );
        }
      } catch (e) {
        if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    switch (action) {
      case 'Edit':
        if (Object.keys(detail).length > 0) {
          if (markers.length > 0) {
            formik.setFieldValue('latitude', markers[1]);
            formik.setFieldValue('longitude', markers[0]);
            formik.setFieldValue('name', detail.nama_titik);
          } else {
            formik.setFieldValue('name', detail.nama_titik);
            formik.setFieldValue('latitude', detail.latitude);
            formik.setFieldValue('longitude', detail.longitude);
          }
        }
        break;
      case 'Add':
        if (markers.length > 0) {
          formik.setFieldValue('latitude', markers[1]);
          formik.setFieldValue('longitude', markers[0]);
        }

        break;
    }
  }, [markers, detail]);

  const cancelForm = () => {
    setActive();
    setAction();
    dispatch({
      type: props.action === 'Add' ? REMOVE_MARKER : CANCEL_EDIT_PATROL_POINT,
    });
  };
  return (
    <div>
      <form onSubmit={ formik.handleSubmit }>
        <Card body>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <p>Titik Baru</p>
            </div>
            <div onClick={ cancelForm }>
              <p style={ { color: '#6174DD', cursor: 'pointer' } }>Tutup</p>
            </div>
          </div>
          <Row>
            <Col>
              <Inputs
                placeholder='latitude'
                label='Latitude'
                name='latitude'
                type='text'
                value={ formik.values.latitude }
                formik={ formik }
                disabled={ true }
              />
            </Col>
            <Col>
              <Inputs
                label='Longitude'
                placeholder='longitude'
                name='longitude'
                type='text'
                value={ formik.values.longitude }
                formik={ formik }
                disabled={ true }
              />
            </Col>
          </Row>
          <div className='mt-3'>
            <Inputs
              label='Nama Titik'
              name='name'
              type='text'
              formik={ formik }
              value={ formik.values.name }
              onChange={ formik.handleChange }
            />
          </div>
          <div className='mt-3 d-flex justify-content-center'>
            <Button color='primary'>
              { props.action === 'Add' ? 'Tambahkan' : 'Perbarui' }
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
}
