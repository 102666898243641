/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
import Inputs from 'components/Forms';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import API from 'service/api/config';

const UbahPasswordProfile = (props) => {
    const { data, setRender, toggle, userID, isOpen } = props;
    
    useEffect(() => {
        if (data) {
            formik.setFieldValue('user_id', userID);
        }
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            user_id: '',
            password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Password is required'),
            confirm_password: Yup.string()
               .oneOf([Yup.ref('password'), null], 'Passwords tidak sesuai')
        }),
        onSubmit: async(values) => {
            let data = {
                user_id: values.user_id,
                password: values.password
            };
            try {
                const response = await API.putCredential(data);
                if (response.status === 200) {
                    toast.success(response.data.message);
                    setRender();
                    toggle();
                }
            } catch (error) {
                toast.error('Fetch Error');
            }
        }
    });

  return (
    <>
        <Modal
            size='md'
            isOpen={ props.isOpen }
            toggle={ props.toggle }
            centered={ true }
            backdrop='static'
        >
            <ModalHeader toggle={ toggle }>
                <div
                    className='d-flex justify-content-left'
                    style={ { width: '160px' } }
                >
                    <span style={ { fontSize: '18px', marginLeft: '2%' } }>
                        Ubah Password
                    </span>
                </div>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={ formik.handleSubmit }>
                    <Inputs
                        formik={ formik }
                        type='password'
                        label='Password'
                        name='password'
                        value={ formik.values.password }
                        placeholder='password'
                    />
                    <Inputs
                        formik={ formik }
                        type='password'
                        label='Ulang Password'
                        name='confirm_password'
                        value={ formik.values.confirm_password }
                        placeholder='password'
                    />
                    <hr />
                    <div className='col-md-5'>
                        <button
                            className='btn-pindah-lokasi'
                            type='submit'
                        >
                            <span>Simpan</span>
                        </button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </>
  );
};

export default UbahPasswordProfile;