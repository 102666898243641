/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';


export const actionNotification = (limit, offset, userID, callBack) => async() => {
    // todo logic hit api
    // dispatch({
    //   type: SIGNIN,
    // });
    try {
      const res = await API.getNotification(`limit=${limit}&offset=${offset}&user_id=${userID.user_id}`);
      if (res.status < 299) {
        callBack(res);
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};

export const actionReadNotification = (data, callBack) => async() => {
  // todo logic hit api
  try {
    const res = await API.putReadNotification(data);
    if (res.status < 299) {
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionReadAllNotification = (data, callBack) => async() => {
  // todo logic hit api
  try {
    const res = await API.postReadAllNotification(data);
    if (res.status < 299) {
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};


