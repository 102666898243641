/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-undef */
/* eslint-disable space-before-blocks */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import IconDownload from '../../assets/img/DownloadXLS.svg';
import IconsXLS from '../../assets/img/ph_file-xls-fill.svg';
import IconsPDF from '../../assets/img/PDF.svg';
import IconsBtnPDF from '../../assets/img/icon-btn-pdf.svg';
import IconsClose from '../../assets/img/icons/bi_x-lg.svg';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { useFormik } from 'formik';
import { Modal, ModalBody } from 'reactstrap';
import MySelect from 'components/Select';

const ModalDownload = (props) => {
    const { isType, listCluster  } = props;
    const [startDate, setStartDate] = useState();
    const [endDates, setEndDates] = useState();
    const [startDateMonth, setStartDateMonth] = useState(null);
    
    const formik = useFormik({
        initialValues: {
            startDateMonth: null,
            startDate: null,
            endDate: null,
            cluster_id: '',
        },
        onSubmit: () => {}
    });
    
    const handleDaysDownload = val => {
        if (formik.values.cluster_id) {
            if (val === 'day') {
                let url;
                url = `${process.env.REACT_APP_BE_URL}/absensi/${isType ? 'render' : 'render_excel'}?type=${val}&cluster_id=${formik.values.cluster_id}`;
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
            } else if (val === 'week') {
                let url;
                url = `${process.env.REACT_APP_BE_URL}/absensi/${isType ? 'render' : 'render_excel'}?type=${val}&cluster_id=${formik.values.cluster_id}`;
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
            } else if (val === 'month'){
                let url;
                url = `${process.env.REACT_APP_BE_URL}/absensi/${isType ? 'render' : 'render_excel'}?type=${val}&cluster_id=${formik.values.cluster_id}`;
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
            }
        }
    };

    const handleDownload = () => {
        if (formik.values.cluster_id) {
            
            if (isType) {
                if (startDate && endDates){
                    let start = moment(startDate).format('YYYY/MM/DD');
                    let end = moment(endDates).format('YYYY/MM/DD');
                    let date = `${start}-${end}`;
                    let url;
                    url = `${process.env.REACT_APP_BE_URL}/absensi/render?type='custom'&cluster_id=${formik.values.cluster_id}&tanggal=${date}`;
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                }
            } else {
                if (startDateMonth){
                    let start = moment(startDateMonth).format('YYYY/MM/DD');
                    let date = `${start}`;
                    let url;
                    url = `${process.env.REACT_APP_BE_URL}/absensi/render_excel?type=${'custom'}&cluster_id=${formik.values.cluster_id}&tanggal=${date}`;
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                }
            }
        }
    };
    
  return (
      <Modal
        size='lg'
        isOpen={ props.isOpen }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
            <div className='d-flex justify-content-between align-items-center px-4'>
                <div>
                    { isType ? 'Download PDF' : 'Download XLS' }
                </div>
                <div className='text-center'>
                    <MySelect
                        formik={ formik }
                        name='cluster_id'
                        placeholde='Pilih Cluster'
                        options={ listCluster }
                    />
                </div>
                <div
                    style={ { cursor: 'pointer' } }
                    onClick={ props.toggle  }
                >
                    <img src={IconsClose} alt='icons' />
                </div>
            </div>
            <ModalBody style={ { padding: '0px' } }>
                <hr />
                <div className='d-flex justify-content-center'>
                    <div className='mx-4'>
                        <div className='my-2' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>Hari ini</div>
                        <img
                            src={ !isType ? IconDownload : IconsPDF }
                            alt='icons download' style={{ cursor: 'pointer' }}
                            onClick={() => handleDaysDownload('day') }
                        />
                    </div>
                    <div className='mx-4'>
                        <div className='my-2' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>Minggu ini</div>
                        <img
                            src={ !isType ? IconDownload : IconsPDF }
                            alt='icons download'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDaysDownload('week') }
                        />
                    </div>
                    <div className='mx-4'>
                        <div className='my-2' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>Bulan ini</div>
                        <img src={ !isType ? IconDownload : IconsPDF } alt='icons download' style={{ cursor: 'pointer' }} onClick={() => handleDaysDownload('month') }/>
                    </div>
                </div>
                <hr />
                {
                    isType ? (
                        <div className='d-flex justify-content-center align-items-center'>
                            <div>
                                <ReactDatePicker
                                    selected={ startDate }
                                    selectsStart
                                    startDate={ startDate }
                                    endDate={ endDates }
                                    placeholderText='Tanggal'
                                    onChange={ date => { setStartDate(date); formik.setFieldValue('startDate', date); } }
                                    dateFormat='yyyy-MM-dd'
                                    className='datepicks-default'
                                />
                            </div>
                            <div className='mx-3' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>s/d</div>
                            <div>
                                <ReactDatePicker
                                    selected={ endDates }
                                    onChange={ date => { setEndDates(date); formik.setFieldValue('endDate', date); } }
                                    placeholderText='Tanggal'
                                    dateFormat='yyyy-MM-dd'
                                    className='datepicks-default'
                                    selectsEnd
                                    disabled={formik.values.startDate === null ? true : false}
                                    minDate={startDate}
                                    maxDate={
                                        formik.values.startDate === null ? new Date() :
                                         new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='d-flex justify-content-center align-items-center'>
                            <div>
                                <ReactDatePicker
                                    selected={ startDateMonth }
                                    showMonthYearPicker
                                    placeholderText='Bulan/Tahun'
                                    onChange={ date => { setStartDateMonth(date); formik.setFieldValue('startDateMonth', date); } }
                                    dateFormat='MM/yyyy'
                                    className='datepicks-default'
                                    maxDate={ new Date() }
                                />
                            </div>
                        </div>
                    )
                }
                {
                    isType ? (
                        <div className='d-flex justify-content-center my-4'>
                            {
                                <div
                                    className={formik.values.startDate && formik.values.endDate && formik.values.cluster_id ? 'btn-download-custom-active' : 'btn-download-custom'}
                                    onClick={formik.values.startDate && formik.values.endDate && formik.values.cluster_id ? handleDownload : null}>
                                    Download <span><img className='ml-2' src={ IconsBtnPDF } alt='icons' /></span>
                                </div>
                            }
                        </div>
                    ) : (
                        <div className='d-flex justify-content-center my-4'>
                            {
                                <div
                                    className={ formik.values.startDateMonth && formik.values.cluster_id ? 'btn-download-custom-active' : 'btn-download-custom' }  
                                    onClick={ formik.values.startDateMonth && formik.values.cluster_id ? handleDownload : null}>
                                    Download <span><img className='ml-2' src={ IconsXLS } alt='icons' /></span>
                                </div>
                            }
                        </div>
                    )
                }
            </ModalBody>
      </Modal>
  );
};

export default ModalDownload;