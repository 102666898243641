/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
import MySelect from 'components/Select';
import { useFormik } from 'formik';
import { addDefaultSrc } from 'helpers/brokenImg';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import API from 'service/api/config';

const UbahJabatan = (props) => {
    const { data,setRender, toggle } = props;
    const [listJabatan, setListJabatan] = useState([]);

    useEffect(() => {
        if (data) {
            formik.setFieldValue('ubah_jabatan', data.role?.role_id);
            formik.setFieldValue('user_id', data.user_id);
        }
        fetchListJabatan();
    }, [data]);

    const fetchListJabatan = async() => {
        try {
            const response = await API.getListJabatan();
            if (response.status === 200) {
                setListJabatan(response.data?.data);
            }
        } catch (error) {
            toast.error('Fetch Error');
        }
    };
    
    const jabatan = listJabatan.map((values) => {
        return { value: values.role_id, label: values.role_name };
    });

    const formik = useFormik({
        initialValues: {
            ubah_jabatan: '',
            user_id: '',
        },
        onSubmit: async(values) => {
            let data = {
                user_id: values.user_id,
                role_id: values.ubah_jabatan
            };
            try {
                const response = await API.putUbahJabatan(data);
                if (response.status === 200) {
                    toast.success(response.data.message);
                    setRender();
                    toggle();
                }
            } catch (error) {
                toast.error('Fetch Error');
            }
        }
    });
    
  return (
    <>
        <Modal
            size='md'
            isOpen={ props.isOpen }
            toggle={ props.toggle }
            centered={ true }
            backdrop='static'
        >
            <ModalHeader toggle={ toggle }>
                <div
                    className='d-flex justify-content-left'
                    style={ { width: '160px' } }
                >
                    <span style={ { fontSize: '18px', marginLeft: '2%' } }>
                        Ubah Jabatan
                    </span>
                </div>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={ formik.handleSubmit }>
                    <div className='d-flex'>
                        <div className=''>
                            <div className='d-flex align-items-center'>
                                <img
                                    src={ `${process.env.REACT_APP_BE_URL}/${data.image_link}` }
                                    alt='avatar'
                                    onError={ e => addDefaultSrc(e) }
                                    className='rounded-circle img-fluid'
                                    height={ 100 }
                                    width={ 100 }
                                />
                                <div className='mx-3'>
                                    <p style={ { color: '#2E2D2D', fontWeight: '700' } }>{ data.name }</p>
                                    <p style={ { color: '#9B9FB7', fontWeight: '600', fontSize: '14px' } }>{ data.role?.role_name }</p>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <MySelect
                                formik={ formik }
                                label='Ubah Jabatan'
                                name='ubah_jabatan'
                                placeholde='Ubah Jabatan'
                                selected={ { label: data.role?.role_name, value: formik.values.ubah_jabatan } }
                                options={ jabatan }
                            />
                        </div>
                    </div>
                    <hr />
                    <div className='col-md-5'>
                        <button
                            className='btn-pindah-lokasi'
                            type='submit'
                        >
                            <span>Simpan</span>
                        </button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </>
  );
};

export default UbahJabatan;