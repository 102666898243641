/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import Inputs from 'components/Forms';
import IconEdit from 'assets/img/icons/edit.svg';
import API from 'service/api/config';
import { toast } from 'react-toastify';
import { ROLE_SUPER_ADMIN } from 'helpers/types';

const InputEmail = (props) => {
    const {
        email,
        userID,
        setRender,
    } = props;
    const [isEdited, setIsEdited] = useState(true);
    const roles = localStorage.getItem('role');

    useEffect(() => {
        formik.setFieldValue('email', email);
    }, [email]);

    
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async(values) => {
            let data = {
                user_id: userID,
                email: values.email
            };
            try {
                const response = await API.putCredential(data);
                if (response.status === 200) {
                    toast.success(response.data.message);
                    setIsEdited(!isEdited);
                    setRender();
                }
            } catch (error) {
                toast.error('Fetch Error');
            }
        }
    });

  return (
    <form onSubmit={ formik.handleSubmit }>
        {
            roles === ROLE_SUPER_ADMIN ? (
                <div className='d-flex align-items-center'>
                    <Inputs
                        formik={ formik }
                        type='text'
                        name='email'
                        disabled={ isEdited }
                        value={ formik.values.email }
                        placeholder={ email }
                    />
                    <div className='m-3 pt-3'>
                        <img
                            src={ IconEdit }
                            width={ 20 }
                            height={ 20 }
                            alt='icon'
                            style={ { cursor: 'pointer' } }
                            onClick={ () => setIsEdited(!isEdited) }
                        />
                    </div>
                </div>
            ) : (
                <Inputs
                    formik={ formik }
                    type='text'
                    name='email'
                    disabled={ isEdited }
                    value={ formik.values.email }
                    placeholder={ email }
                />
            )
        }
        {
            !isEdited && (
                <div className='wrap-label-place-detail'>
                    <button
                        className='btn-pindah-lokasi'
                    >
                    <span>Simpan</span>
                    </button>
                </div>
            )
        }
    </form>
  );
};

export default InputEmail;