/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import classnames from "classnames";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Security from "./security/index";
import PayrollPages from "./payroll";
import KelengkapanPages from "./kelengkapan";
import { ROLE_ADMIN } from "helpers/types";

function SecurityPages() {
  const [activeTab, setactiveTab] = React.useState("1");

  const roles = localStorage.getItem("role");

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  return (
    <>
      <div className="wrap-text-label-head">
        <span className="txt-label-pages">Security</span>
      </div>
      <Container>
        <div>
          {roles === "CLIENT" ? (
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      actives: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <span className="txt-lbl-sub">Security</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="tab-content">
                <TabPane
                  tabId="1"
                  className="p-3"
                  style={{ backgroundColor: "#fff" }}
                >
                  <Security />
                </TabPane>
              </TabContent>
            </div>
          ) : (
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      actives: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <span
                      className={`txt-lbl-sub ${activeTab === "1" ? "actives-tab" : null}`}
                    >
                      Security
                    </span>
                  </NavLink>
                </NavItem>
                {roles !== ROLE_ADMIN ? (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        actives: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <span
                        className={`txt-lbl-sub ${activeTab === "2" ? "actives-tab" : null}`}
                      >
                        Payroll
                      </span>
                    </NavLink>
                  </NavItem>
                ) : null}
                {/* <NavItem>
                    <NavLink
                      className={ classnames({
                        actives: activeTab === '3',
                      })}
                      onClick={ () => {
                        toggle('3');
                      } }
                    >
                      <span className={ `txt-lbl-sub ${activeTab === '3' ? 'actives-tab' : null} hidden` }>Kelengkapan</span>
                    </NavLink>
                  </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab} className="tab-content">
                <TabPane tabId="1" style={{ backgroundColor: "#fff" }}>
                  <Security />
                </TabPane>
                <TabPane tabId="2" style={{ backgroundColor: "#fff" }}>
                  <PayrollPages />
                </TabPane>
                <TabPane tabId="3" style={{ backgroundColor: "#fff" }}>
                  <KelengkapanPages />
                </TabPane>
              </TabContent>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default SecurityPages;
