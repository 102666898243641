/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import { SAVE_INFO_ABSEN, SAVE_RESULT_DASHBOARD } from './actionTypes';


export const actionAllResult = body => async dispatch => {
    try {
      const res = await API.postDashboard(body);
      if (res.status < 299) {
        dispatch({
          type: SAVE_RESULT_DASHBOARD,
          payload: res.data.data
        });
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};

export const actionInfo = body => async dispatch => {
    try {
      const res = await API.getUserAbsensi(`kelangkapan=${false}&tanggal=${body.date}&client_id=${body.client_id}`);
      if (res.status < 299) {
        dispatch({
          type: SAVE_INFO_ABSEN,
          payload: res.data.data
        });
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};
