/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import { Redirect, Route } from 'react-router';

const NonAuthmiddleware = props => {
  // const token = Cookies.get('data');
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  const { component: Component } = props;

  return (
    <Route
      render={ props => {
        return !token ? (
          <Component { ...props } />
        ) : (
          <Redirect to={ { pathname: `/${role.toLowerCase()}/dashboard` } } />
        );
      } }
    />
  );
};

export default NonAuthmiddleware;
