/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { addDefaultSrc } from 'helpers/brokenImg';
import { useSelector } from 'react-redux';

export default function LaporaToday() {
  const data = useSelector(state => state.laporanReducer.laporan);
  return (
    <Fragment>
      <Card className='p-4'>
        <h3> Laporan Hari ini</h3>
        {
        data.map((val, i) => {
          return (
            <>
            <Row className='mt-3' key={ i }>
              <Col>
                <p className='m-0 p-0' style={ { color: '#2E2D2D', fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize' } }>{ val.judul_laporan }</p>
                <p className='p-0 m-0' style={ { color: '#9B9FB7', fontSize: '14px', fontWeight: 'normal' } }>{ val.nama_pelapor.name }</p>
                <div className='mt-4'>
                  <p style={ { color: '#9B9FB7', fontSize: '14px', fontWeight: 'normal' } }>{ val.tanggal }</p>
                </div>
              </Col>
              <Col>
                <img onError={ e => addDefaultSrc(e) } src={ process.env.REACT_APP_BE_URL + '/' + val?.photos[0]?.image_link  } className='img-thumbnail' alt='img' />
                <div className='mt-4'>
                  <p>{ val.laporan }</p>
                </div>
              </Col>
            </Row>
            <hr />
            </>
          );
        })
      }
      </Card>
    </Fragment>
  );
}
