import ClientPages from "views/pages/client/index.js";
import LaporanPages from "views/pages/laporan";
import SecurityPages from "views/pages/security";
import Tambah from "views/pages/forms/Tambah";
import ProfilePages from "views/pages/profile";
import DetailClient from "views/pages/client/details";
import AdminPages from "views/pages/adminPages";
import ProfileDetailPages from "views/pages/profile/detail/profiledetail";
import DashboardAdmin from "views/pages/dashboards/DashboardAdmin";
import AbsensiSecurity from "views/pages/security/absensi";

const routesSA = [
  {
    path: "/dashboard",
    icon: "ni ni-tv-2 text-white",
    name: "Dashboard",
    miniName: "D",
    component: DashboardAdmin,
    layout: "/superadmin",
    state: "dashboardsCollapse",
    show: true,
  },
  {
    path: "/admin-pages",
    icon: "fa fa-user text-white",
    name: "Admin",
    miniName: "D",
    component: AdminPages,
    layout: "/superadmin",
    state: "dashboardsCollapse",
    show: true,
  },
  {
    path: "/laporan",
    icon: "ni ni-single-copy-04 text-white",
    name: "Laporan",
    miniName: "C",
    component: LaporanPages,
    layout: "/superadmin",
    state: "componentsCollapse",
    show: true,
  },
  {
    path: "/client",
    icon: "ni ni-briefcase-24 text-white",
    name: "Client",
    miniName: "T",
    component: ClientPages,
    layout: "/superadmin",
    state: "examplesCollapse",
    show: true,
  },
  {
    path: "/security",
    icon: "ni ni-badge text-white",
    name: "Security",
    miniName: "V",
    component: SecurityPages,
    layout: "/superadmin",
    state: "formsCollapse",
    show: true,
  },
  {
    path: "/tambah-pengguna",
    icon: "ni ni-fat-add text-white",
    name: "Tambah Pengguna",
    miniName: "TBH",
    component: Tambah,
    layout: "/superadmin",
    state: "formsCollapse",
    show: true,
  },
  {
    path: "/profile",
    component: ProfilePages,
    name: "",
    layout: "/superadmin",
    show: false,
  },
  {
    path: "/detail-client-:client_id",
    component: DetailClient,
    name: "",
    layout: "/superadmin",
    show: false,
  },
  {
    path: "/profiles/:id",
    component: ProfileDetailPages,
    name: "",
    layout: "/superadmin",
    show: false,
  },
  {
    path: "/detailAbsensi-:id",
    component: AbsensiSecurity,
    name: "",
    layout: "/superadmin",
    show: false,
  },
];

export default routesSA;
