import React, { Fragment } from 'react';
import Router from 'router';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { store } from 'store';

export default function App() {
    return (
        <Fragment>
            <Provider store={ store }>
              <ToastContainer />
             <Router />
            </Provider>
        </Fragment>
    );
}
