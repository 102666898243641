export const SAVE_DATA_CLUSTER = 'SAVE_DATA_CLUSTER';
export const ADD_CLUSTER = 'ADD_CLUSTER';
export const ADD_MARKER = 'ADD_MARKER';
export const REMOVE_MARKER = 'REMOVE_MARKER';
export const EDIT_CLUSTER = 'EDIT_CLUSTER';
export const CANCEL_MAP_CLUSTER = 'CANCEL_EDIT_CLUSTER';
export const UPDATE_CLUSTER = 'UPDATE_CLUSTER';
export const DELETE_CLUSTER = 'DELETE_CLUSTER';
export const GET_DETAIL_CLUSTER = 'GET_DETAIL_CLUSTER';
export const CLUSTER = 'CLUSTER';
export const CLUSTER_SUCCESS = 'CLUSTER_SUCCESS';
export const CLUSTER_FAIL = 'CLUSTER_FAIL';

