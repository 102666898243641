/* eslint-disable keyword-spacing */
/* eslint-disable space-before-blocks */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useFormik } from "formik";
import moment from "moment";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import API from "service/api/config/index";
import ModalPindahLokasi from "components/Modal/PindahLokasi";
import { toast } from "react-toastify";
import Section1 from "components/Molecules/Profile/details/Section1";
import Section2 from "components/Molecules/Profile/details/Section2";
import Section3 from "components/Molecules/Profile/details/Section3";
import Section4 from "components/Molecules/Profile/details/Section4";
import { validateProfiles } from "../../../../validates";
import { addDefaultSrc } from "../../../../helpers/brokenImg";
import UbahJabatan from "components/Modal/UbahJabatan";
import { ROLE_SUPER_ADMIN, PENGHUNI } from "helpers/types";
import UbahPasswordProfile from "components/Modal/UbahPasswordProfile";
import InputEmail from "./inputEmail";
import { ROLE_ADMIN } from "helpers/types";
import { ROLE_CLIENT } from "helpers/types";

function ProfileDetailPages() {
  const { id } = useParams();
  const [dataProfile, setDataProfile] = useState({});
  const [modalPindahLokasi, setModalPindahLokasi] = useState(false);
  const [modalUbahJabatan, setModalUbahJabatan] = useState(false);
  const [modalUbahPassword, setModalUbahPassword] = useState(false);
  const [disable, setDisabled] = useState(true);
  const avatarRef = useRef();
  const [day, setDay] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const [years, setYears] = useState(new Date());
  const [dayJoin, setDayJoin] = useState(new Date());
  const [monthJoin, setMonthJoin] = useState(new Date());
  const [yearsJoin, setYearsJoin] = useState(new Date());
  const [reRender, setRerender] = useState(false);
  const [tempImages, setTempImages] = useState("");
  const [email, setEmail] = useState("");
  const [typeRole, setTypeRole] = useState("");
  const roles = localStorage.getItem("role");

  const toggleModalPindahLokasi = () => {
    setModalPindahLokasi(!modalPindahLokasi);
  };

  const changeAvatar = () => {
    let avatar_profile = avatarRef.current.files[0];
    if (avatar_profile !== undefined && avatar_profile !== tempImages) {
      setTempImages(avatar_profile);
    }
  };

  useEffect(() => {
    fetchGetProfile();
  }, [reRender]);

  const componentbutton = () => {
    if (roles === ROLE_SUPER_ADMIN) {
      if (
        dataProfile.role?.role_name !== PENGHUNI &&
        dataProfile.role?.role_name !== ROLE_ADMIN &&
        dataProfile.role?.role_name !== ROLE_CLIENT
      ) {
        return (
          <>
            <div className="wrap-label-place-detail">
              <button
                className="btn-pindah-lokasi"
                onClick={() => setModalUbahPassword(!modalUbahPassword)}
              >
                <span>Ubah Password</span>
              </button>
            </div>
            <div className="wrap-label-place-detail">
              <button
                className="btn-pindah-lokasi"
                onClick={() => setModalUbahJabatan(!modalUbahJabatan)}
              >
                <span>Ubah Jabatan</span>
              </button>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="wrap-label-place-detail">
              <button
                className="btn-pindah-lokasi"
                onClick={() => setModalUbahPassword(!modalUbahPassword)}
              >
                <span>Ubah Password</span>
              </button>
            </div>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const fetchGetProfile = useCallback(async () => {
    try {
      const response = await API.getUserProfileDetails(id);
      if (response.status === 200) {
        setTypeRole(response.data.data[0].role.role_name);
        setDataProfile(response.data.data[0]);
        formik.setFieldValue("no_rekening", response.data.data[0].no_rekening);
        formik.setFieldValue("no_kta", response.data.data[0].no_kta);
        formik.setFieldValue("no_bpjs", response.data.data[0].no_bpjs);
        formik.setFieldValue(
          "no_bpjs_kesehatan",
          response.data.data[0].no_bpjs_kesehatan,
        );
        formik.setFieldValue("no_npwp", response.data.data[0].no_npwp);
        formik.setFieldValue(
          "pendidikan_terakhir",
          response.data.data[0].pendidikan_terakhir,
        );
        formik.setFieldValue("fullname", response.data.data[0].name);
        formik.setFieldValue("gender", response.data.data[0].gender);
        formik.setFieldValue("brith_place", response.data.data[0].birth_plc);
        formik.setFieldValue("nik", response.data.data[0].nik);
        formik.setFieldValue("religion", response.data.data[0].agama);
        formik.setFieldValue("address", response.data.data[0].alamat);
        formik.setFieldValue("city", response.data.data[0].kota);
        formik.setFieldValue("province", response.data.data[0].provinsi);
        formik.setFieldValue("post_code", response.data.data[0].kode_pos);
        formik.setFieldValue("telephone", response.data.data[0].phone);
        formik.setFieldValue("rekening", response.data.data[0].no_rekening);
        formik.setFieldValue("email", response.data.data[0].email);
        setEmail(response.data.data[0].email);
        if (response.data.data[0].birth_dt) {
          formik.setFieldValue("day", response.data.data[0].birth_dt);
          formik.setFieldValue("month", response.data.data[0].birth_dt);
          formik.setFieldValue("years", response.data.data[0].birth_dt);
          setDay(new Date(response.data.data[0].birth_dt));
          setMonth(new Date(response.data.data[0].birth_dt));
          setYears(new Date(response.data.data[0].birth_dt));
        }
        if (response.data?.data[0]?.join_dt) {
          formik.setFieldValue("day_join", response.data.data[0].join_dt);
          formik.setFieldValue("month_join", response.data.data[0].join_dt);
          formik.setFieldValue("years_join", response.data.data[0].join_dt);
          setDayJoin(new Date(response.data.data[0].join_dt));
          setMonthJoin(new Date(response.data.data[0].join_dt));
          setYearsJoin(new Date(response.data.data[0].join_dt));
        }
      }
    } catch (e) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: "",
      gender: "",
      brith_place: "",
      day: "",
      month: "",
      years: "",
      nik: "",
      religion: "",
      address: "",
      city: "",
      province: "",
      post_code: "",
      telephone: "",
      rekening: "",
      email: "",
      isNotRole: false,
      no_kta: "",
      no_rekening: "",
      day_join: "",
      month_join: "",
      years_join: "",
      no_bpjs: "",
      no_bpjs_kesehatan: "",
      no_npwp: "",
      pendidikan_terakhir: "",
    },
    validationSchema:
      typeRole === "CLIENT"
        ? validateProfiles.profileDetailClient
        : typeRole === "PENGHUNI"
          ? validateProfiles.profileDetailPenghuni
          : validateProfiles.profileDetail,
    onSubmit: async (values) => {
      let year = moment(years).format("YYYY");
      let months = moment(month).format("MM");
      let days = moment(day).format("DD");
      let dates = year + "-" + months + "-" + days;
      let year_join = moment(yearsJoin).format("YYYY");
      let months_join = moment(monthJoin).format("MM");
      let days_join = moment(dayJoin).format("DD");
      let dates_join = year_join + "-" + months_join + "-" + days_join;

      const body = {
        email: values.email,
        name: values.fullname,
        gender: values.gender,
        birth_plc: values.brith_place,
        birth_dt: dates,
        nik: values.nik,
        agama: values.religion,
        phone: values.telphone,
        alamat: values.address,
        no_rekening: values.rekening,
        kota: values.city,
        provinsi: values.province,
        kode_pos: values.post_code,
        no_kta: values.no_kta,
        no_rekening: values.no_rekening,
        join_dt: dates_join,
        no_bpjs: values.no_bpjs,
        no_bpjs_kesehatan: values.no_bpjs_kesehatan,
        no_npwp: values.no_npwp,
        pendidikan_terakhir: values.pendidikan_terakhir,
      };
      try {
        const response = await API.putProfiles(body);
        if (response.status === 200) {
          setRerender(!reRender);
          setDisabled(true);
          toast.success("Success data update !!");
        }
      } catch (e) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  const uploadPhotoProfile = async (e) => {
    const formDataPhoto = new FormData();
    formDataPhoto.append("email", email);
    formDataPhoto.append("image_link", e.target.files[0]);
    try {
      const response = await API.putPhotoProfile(formDataPhoto);
      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error("upload fail !!");
    }
  };

  return (
    <>
      <ModalPindahLokasi
        toggle={toggleModalPindahLokasi}
        modal={modalPindahLokasi}
        data={dataProfile}
        reRender={reRender}
        setRender={() => setRerender(true)}
      />
      <UbahJabatan
        toggle={() => setModalUbahJabatan(!modalUbahJabatan)}
        isOpen={modalUbahJabatan}
        data={dataProfile}
        setRender={() => setRerender(true)}
      />
      <UbahPasswordProfile
        toggle={() => setModalUbahPassword(!modalUbahPassword)}
        isOpen={modalUbahPassword}
        data={dataProfile}
        userID={dataProfile.user_id}
        setRender={() => setRerender(true)}
      />
      <SimpleHeader name="Form components" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="8">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Row>
                    <div className="px-2">Profile</div>
                    <div className="ml-2">
                      <button
                        onClick={() => setDisabled(!disable)}
                        type="button"
                        className="btn btn-edit-profile btn-rounded"
                      >
                        Edit
                      </button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <form onSubmit={formik.handleSubmit}>
                    <Section1
                      formik={formik}
                      disable={disable}
                      typeRole={typeRole}
                    />
                    <hr />
                    <Section2
                      formik={formik}
                      disable={disable}
                      day={day}
                      setDay={setDay}
                      month={month}
                      setMonth={setMonth}
                      years={years}
                      setYears={setYears}
                      typeRole={typeRole}
                    />
                    {typeRole !== "PENGHUNI" && typeRole !== "CLIENT" ? (
                      <hr />
                    ) : null}
                    <Section3
                      formik={formik}
                      disable={disable}
                      typeRole={typeRole}
                      day={dayJoin}
                      setDay={setDayJoin}
                      month={monthJoin}
                      setMonth={setMonthJoin}
                      years={yearsJoin}
                      setYears={setYearsJoin}
                    />
                    <hr />
                    <Section4
                      formik={formik}
                      disable={disable}
                      typeRole={typeRole}
                    />
                    <Row>
                      <div className="mx-3">
                        {!disable ? (
                          <button
                            type="submit"
                            className="ml-3 btn btn-edit-profile btn-rounded"
                          >
                            Save
                          </button>
                        ) : null}
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col>
            <Row>
              <Col lg="12" md="12" className="mb-lg-0 mb-4">
                <Card className="wrap-profile-card">
                  <div className="mx-auto white">
                    <img
                      src={
                        tempImages === ""
                          ? dataProfile?.image_link &&
                            `${process.env.REACT_APP_BE_URL}/` +
                              dataProfile?.image_link
                          : URL.createObjectURL(tempImages)
                      }
                      alt="avatar"
                      onError={(e) => addDefaultSrc(e)}
                      className="avatar avatar-xl rounded-circle"
                      height={100}
                      width={100}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      name="upload"
                      className="img-uploads"
                      onChange={(e) => {
                        formik.handleChange(e);
                        changeAvatar();
                        uploadPhotoProfile(e);
                      }}
                      ref={avatarRef}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className="text-center image-uploads"
                      onClick={() => avatarRef.current.click()}
                    >
                      <a href="#upload" role="button">
                        <i
                          className="fa fa-camera icon-cameras"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    {formik.touched.upload && formik.errors.upload ? (
                      <div style={{ color: "red" }}>{formik.errors.upload}</div>
                    ) : null}
                  </div>
                  <CardBody>
                    <h4 className="font-weight-bold txt-name-personal">
                      {dataProfile.name}
                    </h4>
                    <h4 className="txt-name-personal">
                      {dataProfile?.role?.role_name}
                    </h4>
                    <hr />
                    <InputEmail
                      userID={dataProfile?.user_id}
                      email={dataProfile?.email}
                      setRender={() => setRerender(true)}
                    />
                    <div className="wrap-label-profile-detail">
                      <i className="fa fa-phone" aria-hidden="true" />
                      <span>{dataProfile.phone}</span>
                    </div>
                    <div className="wrap-label-place-detail">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      <div>
                        <div>{dataProfile.client?.client_name}</div>
                        <div>{dataProfile.cluster?.cluster_name}</div>
                      </div>
                    </div>
                    {componentbutton()}
                    <div className="wrap-label-place-detail">
                      <button
                        className="btn-pindah-lokasi"
                        onClick={() => {
                          toggleModalPindahLokasi();
                          setRerender(!reRender);
                        }}
                      >
                        <span>Pindahkan Lokasi</span>
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProfileDetailPages;
