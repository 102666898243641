import {  SAVE_INFO_ABSEN, SAVE_RESULT_DASHBOARD } from './actionTypes';

const initialState = {
  loading: false,
  result: {},
  data: []
};

const dashboardReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case SAVE_RESULT_DASHBOARD:
      state = {
        ...state,
        result: action.payload,
      };
      break;
    case SAVE_INFO_ABSEN:
      state = {
        ...state,
        data: action.payload,
      };
      break;
  }

  return state;
};


export default dashboardReducer;
