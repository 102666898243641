/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useState } from 'react';
import { Input, Label, Modal } from 'reactstrap';
import IconClose from 'assets/img/icons/bi_x-lg.svg';
import IconArrowLeft from 'assets/img/icons/arrow-left.svg';
import Inputs from 'components/Forms';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { actionUpdateEvaluasi } from 'store/security/actions';

export default function ModalEditEvaluasi(props) {
  const { detail, setReRender } = props;
  const [dates, setDates] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    formik.setFieldValue('evaluasi_id', detail.evaluasi_id);
    formik.setFieldValue('evaluasi', detail.desc_info);
    formik.setFieldValue('dievaluasi_oleh', detail.evaluated_by?.name);
    setDates(new Date(detail.createDateTime));

  }, [props.isOpen]);

  const formik = useFormik({
    initialValues: {
      tanggal_evaluasi: '',
      dievaluasi_oleh: '',
      evaluasi: '',
      evaluasi_id: ''
    },
    validationSchema: Yup.object({
      evaluasi: Yup.string().required('Evaluasi harus diisi'),
    }),
    onSubmit: value => {
      let body = {
        evaluasi_id: value.evaluasi_id,
        desc_info: value.evaluasi
      };
      dispatch(
        actionUpdateEvaluasi(body, () => {
          props.toggle();
          setReRender();
        })
      );
    }
  });
  return (
    <Fragment>
      <Modal
        size='xl'
        isOpen={ props.isOpen }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <div className='p-4'>
          <div className='d-flex'>
            <div className='flex-grow-1'>
              <div className='d-flex'>
                <div className='pr-2'>
                  <img src={ IconArrowLeft } alt='icon' />
                </div>
                <div
                  style={ { color: '#2E2D2D', fontSize: '16px' } }
                >
                  Kembali
                </div>
              </div>
            </div>
            <div onClick={ () => props.toggle() } style={ { cursor: 'pointer' } }>
              <img src={ IconClose } alt='icon' />
            </div>
          </div>
          <div
            className='d-flex justify-content-center'
            >
            <p style={ { color: '#2E2D2D', fontSize: '16px', fontWeight: 'bold' } }>EDIT EVALUASI</p>
          </div>
          <form onSubmit={ formik.handleSubmit }>
            <div className='px-8'>
              <div className='my-3'>
                <Label>Tanggal Evaluasi</Label>
                <DatePicker
                  name='tanggal_evaluasi'
                  selected={ dates }
                  onChange={ date => setDates(date) }
                  className='input-date-picker-customs_2'
                  dateFormat='dd MMMM yyyy'
                  disabled
                />
              </div>
              <Inputs
                formik={ formik }
                label='Dievaluasi Oleh'
                name='dievaluasi_oleh'
                value={ formik.values.dievaluasi_oleh }
                readOnly={ true }
              />
              <div className='my-3'>
                <Label>Evaluasi</Label>
                <Input
                  name='evaluasi'
                  placeholder=''
                  type='textarea'
                  value={ formik.values.evaluasi }
                  maxlength='400'
                  onChange={ formik.handleChange }
                />
                { formik.touched.evaluasi && formik.errors.evaluasi ? (
                  <div style={ { color: 'red' } }>{ formik.errors.evaluasi }</div>
                ) : null }
              </div>
              <div>
                  <button
                    type='submit'
                    className='btn btn-modal btn-rounded'
                  >
                    Simpan
                  </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}
