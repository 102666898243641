/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React from 'react';
// react library for routing
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

// import routes from "../router/routes.js";
import routesClient from 'router/routes-client.js';

function Client() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const token = localStorage.getItem('token');
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = routesClient => {
    return routesClient.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/client') {
        return (
          <Route
            path={ prop.layout + prop.path }
            component={ prop.component }
            key={ key }
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = () => {
    for (let i = 0; i < routesClient.length; i++) {
      if (
        location.pathname.indexOf(
          routesClient[i].layout + routesClient[i].path
        ) !== -1
      ) {
        return routesClient[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };

  return (
    <>
      <Sidebar
        routes={ routesClient }
        toggleSidenav={ toggleSidenav }
        sidenavOpen={ sidenavOpen }
        logo={ {
          innerLink: '/',
          imgSrc: require('assets/img/brand/Logo-side.svg').default,
          imgAlt: '...',
        } }
      />
      <div className='main-content' ref={ mainContentRef }>
        {
          token ? (
            <AdminNavbar
              theme={ getNavbarTheme() }
              toggleSidenav={ toggleSidenav }
              sidenavOpen={ sidenavOpen }
              brandText={ getBrandText(location.pathname) }
            />
          ) : null
        }
        <Switch>
          { token ? getRoutes(routesClient) : <Redirect from='*' to='/login' /> }
          <Redirect from='*' to='/client/dashboard' />
        </Switch>
        <AdminFooter />
      </div>
      { sidenavOpen ? (
        <div className='backdrop d-xl-none' onClick={ toggleSidenav } />
      ) : null }
    </>
  );
}

export default Client;
