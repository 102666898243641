/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import { ADD_CLUSTER, DELETE_CLUSTER, SAVE_DATA_CLUSTER } from './actionTypes';



export const actionListClusters = (cluster_name, titik, callBack) => async dispatch => {
    try {
      const res = await API.getClientCluster(cluster_name, titik);
      if (res.status < 299) {
        dispatch({
          type: SAVE_DATA_CLUSTER,
          payload: res.data?.data
        });
        callBack(res.data?.data);
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};

export const actionAddCluster = (body, callBack) => async dispatch => {
  try {
    const res = await API.postClientCluster(body);
    if (res.status < 299) {
      toast.success('Sukses tambah cluster');
      dispatch({
        type: ADD_CLUSTER
      });
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionUpdateCluster = (cluster_id, body, callback) => async dispatch => {
  try {
    const res = await API.putClientCluster(cluster_id, body);
    if (res.status < 299) {
      toast.success('Sukses perbarui cluster');
      callback();
      dispatch({
        type: UPDATE_CLUSTER
      });
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionDeleteCluster = (body, callBack) => async dispatch => {
  try {
    const res = await API.deleteClient(body);
    if (res.status < 299) {
      toast.success('Sukses Hapus Cluster');
      callBack();
      dispatch({
        type: DELETE_CLUSTER
      });
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionCluster = (queryParams, callBack) => async() => {
  try {
    const res = await API.getCluster(`?client_name=${queryParams}`);
    if (res.status < 299) {
      callBack(res.data?.data);
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};


