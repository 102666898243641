import { Redirect } from 'react-router';
import ResetPassword from 'views/pages/auth/resetPassword';
import ForgotPages from '../views/pages/auth/forgotpassword/Forgot';
import LoginPages from '../views/pages/auth/login/Login';
import RegisterPages from '../views/pages/auth/register/Register';

const publicRoute = [
	{ path: '/reset-password', component: ResetPassword },
	{ path: '/login', component: LoginPages },
	{ path: '/forgot-password', component: ForgotPages },
	{ path: '/register', component: RegisterPages },
  { path: '/', component: () => <Redirect to='/login'/> },
];

export { publicRoute };