/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { Label } from "reactstrap";
import Unclocked from "assets/img/Unlocked.svg";
import Locked from "assets/img/locked.svg";

export default function InputsAuth(props) {
  const { placeholder, type, name, label, formik, value, readOnly } = props;
  const [showPassword, setshowPassword] = useState(false);
  let tipe = type;
  if (type === "passsword" || showPassword) {
    tipe = "text";
  }
  return (
    <div>
      <Label>{label}</Label>
      <div className="d-flex justify-content-center align-items-center">
        <div className="input-icon">
          {showPassword ? (
            <img
              src={Unclocked}
              style={{ height: "20px" }}
              alt="icon"
              onClick={() => setshowPassword(!showPassword)}
            />
          ) : (
            <img
              src={Locked}
              style={{ height: "20px" }}
              alt="icon"
              onClick={() => setshowPassword(!showPassword)}
            />
          )}
        </div>
        <input
          type={tipe}
          className="input-auth"
          placeholder={placeholder}
          name={name}
          value={value}
          readOnly={readOnly}
          onChange={(e) => formik.setFieldValue(name, e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {formik.touched[name] && formik.errors[name] ? (
          <div style={{ color: "red" }}>{formik.errors[name]}</div>
        ) : null}
      </div>
    </div>
  );
}
