/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { actionLogout } from 'store/auth/actions';

function ModalLogout(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const nameUser = useSelector(state => state.authReducer.data);

    const Logout = () => {
        dispatch(
            actionLogout(() => {
                history.push('/login');
            })
        );
    };
    return (
        <div>
            <Modal size='md' isOpen={ props.modal } toggle={ props.toggle } centered={ true } backdrop='static'>
                <ModalHeader toggle={ props.toggle }>
                    <div className='d-flex justify-content-left' style={ { width: '120px' } }>
                        <span style={ { fontSize: '18px', marginLeft: '2%' } }>Logout</span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <span> Apakah Anda Yakin Untuk Keluar <strong> { nameUser } </strong> ? </span>
                    </div>
                </ModalBody>
                <div className='footers-modal'>
                    <Row>
                        <Col>
                            <button className='btn-submit-modals' onClick={ Logout }>
                                Yakin
                            </button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
}

export default ModalLogout;