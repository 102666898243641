/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import differenceTime from 'helpers/differenceTime';
import React, { Fragment, useCallback, useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import { actionNotification } from 'store/notification/actions';

export default function PageNotif() {
  const dispatch = useDispatch();
  const history = useHistory();
  const postsPerPage = 5;
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMore, setIsMore] = useState();
  const userID = JSON.parse(localStorage.getItem('userData'));
  
  useEffect(() => {
    fetchNotif();
  }, []);

  const fetchNotif = useCallback(() => {
    setIsLoading(true);
    dispatch(
      actionNotification(limit, offset, userID, res => {
        setIsMore(res.data.count);
        setIsLoading(false);
        setData(res.data.data);
      })
    );
  }, []);

  const fecthLoadMore = count => {
    setIsLoading(true);
    dispatch(
      actionNotification(limit, count, userID, res => {
        setIsMore(res.data.count);
        setIsLoading(false);
        setData(e => [...e, ...res.data.data]);
      })
    );
  };

  const handleMore = () => {
    fecthLoadMore(offset + postsPerPage);
    setOffset(offset + postsPerPage);
  };

  const routeChange = () => {
    const role = userID.role.role_name.toLowerCase();
    history.push(`/${role}/laporan`);
  };
  
  return (
    <Fragment>
      <div className='d-flex mx-4 my-4'>
        <span className='px-2' style={ { color: '#6174DD', fontWeight: '600', fontSize: '16px' } }>Dashboard</span>
        <span className='px-2'>-</span>
        <span className='px-2' style={ { color: '#9B9FB7', fontWeight: '600', fontSize: '16px' } }>Notifikasi</span>
      </div>
      <div className='mx-6 my-2'>
        <Card>
          <CardBody>
            <div className='mx-4' style={ { color: '#2C3147', fontWeight: 'bold', fontSize: '20px' } }>Notifikasi</div>
            {
              isLoading ? (
                <div className='my-4'>
                  <Skeleton count={ 5 } height={ 50 } />
                </div>
              ) :
              data.map((val, i) => {
                return (
                  <Card
                    key={ i }
                    className='my-3 card-list-notif-nonactive'
                    onClick={ routeChange }
                    >
                    <div className='d-flex bd-highlight align-items-center px-3'>
                      <div className='p-2 bd-highlight'>
                        <div className='dot-notif-nonactive bd-highlight' />
                      </div>
                      <div className='p-2 flex-grow-1 bd-highlight'>
                          <p className='content-notif content-title-notif'>{ val.notification?.type }</p>
                          <p className='content-notif content-sub-notif'>{ val.notification?.content }</p>
                      </div>
                      <div className='p-2 bd-highlight content-clock-notif'>{ differenceTime(val.notification.created_date) }</div>
                    </div>
                  </Card>
                );
              })
            }
            {
              isMore === 0 || isMore < 5 ? null : (
                <div
                  className='d-flex justify-content-center my-3'
                  style={ { color: '#9B9FB7', cursor: 'pointer' } }
                  onClick={ handleMore }
                  >
                  more
                </div>
              )
            }
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
}
