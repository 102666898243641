/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Modal } from 'reactstrap';
import IconQuestion from 'assets/img/Icon-question.svg';
import { addDefaultSrc } from 'helpers/brokenImg';
import { ButtonDefault } from 'components/Buttons/indes';
import { useDispatch, useSelector } from 'react-redux';
import { actionDeletePIC } from '../../../store/client/actions';

export default function ModalDeletePIC(props) {
    const { isOpen, toggle } = props;
    const dispatch = useDispatch();
    const detail_pic = useSelector(state => state.clientReducer.detail);

    const deletePIC = () => {
        !isOpen;
        dispatch(actionDeletePIC(detail_pic?.user_id, () => {
            props.setReRender();
            props.toggle();
        }));
    };

    return (
        <Modal
            size='md'
            isOpen={ isOpen }
            toggle={ toggle }
            centered={ true }
            backdrop='static'
        >
            <div className='px-3 py-2'>
                <div className='d-flex justify-content-between '>
                    <div className='mt-1 mb-2'>
                        <p className='title-text'> Hapus PIC</p>
                    </div>
                    <div
                        className='d-flex justify-content-end pr-3 pt-2'
                        onClick={ toggle }
                        style={ { cursor: 'pointer' } }
                    >
                        <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M1.61647 1.61647C1.85088 1.38213 2.16877 1.25049 2.50022 1.25049C2.83168 1.25049 3.14956 1.38213 3.38397 1.61647L10.0002 8.23272L16.6165 1.61647C16.7318 1.49709 16.8697 1.40186 17.0222 1.33635C17.1747 1.27084 17.3387 1.23635 17.5047 1.23491C17.6707 1.23347 17.8353 1.2651 17.9889 1.32795C18.1425 1.3908 18.2821 1.48361 18.3995 1.60098C18.5168 1.71835 18.6096 1.85791 18.6725 2.01153C18.7353 2.16515 18.767 2.32975 18.7655 2.49573C18.7641 2.6617 18.7296 2.82572 18.6641 2.97823C18.5986 3.13073 18.5034 3.26867 18.384 3.38397L11.7677 10.0002L18.384 16.6165C18.6117 16.8522 18.7377 17.168 18.7348 17.4957C18.732 17.8235 18.6005 18.137 18.3687 18.3687C18.137 18.6005 17.8235 18.732 17.4957 18.7348C17.168 18.7377 16.8522 18.6117 16.6165 18.384L10.0002 11.7677L3.38397 18.384C3.14822 18.6117 2.83247 18.7377 2.50472 18.7348C2.17697 18.732 1.86346 18.6005 1.6317 18.3687C1.39994 18.137 1.26848 17.8235 1.26563 17.4957C1.26278 17.168 1.38877 16.8522 1.61647 16.6165L8.23272 10.0002L1.61647 3.38397C1.38213 3.14956 1.25049 2.83168 1.25049 2.50022C1.25049 2.16877 1.38213 1.85088 1.61647 1.61647Z'
                                fill='black'
                            />
                        </svg>
                    </div>
                </div>
                <div className='my-3 text-center'>
                    <img onError={ e => addDefaultSrc(e) } src={ IconQuestion } alt='Icon' />
                    <div className='mt-4'>
                        <p className='title-text'>{ detail_pic?.name }</p>
                    </div>
                    <div className='mt-4'>
                        <p className='title-sub'>Anda yakin ingin menghapus PIC ini?</p>
                    </div>
                </div>
                <div>
                    <div className='d-flex mb-3 mt-5' style={ { width: '25%' } }>
                        <ButtonDefault
                            name='Hapus'
                            color='#fff'
                            background='#FF7A80'
                            onClick={ deletePIC }
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}
