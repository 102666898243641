/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import API from 'service/api/config';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory } from 'react-router';


function ModalBuatLaporan(props) {
    const [data, setData] = useState([]);
    const history = useHistory();

    const clientId = props?.data;
    
    useEffect(() => {
      API.getUserData('SECURITY', clientId).then(res => {
        if (res.data.success) {
          setData(res.data?.data);
        } else {
          toast.error('Error Fetch!!!');
        }
      });
    }, [clientId]);


    const option = data.map(val => {
      return { value: val.email, label: val.name };
    });

    const formik = useFormik({
      initialValues: {
        judulLaporan: '',
        laporan: '',
        namePelapor: ''
      },
      validationSchema: Yup.object({
        judulLaporan: Yup.string()
          .min(5, 'Must be 5 characters or less')
          .required('Required !!'),
        laporan: Yup.string()
          .min(5, 'Must be 5 characters or less')
          .required('Required !!'),
        namePelapor: Yup.string()
          .required('Required !!'),
      }),
      onSubmit: values => {
        const body = {
          email: values.namePelapor,
          judul_laporan: values.judulLaporan,
          laporan: values.laporan
        };
        API.postAddLaporan(body).then(res => {
          // to do logic
          if (res.data.success) {
            toast.success('Data Success !!');
            props.toggle();
            history.go(0);
          }
        });
      },
    });

    return (
        <div>
            <Modal size='md' isOpen={ props.modal } toggle={ props.toggle } centered={ true } backdrop='static'>
              <form onSubmit={ formik.handleSubmit }>
                <ModalHeader toggle={ props.toggle }>
                    <div className='d-flex justify-content-left' style={ { width: '120px' } }>
                        <span style={ { fontSize: '18px', marginLeft: '2%' } }>Buat Laporan</span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <FormGroup>
                            <Label for='nama-pelapor'>
                                Nama Pelapor
                            </Label>
                            <Select
                            options={ option }
                            name='namePelapor'
                            onChange={ e => formik.setFieldValue('namePelapor', e.value) }
                            />
                            { formik.touched.namePelapor && formik.errors.namePelapor ? (
                                <div style={ { color: 'red' } }>{ formik.errors.namePelapor }</div>
                              ) : null }
                        </FormGroup>
                        <FormGroup>
                            <Label for='judul-laporan'>
                                Judul Laporan
                            </Label>
                            <Input
                                id='judul-laporan'
                                name='judulLaporan'
                                placeholder=''
                                type='text'
                                maxlength='50'
                                onChange={ formik.handleChange }
                            />
                            { formik.touched.judulLaporan && formik.errors.judulLaporan ? (
                              <div style={ { color: 'red' } }>{ formik.errors.judulLaporan }</div>
                            ) : null }
                        </FormGroup>
                        <FormGroup>
                            <Label for='laporan'>
                                Laporan
                            </Label>
                            <Input
                                id='laporan'
                                name='laporan'
                                placeholder=''
                                type='textarea'
                                maxlength='400'
                                onChange={ formik.handleChange }
                            />
                            { formik.touched.laporan && formik.errors.laporan ? (
                                <div style={ { color: 'red' } }>{ formik.errors.laporan }</div>
                              ) : null }
                        </FormGroup>
                    </div>
                </ModalBody>
                <div className='footers-modal'>
                    <Row>
                        <Col>
                            <button type='submit' className='btn-submit-modals'>
                                Tambahkan
                            </button>
                        </Col>
                    </Row>
                </div>
                </form>
            </Modal>
        </div>
    );
}

export default ModalBuatLaporan;