/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ReactDatePicker from 'react-datepicker';
import { Card, Container, Row, Col, Media } from 'reactstrap';

import ModalViewItem from 'components/Modal/ViewItem';
import API from 'service/api/config';
import moment from 'moment';
import Paginations from 'components/Tabels/Paginations';
import ModalPhotos from 'components/Modal/ModalPhotos';
import { addDefaultSrc } from 'helpers/brokenImg';
import { ROLE_CLIENT } from 'helpers/types';

function KelengkapanPages() {
  const componentRef = React.useRef(null);
  const [modalViewItem, setModalViewItem] = useState(false);
  const [isOpenModalPhotos, setIsOpenModalPhotos] = useState(false);
  const [dates, setDates] = useState(new Date());
  const getDate = moment(dates).format('YYYY-MM-DD');
  const [isDetailPhotos, setIsDetailPhotos] = useState('');
  const [kelengkapan, setKelengkapan] = useState([]);
  const [data, setData] = useState({});
  const [dataKelengkapanDetail, setDataKelengkapanDetail] = useState('');
  const userData = JSON.parse(localStorage.getItem('userData'));

  const toggleModalViewItem = () => {
    setModalViewItem(!modalViewItem);
  };

  const handleEditClient = props => {
    toggleModalViewItem(props);
    setData(props);
  };

  const evaluasiHandle = kelengkapan => {
    return (
      <div
        style={ {
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal',
          margin: '10px',
        } }
      >
        <button
          style={ {
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
          } }
        >
          <span
            style={ { color: '#6174DD', fontWeight: 'bold' } }
            onClick={ () => handleEditClient(kelengkapan) }
          >
            Lihat Item
          </span>
        </button>
      </div>
    );
  };

  const detailFoto = row => {
    let img = row.absensi !== null ? row.absensi?.kelengkapan?.image_link : '';
    return (
      <div onClick={ () => { setIsOpenModalPhotos(!isOpenModalPhotos); setIsDetailPhotos(img); } }>
        <Media className='align-items-center'>
          <div>
             <img className='img-thumbnail' onError={ addDefaultSrc } alt='...' src={ `${process.env.REACT_APP_BE_URL}/` + img } height={ 50 } width={ 50 } />
          </div>
        </Media>
      </div>
    );
  };

  useEffect(() => {
    if (userData.role.role_name === ROLE_CLIENT) {
      API.getAbsensiKelengkapan(`user/absensi?kelengkapan=${true}&tanggal=${getDate}&client_id=${userData.client.client_id}`).then(res => {
        setKelengkapan(res?.data?.data);
      });
    } else {
      API.getAbsensiKelengkapan(`user/absensi?kelengkapan=${true}&tanggal=${getDate}`).then(res => {
        setKelengkapan(res?.data?.data);
      });
    }
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      setDataKelengkapanDetail(row);
    },
  };

  const handleDate = val => {
    setDates(val);
    const date = moment(val).format('YYYY-MM-DD');
    if (userData.role.role_name === 'SUPERADMIN') {
      API.getAbsensiKelengkapan(`user/absensi?kelengkapan=${true}&tanggal=${date}`).then(res => {
        setKelengkapan(res?.data?.data);
      });
    } else {
      API.getAbsensiKelengkapan(`user/absensi?kelengkapan=${true}&tanggal=${date}&client_id=${userData.client.client_id}`).then(res => {
        setKelengkapan(res?.data?.data);
      });
    }
  };

  return (
    <>
      <ModalViewItem
        toggle={ toggleModalViewItem }
        modal={ modalViewItem }
        data={ data }
        detailKelengkapan={ dataKelengkapanDetail }
      />
       <ModalPhotos
        toggle={ () => setIsOpenModalPhotos(!isOpenModalPhotos) }
        isOpen={ isOpenModalPhotos }
        isDetailPhotos={ isDetailPhotos }
      />
      
      <Card body>
        <Container fluid>
          <Row>
            <Col xs={ 12 } sm={ 5 }>
              <div className='wrap-datepick'>
                <ReactDatePicker
                  selected={ dates }
                  onChange={ date => handleDate(date) }
                  dateFormat='yyyy-MM-dd'
                  className='datepicks'
                />
              </div>
            </Col>
          </Row>
        </Container>
        <ToolkitProvider
          data={ kelengkapan }
          keyField={ kelengkapan }
          columns={ [
            {
              dataField: 'name',
              text: 'NAMA',
              sort: true,
              headerAttrs: { width: 50 },
              attrs: { width: 50, class: 'EditRow' },
            },
            {
              dataField: 'kelengkapanMessage',
              text: 'KELENGKAPAN',
              sort: true,
              headerAttrs: { width: 10 },
              attrs: { width: 50, class: 'EditRow' },
            },
            {
              dataField: 'image_link',
              text: 'FOTO',
              sort: false,
              formatter: (cell, row) => detailFoto(row),
              headerAttrs: { width: 10 },
              attrs: { width: 10, class: 'EditRow' },
            },
            {
              dataField: 'absensis[0]',
              text: '',
              sort: false,
              formatter: kelengkapan => evaluasiHandle(kelengkapan),
              headerAttrs: { width: 10 },
              attrs: { width: 10, class: 'EditRow' },
            },
          ] }
          search
        >
          { props => (
            <div className='py-4 table-responsive'>
              <BootstrapTable
                ref={ componentRef }
                { ...props.baseProps }
                bootstrap4={ true }
                pagination={ Paginations }
                bordered={ false }
                id='react-bs-table'
                rowEvents={ rowEvents }
              />
            </div>
          ) }
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default KelengkapanPages;
