import React, { useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Card, CardHeader, Row } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector } from 'react-redux';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: false,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ }) => (
    <div className='dataTables_length' id='datatable-basic_length' />
  ),
});

export default function TableAbsen() {
  const componentRef = useRef(null);
  const data = useSelector(state => state.dashboardReducer.data);

  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let d = new Date();
  let monthName = month[d.getMonth()];
  let dayName = days[d.getDay()];
  let date = d.getDate() + ' ' + monthName + ' ' + d.getFullYear();

  return (
    <Row>
      <div className='col'>
        <Card>
          <CardHeader className='border-0'>
            <span className='text-title'>Absen</span>
            <span className='text-active'>
              { dayName }, { date }
            </span>
          </CardHeader>
          <ToolkitProvider
            data={ data }
            value='5'
            keyField='name'
            columns={ [
              {
                dataField: 'name',
                text: 'NAMA',
                sort: false,
              },
              {
                dataField: 'absensi.check_in',
                text: 'CHECK-IN',
                sort: false,
              },
              {
                dataField: 'absensi.check_out',
                text: 'CHECK-OUT',
                sort: false,
              },
              {
                dataField: 'client.jam_kerja',
                text: 'JAM KERJA',
                sort: false,
              },
            ] }
            search
          >
            { props => (
              <div className='py-1 table-style'>
                <BootstrapTable
                  ref={ componentRef }
                  { ...props.baseProps }
                  bootstrap4={ true }
                  pagination={ pagination }
                  bordered={ false }
                  id='react-bs-table'
                />
              </div>
            ) }
          </ToolkitProvider>
        </Card>
      </div>
    </Row>
  );
}
