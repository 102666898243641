/* eslint-disable no-undef */
import { toast } from "react-toastify";
import API from "service/api/config";
import {
  SIGNIN,
  SIGNIN_ERROR,
  SIGNIN_SUCCESS,
  LOGOUT,
  FORGOTE,
  FORGOTE_SUCCESS,
  FORGOTE_ERROR,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
} from "./actionTypes";

export const actionSignin = (body, callBack) => async (dispatch) => {
  // todo logic hit api
  dispatch({
    type: SIGNIN,
  });
  try {
    const res = await API.postLogin(body);
    if (res.status === 201) {
      dispatch({
        type: SIGNIN_SUCCESS,
        payload: res.data.data.name,
      });
      const role = res?.data?.data?.role?.role_name;
      toast.success(`Welcome ${res.data.data.name}`);
      localStorage.setItem("userData", JSON.stringify(res.data.data));
      localStorage.setItem("role", res?.data?.data?.role?.role_name);
      localStorage.setItem("token", res?.data?.access_token);
      localStorage.setItem("user_id", res?.data?.data?.user_id);
      callBack(role);
    }
  } catch (e) {
    dispatch({
      type: SIGNIN_ERROR,
    });
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error("Opsss. Something When Wrong !!!");
    }
  }
};

export const actionLogout = (callBack) => async (dispatch) => {
  // todo logic hit api
  dispatch({
    type: LOGOUT,
  });
  try {
    localStorage.clear();
    callBack();
    // const res = await API.postLogin(body);
    // if (res.status === 201) {
    //   dispatch({
    //     type: SIGNIN_SUCCESS
    //   });
    //   const role = res?.data?.data?.role?.role_name;
    //   toast.success(`Welcome ${res.data.data.name}`);
    //   localStorage.setItem('userData', JSON.stringify(res.data.data));
    //   localStorage.setItem('role', res?.data?.data?.role?.role_name);
    //   localStorage.setItem('token', res?.data?.access_token);
    //   localStorage.setItem('user_id', res?.data?.data?.user_id);
    //   callBack(role);
    // }
  } catch (e) {
    // dispatch({
    //   type: SIGNIN_ERROR
    // });
    if (e.status === 401) {
      toast.error(e.data.message);
    } else if (e.status === 500) {
      toast.error(e.data.message);
    } else if (e.status === 400) {
      toast.error(e.data.message);
    }
  }
};

export const actionForgotePassword = (body) => async (dispatch) => {
  // todo logic hit api
  dispatch({
    type: FORGOTE,
  });
  try {
    const res = await API.postforgote(body);
    if (res.status < 299) {
      toast.success("Email terkirim, silahkan check email anda");
      dispatch({
        type: FORGOTE_SUCCESS,
      });
    }
  } catch (e) {
    dispatch({
      type: FORGOTE_ERROR,
    });
    if (e.status === 401) {
      toast.error(e.data.message);
    } else if (e.status === 500) {
      toast.error(e.data.message);
    } else if (e.status === 400) {
      toast.error(e.data.message);
    }
  }
};

export const actionResetPassword = (body, callBack) => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD,
  });
  try {
    const res = await API.postResetPassword(body);
    if (res.status < 299) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
      callBack();
    }
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD_ERROR,
    });
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error("Opsss. Something When Wrong !!!");
    }
  }
};

export const actionChangePassword = (body, callBack) => async () => {
  // dispatch({
  //   type: RESET_PASSWORD,
  // });
  try {
    const res = await API.postChangePassword(body);
    if (res.status < 299) {
      // dispatch({
      //   type: RESET_PASSWORD_SUCCESS,
      // });
      toast.success("Update password berhasil");
      callBack();
    }
  } catch (e) {
    // dispatch({
    //   type: RESET_PASSWORD_ERROR
    // });
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error("Opsss. Something When Wrong !!!");
    }
  }
};
