/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";

export default function Section2(props) {
  const { formik, disable, day, setDay, month, setMonth, years, setYears } =
    props;

  return (
    <Fragment>
      <div className="wrap-form">
        <Label>No. Telepon</Label>
        <Input
          name="telphone"
          type="number"
          value={formik.values.telphone}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.telphone && formik.errors.telphone ? (
          <div style={{ color: "red" }}>{formik.errors.telphone}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>Email</Label>
        <Input
          name="email"
          type="text"
          value={formik.values.email}
          disabled={true}
          onChange={formik.handleChange}
        />
        {formik.touched.email && formik.errors.email ? (
          <div style={{ color: "red" }}>{formik.errors.email}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>Tempat Tanggal Lahir</Label>
        <Input
          name="brith_place"
          type="text"
          value={formik.values.brith_place}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.brith_place && formik.errors.brith_place ? (
          <div style={{ color: "red" }}>{formik.errors.brith_place}</div>
        ) : null}
      </div>
      <Row>
        <Col>
          <div className="">
            <Label>Hari</Label>
            <DatePicker
              name="day"
              selected={day}
              onChange={(date) => {
                setDay(date);
                formik.setFieldValue("day", date);
              }}
              dateFormat="dd"
              showDayDropdown
              disabled={disable}
              className="input-date-picker-customs"
              placeholderText="Hari"
            />
            {formik.touched.day && formik.errors.day ? (
              <div style={{ color: "red" }}>{formik.errors.day}</div>
            ) : null}
          </div>
        </Col>
        <Col>
          <div className="">
            <Label>Bulan</Label>
            <DatePicker
              name="month"
              selected={month}
              onChange={(date) => {
                setMonth(date);
                formik.setFieldValue("month", date);
              }}
              dateFormat="MM"
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
              disabled={disable}
              className="input-date-picker-customs"
            />
            {formik.touched.month && formik.errors.month ? (
              <div style={{ color: "red" }}>{formik.errors.month}</div>
            ) : null}
          </div>
        </Col>
        <Col>
          <div className="">
            <Label>Tahun</Label>
            <DatePicker
              name="years"
              selected={years}
              onChange={(date) => {
                setYears(date);
                formik.setFieldValue("years", date);
              }}
              showYearPicker
              dateFormat="yyyy"
              disabled={disable}
              className="input-date-picker-customs"
            />
            {formik.touched.years && formik.errors.years ? (
              <div style={{ color: "red" }}>{formik.errors.years}</div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
