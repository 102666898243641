/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import {
    DELETE_PATROL_POINT,
    GET_DETAIL,
    REGENERATE_QR_CODE,
    SAVE_PATROL_POINT,
    UPDATE_PATROL_POINT,
    UPDATE_STATUS
} from './actionTypes';

export const actionDetail = (cluster_id, callBack) => async dispatch => {
  // todo logic hit api
  try {
    const res = await API.getPatroliTitik(cluster_id);
    if (res.status === 200) {
      dispatch({
        type: GET_DETAIL,
        payload: res.data.data,
      });
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionUpdateStatus = (body, callBack) => async dispatch => {
  try {
    const res = await API.putUpdateStatusPatroliTitik(body);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_STATUS,
      });
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionDeletePoint = (body, callBack) => async dispatch => {
    try {
        const res = await API.deletePatroliTitik(body);
        if (res.status < 299) {
            toast.success('Delete data berhasil');
            dispatch({
                type: DELETE_PATROL_POINT
            });
            callBack();
        }
    } catch (e) {
        if (e !== undefined) {
            if (e.status === 401) {
                toast.error(e.data.message);
            } else if (e.status === 500) {
                toast.error(e.data.message);
            } else if (e.status === 400) {
                toast.error(e.data.message);
            }
        } else {
            toast.error('Opsss. Something When Wrong !!!');
        }
    }
};

export const actionPatrolPoint = (body, callBack) => async dispatch => {
    try {
        const res = await API.postPatroliTitik(body);
        if (res.status < 299) {
            toast.success('Sukses tambah titik');
            dispatch({
                type: SAVE_PATROL_POINT
            });
            callBack();
        }
    } catch (e) {
        if (e !== undefined) {
            if (e.status === 401) {
                toast.error(e.data.message);
            } else if (e.status === 500) {
                toast.error(e.data.message);
            } else if (e.status === 400) {
                toast.error(e.data.message);
            }
        } else {
            toast.error('Opsss. Something When Wrong !!!');
        }
    }
};

export const actionUpdatePatrolPoint = (body, callBack) => async dispatch => {
    try {
        const res = await API.putPatroliTitik(body);
        if (res.status < 299) {
            toast.success('Sukses perbarui titik');
            dispatch({
                type: UPDATE_PATROL_POINT
            });
            callBack();
        }
    } catch (e) {
        if (e !== undefined) {
            if (e.status === 401) {
                toast.error(e.data.message);
            } else if (e.status === 500) {
                toast.error(e.data.message);
            } else if (e.status === 400) {
                toast.error(e.data.message);
            }
        } else {
            toast.error('Opsss. Something When Wrong !!!');
        }
    }
};

export const actionRegenerateQR = (body, callBack) => async dispatch => {
    try {
        const res = await API.putRegenerateQR(body);
        if (res.status === 200) {
            toast.success('Success regenerate');
            dispatch({
                type: REGENERATE_QR_CODE
            });
            callBack();
        }
    } catch (e) {
        if (e !== undefined) {
            if (e.status === 401) {
                toast.error(e.data.message);
            } else if (e.status === 500) {
                toast.error(e.data.message);
            } else if (e.status === 400) {
                toast.error(e.data.message);
            }
        } else {
            toast.error('Opsss. Something When Wrong !!!');
        }
    }
};

