/* eslint-disable no-undef */
import { url } from './config';
import instance from 'axios';

const axios = instance.create();

const Post = async(path, params) => {
	const promise = new Promise((resolve, reject) => {
		axios.post(`${url}/${path}/`, params, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Methods': 'GET,POST',
        } }).then(res => {
          return resolve(res);
        }).catch(err => {
          return reject(err.response);
        });
	});
	return promise;
};

export default Post;