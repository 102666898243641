/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

export default function Section3(props) {
  const { formik, disable } = props;

  return (
    <Fragment>
      <div className='wrap-form'>
        <Label>NIK</Label>
        <Input
          name='nik'
          type='number'
          value={ formik.values.nik }
          disabled={ disable }
          onChange={ formik.handleChange }
          className='input-date-picker-customs'
        />
        { formik.touched.nik && formik.errors.nik ? (
          <div style={ { color: 'red' } }>{ formik.errors.nik }</div>
        ) : null }
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Label>Agama</Label>
            <Input
              name='religion'
              type='text'
              value={ formik.values.religion }
              disabled={ disable }
              onChange={ formik.handleChange }
            />
            { formik.touched.religion && formik.errors.religion ? (
              <div style={ { color: 'red' } }>{ formik.errors.religion }</div>
            ) : null }
          </div>
        </Col>
        <Col />
      </Row>
    </Fragment>
  );
}
