/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useCallback, useState } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { actionAllResult } from "store/dashboard/actions";
import { actionInfo } from "store/dashboard/actions";
import moment from "moment";
import TableAbsen from "components/Molecules/Dashboard/TableAbsen";
import LaporaToday from "components/Molecules/Dashboard/LaporanToday";
import { actionLaporan } from "store/laporan/actions";
import { addDefaultSrc } from "helpers/brokenImg";

function DashboardAdmin() {
  const dispatch = useDispatch();
  const [dates] = useState(new Date());
  const users = JSON.parse(localStorage.getItem("userData"));
  const result = useSelector((state) => state.dashboardReducer.result);

  useEffect(() => {
    if (users.role.role_name === "CLIENT") {
      fetchLaporan();
    }
    fetchGetResultDashboard();
    fetchInfo();
  }, []);

  const fetchGetResultDashboard = useCallback(() => {
    const body = {
      client_id:
        users.role.role_name === "SUPERADMIN" ? 0 : users.client?.client_id,
    };
    dispatch(actionAllResult(body));
  }, []);

  const fetchInfo = useCallback(() => {
    const date = moment(dates).format("YYYY-MM-DD");
    const body = {
      client_id:
        users.role.role_name === "SUPERADMIN" ? 0 : users.client?.client_id,
      date: date,
    };
    dispatch(actionInfo(body));
  }, []);

  const fetchLaporan = useCallback(() => {
    const date = moment(dates).format("YYYY-MM-DD");
    const tggl = `tanggal=${date}`;
    dispatch(actionLaporan(users.client?.client_id, tggl));
  }, []);

  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Card>
              {users?.client && (
                <div className="image-dashboard">
                  <img
                    onError={addDefaultSrc}
                    src={`${process.env.REACT_APP_BE_URL}/${users?.client?.image_link}`}
                    height={250}
                    width={250}
                    alt="img-dashboard"
                  />
                </div>
              )}
              <br />
              <CardHeader>
                <span className="text-title">Security</span>
                <span className="text-active">Aktif</span>
              </CardHeader>
              <CardBody>
                <ListGroup className="list-dashboard my--3" flush>
                  {result.activeUser?.length > 0 &&
                    result.activeUser?.map((val, idx) => (
                      <ListGroupItem className="px-0" key={idx}>
                        <Row className="align-items-center">
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a style={{ cursor: "auto" }}>
                                {val.user?.name ?? ""}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <span>{val.user?.client?.client_name ?? ""}</span>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8">
            {users.role.role_name === "CLIENT" ? (
              <LaporaToday />
            ) : (
              <TableAbsen />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DashboardAdmin;
