/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { ButtonGroup, Card, Container, Row, Col } from "reactstrap";

// core components
import { ButtonAdd } from "components/Buttons/indes";
import ModalDeleteCluster from "components/Molecules/Cluster/ModalDeleteCluster";
import ModalTambahEditCluster from "components/Molecules/Cluster";
import { actionListClusters } from "../../../../store/cluster/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  EDIT_CLUSTER,
  GET_DETAIL_CLUSTER,
} from "../../../../store/cluster/actionTypes";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

function ClusterDetailPages(props) {
  const { userDataClient } = props;
  const [modalAddCluster, setModalAddCluster] = useState(false);
  const [modalDeleteCluster, setModalDeleteCluster] = useState(false);
  const [reRender, setReRender] = useState(false);
  const componentRef = React.useRef(null);
  const list_cluster = useSelector(
    (state) => state.clusterReducer.list_cluster,
  );
  const dispatch = useDispatch();
  const [action, setAction] = useState("");

  const handleActions = (cell, row) => {
    return (
      <div className="d-flex">
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
            margin: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
            }}
            onClick={() => handleEdit(row)}
          >
            {/* <span style={{color: "#6174DD", fontWeight:"bold"}}>Profile</span> */}
            <i className="fas fa-edit" style={{ color: "#6174DD" }} />
          </button>
        </div>
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
            margin: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
            }}
            onClick={() => handleDelete(row)}
          >
            <i
              className="fas fa-trash-alt"
              style={{ color: "rgb(155, 159, 183)" }}
            />
          </button>
        </div>
      </div>
    );
  };

  const handleEdit = (row) => {
    dispatch({
      type: EDIT_CLUSTER,
      payload: row,
    });
    setModalAddCluster(!modalAddCluster);
    setAction("Edit");
  };

  const handleDelete = (row) => {
    dispatch({
      type: GET_DETAIL_CLUSTER,
      payload: row,
    });
    setModalDeleteCluster(!modalDeleteCluster);
  };

  const handleModalAddCluster = () => {
    setModalAddCluster(!modalAddCluster);
    setAction("Add");
  };

  useEffect(() => {
    if (userDataClient.length > 0) {
      fetchListCluster(userDataClient);
    }
  }, [userDataClient, reRender]);

  const fetchListCluster = useCallback(
    (val) => {
      dispatch(actionListClusters(val[0]?.client_name, true, () => {}));
    },
    [reRender],
  );

  return (
    <>
      <ModalTambahEditCluster
        isOpen={modalAddCluster}
        toggle={() => setModalAddCluster(!modalAddCluster)}
        clientId={props?.data}
        action={action}
        propSetReRender={() => setReRender(!reRender)}
      />
      <ModalDeleteCluster
        isOpen={modalDeleteCluster}
        toggle={() => setModalDeleteCluster(!modalDeleteCluster)}
        setReRender={() => setReRender(!reRender)}
      />
      <Card body>
        <ToolkitProvider
          data={list_cluster}
          keyField={list_cluster}
          columns={[
            {
              dataField: "cluster_name",
              text: "Nama Cluster",
              sort: false,
            },
            {
              dataField: "id",
              text: "Action",
              sort: false,
              formatter: handleActions,
            },
          ]}
          search
        >
          {(props) => (
            <div className=" table-responsive">
              <Container>
                <Col xs={12} sm={5}>
                  <ButtonGroup>
                    {localStorage.getItem("role") != "ADMIN" && (
                      <ButtonAdd
                        name="Cluster"
                        onClick={handleModalAddCluster}
                      />
                    )}
                  </ButtonGroup>
                </Col>
              </Container>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
}

export default ClusterDetailPages;
