/* eslint-disable no-undef */
import { url } from './config';
import instance from 'axios';

const axios = instance.create();

const Delete = (path, params) => {
	const promise = new Promise((resolve, reject) => {
		axios.delete(`${url}/${path}`, { data: params }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Methods': 'GET,POST, DELETE',
        } }).then(res => {
          resolve(res);
        }).catch(err => {
          return reject(err.response);
        });
	});
	return promise;
};

export default Delete;
