import { Card, Container, Media } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import Paginations from "components/Tabels/Paginations";
import { useState } from "react";
import { useRef } from "react";
import API from "service/api/config";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { addDefaultSrc } from "helpers/brokenImg";
import ModalViewItemDaftarKehadiran from "components/Modal/ViewItemDaftarKehadiran";
import ModalPhotos from "components/Modal/ModalPhotos";

function AbsensiSecurity() {
  const componentRef = useRef(null);
  const { id: user_id } = useParams();

  const [startDate, setStartDate] = useState(new Date());
  const [presenceData, setPresenceData] = useState([]);
  const [name, setName] = useState("");

  const [isOpenViewItemKehadiran, setIsOpenViewItemKehadiran] = useState(false);
  const [allKelengkapan, setAllKelengkapan] = useState({});

  const [isOpenPicture, setIsOpenPicture] = useState(false);
  const [valuePicture, setValuePicture] = useState("");

  const [reRender, setReRender] = useState(false);

  const dispatch = useDispatch();

  const fetchUserPresence = () => {
    API.getUserPresence(user_id, convertToYearMonth(startDate)).then((res) => {
      setPresenceData(res?.data?.data);
    });
  };

  const fetchUser = () => {
    API.getUser(user_id).then((res) => {
      setName(res?.data?.data[0].name);
    });
  };

  const convertToYearMonth = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    const formattedDate = `${year}-${month}`;

    return formattedDate;
  };

  const kelengkapan = (message, value) => {
    const handleKelengkapanButton = () => {
      if (message === "Tidak Lengkap" || message === "Lengkap") {
        setAllKelengkapan(value?.kelengkapan);
        setIsOpenViewItemKehadiran(!isOpenViewItemKehadiran);
        dispatch({
          type: NAME_KEHADIRAN,
          payload: value.name,
        });
      }
    };
    return (
      <div
        onClick={handleKelengkapanButton}
        style={{ cursor: "pointer", margin: "10px" }}
      >
        <p
          className={
            message === "Belum Check in"
              ? "text-kelengkapan"
              : "text-kelengkapan-active"
          }
        >
          {message}
        </p>
      </div>
    );
  };

  const picture = (value) => {
    return (
      <Media>
        <div
          className="cursor-pointer"
          onClick={() => {
            value?.image_link !== undefined
              ? setIsOpenPicture(!isOpenPicture)
              : null;
            setValuePicture(value?.image_link);
          }}
        >
          <img
            onError={(e) => addDefaultSrc(e)}
            alt="img"
            className="img-thumbnail"
            src={`${process.env.REACT_APP_BE_URL}/` + value?.image_link}
          />
        </div>
      </Media>
    );
  };

  useEffect(() => {
    fetchUserPresence();
    fetchUser();
    console.log(presenceData);
  }, []);
  useEffect(() => {
    fetchUserPresence();
  }, [startDate]);
  return (
    <>
      <div className="wrap-text-label-head">
        <span className="txt-label-pages">Presensi Security</span>
      </div>
      <Container>
        <ModalPhotos
          toggle={() => setIsOpenPicture(!isOpenPicture)}
          isOpen={isOpenPicture}
          isDetailPhotos={valuePicture}
        />
        <ModalViewItemDaftarKehadiran
          toggle={() => setIsOpenViewItemKehadiran(!isOpenViewItemKehadiran)}
          isOpen={isOpenViewItemKehadiran}
          setReRender={() => setReRender(!reRender)}
          allKelengkapan={allKelengkapan}
        />
        <Card body>
          <ToolkitProvider
            data={presenceData}
            keyField="absen_tab"
            columns={[
              {
                dataField: "user.client.client_name",
                text: "CLIENT",
                sort: true,
              },
              {
                dataField: "user.cluster.cluster_name",
                text: "CLUSTER",
                sort: true,
              },
              {
                dataField: "check_in",
                text: "CHECK-IN",
                sort: true,
                // formatter: (absensis) => checkCheckin(absensis),
                headerAttrs: { width: 150 },
                attrs: { width: 150, class: "EditRow" },
              },
              {
                dataField: "check_out",
                text: "CHECK-OUT",
                sort: true,
                // formatter: (absensis) => checkCheckOut(absensis),
                headerAttrs: { width: 150 },
                attrs: { width: 150, class: "EditRow" },
              },
              {
                dataField: "kelengkapanMessage",
                text: "KELENGKAPAN",
                sort: false,
                formatter: (value, val) => kelengkapan(value, val),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
              {
                dataField: "kelengkapan",
                text: "FOTO",
                sort: false,
                formatter: (value) => picture(value),
                headerAttrs: { width: 50 },
                attrs: { width: 50, class: "EditRow" },
              },
            ]}
            search
          >
            {(props) => (
              <div className="table-responsive">
                <Container fluid>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex justify-content-between">
                      <div className="wrap-datepick">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="yyyy MMM"
                          showMonthYearPicker
                          showFullMonthYearPicker
                          className="datepicks"
                        />
                      </div>
                      <div className="mx-6">{name}</div>
                    </div>
                  </div>
                </Container>
                <BootstrapTable
                  ref={componentRef}
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={Paginations}
                  bordered={false}
                  id="react-bs-table"
                  noDataIndication={() => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No Data Available{" "}
                    </div>
                  )}
                />
              </div>
            )}
          </ToolkitProvider>
        </Card>
      </Container>
    </>
  );
}

export default AbsensiSecurity;
