/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback } from 'react';
import { Input, Row, Col, Label, CardFooter } from 'reactstrap';
import { useFormik } from 'formik';
import API from 'service/api/config';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { validateTambahPengguna } from '../../../validates';

export default function FormPenghuni(props) {
  const { typeId } = props;
  const [listClient, setListClient] = useState([]);
  const [listCluster, setListCluster] = useState([]);
  const [reRender, setReRender] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getClusterPengguna();
    getClient();
  }, [reRender]);

  const optionsTypeClient = listClient.map(val => {
    return {
      value: val.client_id,
      label: val.client_name,
    };
  });

  const handleSelectCluster = val => {
    formik.setFieldValue('tipeClient', val.value);
    getClusterPengguna(val.label);
  };

  const getClusterPengguna = val => {
    API.getClientClusterName(val).then(res => {
      setListCluster(res?.data?.data);
    });
  };

  const getClient = useCallback(() => {
    API.getClient().then(res => {
      setListClient(res.data.data);
    });
  }, []);

  const optionsTypeCluster = listCluster.map(val => {
    return {
      value: val.cluster_id,
      label: val.cluster_name,
    };
  });

  const formik = useFormik({
    initialValues: {
      tipePengguna: '',
      tipeClient: '',
      tipeCluster: '',
      fullname: '',
      address: '',
      telephone: '',
      email: '',
      password: '',
    },
    validationSchema: validateTambahPengguna.penghuni,
    onSubmit: async values => {
      try {
        const formData = {
          'role_id': typeId,
          'name': values.fullname,
          'email': values.email,
          'password': values.password,
          'phone': values.telephone,
          'alamat': values.address,
          'cluster_id': values.tipeCluster,
          'client_id': values.tipeClient,
        };

        const response = await API.postUserRegister(formData);
        if (response.status === 201) {
          toast.success('Upload Successful!');
          setReRender(true);
          setTimeout(() => {
            history.go(0);
          }, 8000);
        }
      } catch (e) {
        if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  return (
    <form onSubmit={ formik.handleSubmit }>
      <div className='wrap-form'>
        <Label>Nama Lengkap</Label>
        <Input
          placeholder='Your Name'
          type='text'
          name='fullname'
          id='fullname'
          onChange={ formik.handleChange }
        />
        { formik.touched.fullname && formik.errors.fullname ? (
          <div style={ { color: 'red' } }>{ formik.errors.fullname }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>Alamat</Label>
        <Input
          placeholder=''
          type='text'
          name='address'
          onChange={ formik.handleChange }
        />
        { formik.touched.address && formik.errors.address ? (
          <div style={ { color: 'red' } }>{ formik.errors.address }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>No. Telepon</Label>
        <Input
          placeholder=''
          type='number'
          name='telephone'
          onChange={ formik.handleChange }
        />
        { formik.touched.telephone && formik.errors.telephone ? (
          <div style={ { color: 'red' } }>{ formik.errors.telephone }</div>
        ) : null }
      </div>
      <hr />
      <div className='wrap-form'>
        <Label>Email</Label>
        <Input
          placeholder=''
          type='text'
          name='email'
          onChange={ formik.handleChange }
        />
        { formik.touched.email && formik.errors.email ? (
          <div style={ { color: 'red' } }>{ formik.errors.email }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>Password</Label>
        <Input
          placeholder=''
          type='password'
          name='password'
          onChange={ formik.handleChange }
        />
        { formik.touched.password && formik.errors.password ? (
          <div style={ { color: 'red' } }>{ formik.errors.password }</div>
        ) : null }
      </div>
      <hr />
      <label>Penempatan</label>
      <Row>
        <Col>
          <label>Client</label>
          <Select
            options={ optionsTypeClient }
            name='tipeClient'
            onChange={ handleSelectCluster }
          />
          { formik.touched.tipeClient && formik.errors.tipeClient ? (
            <div style={ { color: 'red' } }>{ formik.errors.tipeClient }</div>
          ) : null }
        </Col>
        <Col>
          <label>Cluster</label>
          <Select
            options={ optionsTypeCluster }
            name='tipeCluster'
            onChange={ val => {
              formik.setFieldValue('tipeCluster', val.value);
            } }
          />
          { formik.touched.tipeCluster && formik.errors.tipeCluster ? (
            <div style={ { color: 'red' } }>{ formik.errors.tipeCluster }</div>
          ) : null }
        </Col>
      </Row>
      <CardFooter>
        <button type='submit' className='btn-tambah-pengguna'>
          <span>Tambah Pengguna</span>
        </button>
      </CardFooter>
    </form>
  );
}
