/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Input, Label, CardFooter, Row, Col } from 'reactstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import API from 'service/api/config';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { validateTambahPengguna } from 'validates';
import IconFieldCheck from '../../../assets/img/icons/Field_Checkbox.svg';

export default function FormClient(props) {
  const { typeId } = props;
  const [listClient, setListClient] = useState([]);
  const [uploadPhoto, setUploadPhoto] = useState('');
  const [reRender, setReRender] = useState(true);
  const history = useHistory();
  const photoRef = useRef();

  useEffect(() => {
    getClient();
  }, [reRender]);

  const onChangePhoto = e => {
    setUploadPhoto(e.target.files[0]);
  };

  const resetPhoto = () => {
    photoRef.current.value = '';
  };


  const optionsTypeClient = listClient.map(val => {
    return {
      value: val.client_id,
      label: val.client_name,
    };
  });

  const handleSelectCluster = val => {
    formik.setFieldValue('tipeClient', val.value);
  };

  const getClient = useCallback(() => {
    API.getClient().then(res => {
      setListClient(res.data.data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      tipeClient: '',
      fullname: '',
      telephone: '',
      email: '',
      password: '',
      upload: '',
    },
    validationSchema: validateTambahPengguna.client,
    onSubmit: async values => {
      try {
        const formData = new FormData();
        formData.append('role_id', typeId);
        formData.append('name', values.fullname);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('phone', values.telephone);
        formData.append('client_id', values.tipeClient);
        formData.append('image_link', uploadPhoto);

        const response = await API.postUser(formData);

        if (response.status === 201) {
          toast.success('Upload Successful!');
          setReRender(true);
          setTimeout(() => {
            history.go(0);
          }, 8000);
        }
      } catch (e) {
        if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  return (
    <form onSubmit={ formik.handleSubmit }>
      <Row>
        <Col>
          <label>Client</label>
          <Select
            options={ optionsTypeClient }
            name='tipeClient'
            onChange={ handleSelectCluster }
          />
          { formik.touched.tipeClient && formik.errors.tipeClient ? (
            <div style={ { color: 'red' } }>{ formik.errors.tipeClient }</div>
          ) : null }
        </Col>
      </Row>
      <div className='wrap-form'>
        <Label>Nama Lengkap</Label>
        <Input
          placeholder='jhone felix'
          type='text'
          name='fullname'
          id='fullname'
          onChange={ formik.handleChange }
        />
        { formik.touched.fullname && formik.errors.fullname ? (
          <div style={ { color: 'red' } }>{ formik.errors.fullname }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>No. Telepon</Label>
        <Input
          placeholder=''
          type='number'
          name='telephone'
          onChange={ formik.handleChange }
        />
        { formik.touched.telephone && formik.errors.telephone ? (
          <div style={ { color: 'red' } }>{ formik.errors.telephone }</div>
        ) : null }
      </div>
      <hr />
      <div className='wrap-form'>
        <Label>Email</Label>
        <Input
          placeholder=''
          type='email'
          name='email'
          onChange={ formik.handleChange }
        />
        { formik.touched.email && formik.errors.email ? (
          <div style={ { color: 'red' } }>{ formik.errors.email }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>Password</Label>
        <Input
          placeholder=''
          type='password'
          name='password'
          onChange={ formik.handleChange }
        />
        { formik.touched.password && formik.errors.password ? (
          <div style={ { color: 'red' } }>{ formik.errors.password }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label className='tit-upload'>Photo Profile</Label>
        <div className='d-flex'>
          <div>
            <input
                name='upload'
                className='upload-doc'
                type='file'
                accept='image/*'
                onChange={ e => {
                  formik.handleChange(e);
                  onChangePhoto(e);
                } }
                ref={ photoRef }
            />
          </div>
          <div className='px-3'>
            <img
                style={ { cursor: 'pointer' } }
                src={  IconFieldCheck }
                alt='icon check'
                onClick={ () => resetPhoto() }
            />
          </div>
        </div>
        { formik.touched.upload && formik.errors.upload ? (
          <div style={ { color: 'red' } }>{ formik.errors.upload }</div>
        ) : null }
      </div>
      <CardFooter>
        <button type='submit' className='btn-tambah-pengguna'>
          <span>Tambah Pengguna</span>
        </button>
      </CardFooter>
    </form>
  );
}
