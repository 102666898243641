import React from 'react';
import { Button } from 'reactstrap';
import PulseLoader from 'react-spinners/PulseLoader';
import Download from 'assets/img/bi_cloud-download.svg';

const ButtonDefault = props => {
  const { name, onClick, color, background, type } = props;
  return (
    <button
      className='button-custom'
      onClick={ onClick }
      style={ { color: color, backgroundColor: background } }
      type={ type }
    >
      { name }
    </button>
  );
};

const ButtonSpiner = () => {
  return (
    <Button
      className={ 'btnSubmit' }
      type='submit'
    >
      <PulseLoader color='#fff' size={ 15 } />
    </Button>
  );
};

const ButtonDark = props => {
  const { name, onClick } = props;
  return (
    <button
      className='button-dark'
      onClick={ onClick }
    >
      { name }
    </button>
  );
};

const ButtonAdd = props => {
  const { name, onClick, disabled } = props;
  return (
    <Button
      disabled={ disabled }
      size='sm'
      onClick={ onClick }
      style={ { backgroundColor: '#6174DD', color: '#fff' } }
      color='primary'
      >
        <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5.99951 6H1.99951M5.99951 10V6V10ZM5.99951 6V2V6ZM5.99951 6H9.99951H5.99951Z' stroke='white' strokeWidth='1.33333' strokeLinecap='round'/>
        </svg>
        <span>
        { name }
        </span>
    </Button>
  );
};

const ButtonXLS = props => {
  const { name, onClick, disabled } = props;
  return (
    <button
      className='button-xls d-flex justify-content-center'
      onClick={ onClick }
      disabled={ disabled }
    >
      <div className='pr-2'>
        <img src={ Download } alt='icons download' />
      </div>
      <span style={ { color: '#19B6B3' } }>
      { name }
      </span>
    </button>
  );
};



export { ButtonSpiner, ButtonDark, ButtonAdd, ButtonDefault, ButtonXLS };