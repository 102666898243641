/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-undef */
/* eslint-disable space-before-blocks */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
/* eslint-disable react/self-closing-comp */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconDownload from '../../assets/img/DownloadXLS.svg';
import IconsXLS from '../../assets/img/ph_file-xls-fill.svg';
import IconsPDF from '../../assets/img/PDF.svg';
import IconsBtnPDF from '../../assets/img/icon-btn-pdf.svg';
import IconsClose from '../../assets/img/icons/bi_x-lg.svg';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { useFormik } from 'formik';
import { Modal, ModalBody } from 'reactstrap';
import MySelect from 'components/Select';
import { addDays } from '@fullcalendar/core';
import MyModal from './MyModal';
import Inputs from 'components/Forms';
import { useDispatch } from 'react-redux';
import { actionInputKeterangan } from 'store/client/actions';
import { actionUpdateKeteranganAbsensi } from 'store/client/actions';

const ModalKeterangan = (props) => {
    const { isOpen, toggle, setReRender  } = props;
    const dispatch = useDispatch();
    const valueKeterangan = useSelector(state => state.clientReducer);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            keterangan: valueKeterangan.keterangan,
            isDisabled: valueKeterangan.keterangan ? true : false
        },
        onSubmit: (values) => {
            let body = {
                absensi_id: valueKeterangan.absen_id,
                keterangan: values.keterangan
            };
            dispatch(actionUpdateKeteranganAbsensi(body, () => {
                setReRender();
                toggle();
            }));
        }
    });

  return (
      <MyModal
        modal={ isOpen }
        toggle={ toggle }
        title='Keterangan'
        size='md'
      >
          <>
            <form onSubmit={ formik.handleSubmit }>
                <Inputs
                    label='Keterangan'
                    formik={ formik }
                    placeholder='keterangan'
                    type='text'
                    name='keterangan'
                    value={ formik.values.keterangan }
                    disabled={ formik.values.isDisabled }
                />
                <div className='mt-2'>
                    <button
                        style={ { backgroundColor: '#6174DD', color: '#fff' } }
                        type='submit'
                        className='btn btn-rounded'
                    >
                        Simpan
                    </button>
                </div>
            </form>
          </>
      </MyModal>
  );
};

export default ModalKeterangan;