/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import { DELETE_SECURITY, SAVE_DATA_PAYROLL } from './actionTypes';

export const actionPayroll = (clientID, dates, callBack) => async dispatch => {
    try {
      const res = await API.getPayroll(clientID, dates);
      if (res.status < 299) {
        dispatch({
          type: SAVE_DATA_PAYROLL,
          payload: res.data?.data
        });
        callBack(res.data?.data);
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};


export const actionDeleteSecurity = (body, callBack) => async dispatch => {
  try {
    const res = await API.deleteUser(body);
    if (res.status < 299) {
      dispatch({
        type: DELETE_SECURITY
      });
      toast.success('Sukses Hapus Security');
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};
export const actionUpdateEvaluasi = (body, callBack) => async() => {
    try {
      const res = await API.putEvaluasi(body);
      if (res.status < 299) {
        toast.success('Update evaluasi berhasil');
        callBack(res.data?.data);
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};

export const actionDeleteEvaluasi = (id, callBack) => async() => {
    try {
      const res = await API.deleteEvaluasi(id);
      if (res.status < 299) {
        toast.success('Delete evaluasi berhasil');
        callBack(res.data?.data);
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};


