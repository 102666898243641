import {
    ADD_MARKER,
    REMOVE_MARKER,
    GET_DETAIL,
    UPDATE_STATUS,
    DELETE_PATROL_POINT,
    SAVE_PATROL_POINT, REGENERATE_QR_CODE, EDIT_PATROL_POINT, CANCEL_EDIT_PATROL_POINT, UPDATE_PATROL_POINT,
} from './actionTypes';

const initialState = {
  markers: [],
    data: [],
    filteredData: [],
    newMarker: [],
    detail: {}
};

const mapsReducer = (
  state = initialState,
  action: { type: any, payload: any }
) => {
  switch (action.type) {
    case GET_DETAIL:
      const markers = action.payload.map(val => [
        val.longitude,
        val.latitude,
      ]);

          state = {
              ...state,
              data: action.payload,
              markers: markers
          };
          break;
      case UPDATE_STATUS:
          state = {
              ...state,
          };
          break;
      case DELETE_PATROL_POINT:
          state = {
              ...state,
          };
          break;
    case ADD_MARKER:
      state = {
        ...state,
        newMarker: action.payload.marker
      };
      break;
      case REMOVE_MARKER:
          state = {
              ...state,
              newMarker: []
          };
          break;
      case SAVE_PATROL_POINT:
          state = {
              ...state,
              newMarker: []
          };
          break;
      case REGENERATE_QR_CODE:
          state = {
              ...state,
          };
          break;
      case EDIT_PATROL_POINT:
          const filterArr = state.data.filter(val => val.titik_id !== action.payload.titik_id);
          const editMarkers = filterArr.map(val => [
              val.longitude,
              val.latitude,
          ]);

          state = {
              ...state,
              filteredData: filterArr,
              detail: action.payload,
              markers: editMarkers,
              newMarker: [action.payload.longitude, action.payload.latitude]
          };
          break;
      case CANCEL_EDIT_PATROL_POINT:
          const dataMarker = state.data.map(val => [
              val.longitude,
              val.latitude,
          ]);

          state = {
              ...state,
              markers: dataMarker,
              filteredData: [],
              newMarker: []
          };
          break;

      case UPDATE_PATROL_POINT:

          state = {
              ...state,
              markers: state.data.map(val => [
                  val.longitude,
                  val.latitude,
              ]),
              filteredData: [],
              newMarker: []
          };
          break;
  }

  return state;
};

export default mapsReducer;
