import * as Yup from "yup";

const client = Yup.object({
  tipeClient: Yup.string().required("Client harus diisi"),
  fullname: Yup.string().required("Nama Lengkap harus diisi"),
  telephone: Yup.string().required("No Telepon harus diisi"),
  email: Yup.string().required("Email harus diisi"),
  password: Yup.string().required("Password harus diisi"),
  // upload: Yup.string().required('Password harus diisi'),
});

const chief = Yup.object({
  fullname: Yup.string().required("Nama Lengkap harus diisi"),
  gender: Yup.string().required("Jenis Kelamin harus diisi"),
  bornplace: Yup.string().required("Tempat Tanggal Lahir harus diisi"),
  day: Yup.string().required("Hari harus diisi"),
  month: Yup.string().required("Bulan harus diisi"),
  years: Yup.string().required("Tahun harus diisi"),
  numberId: Yup.string().required("NIK harus diisi"),
  religion: Yup.string().required("Agama harus diisi"),
  address: Yup.string().required("Alamat harus diisi"),
  kota: Yup.string().required("Kota harus diisi"),
  province: Yup.string().required("Provinsi harus diisi"),
  post_code: Yup.string().required("Kode Pos harus diisi"),
  telephone: Yup.string().required("No Telepon harus diisi"),
  rekening: Yup.string().required("Nomor Rekening harus diisi"),
  email: Yup.string().email("Email tidak sesuai").required("Email harus diisi"),
  password: Yup.string().required("Password harus diisi"),
  tipeClient: Yup.string().required("Client harus diisi"),
  tipeCluster: Yup.mixed().when("multiRole", {
    is: false,
    then: Yup.mixed().required("Cluster harus diisi"),
  }),
  hari_bergabung: Yup.string().required("Hari harus diisi"),
  bulan_bergabung: Yup.string().required("Bulan harus diisi"),
  tahun_bergabung: Yup.string().required("Tahun harus diisi"),
  no_kta: Yup.string()
    .test("Silahkan masukan angka", (value) => {
      let a = String(value).replaceAll(".", "");
      return !isNaN(Number(a));
    })
    .required("No KTA harus diisi"),
  // no_bpjs: Yup.string().required("No BPJS harus diisi"),
  // no_bpjs_kesehatan: Yup.string().required("No BPJS Kesehatan harus diisi"),
  // no_npwp: Yup.string().required("NPWP harus diisi"),
  // pendidikan_terakhir: Yup.string().required("Pendidikan Terakhir harus diisi"),
});

const penghuni = Yup.object({
  tipeClient: Yup.string().required("Client harus diisi"),
  tipeCluster: Yup.string().required("Cluster harus diisi"),
  fullname: Yup.string().required("Nama Lengkap harus diisi"),
  address: Yup.string().required("Alamat harus diisi"),
  telephone: Yup.string().required("No Telepon harus diisi"),
  email: Yup.string().email("Email tidak sesuai").required("Email harus diisi"),
  password: Yup.string().required("Password harus diisi"),
  // upload: Yup.string().required('Password harus diisi'),
});

const admin = Yup.object({
  fullname: Yup.string().required("Nama Lengkap harus diisi"),
  gender: Yup.string().required("Jenis Kelamin harus diisi"),
  bornplace: Yup.string().required("Tempat Tanggal Lahir harus diisi"),
  day: Yup.string().required("Hari harus diisi"),
  month: Yup.string().required("Bulan harus diisi"),
  years: Yup.string().required("Tahun harus diisi"),
  numberId: Yup.string().required("NIK harus diisi"),
  religion: Yup.string().required("Agama harus diisi"),
  address: Yup.string().required("Alamat harus diisi"),
  kota: Yup.string().required("Kota harus diisi"),
  province: Yup.string().required("Provinsi harus diisi"),
  post_code: Yup.string().required("Kode Pos harus diisi"),
  telephone: Yup.string().required("No Telepon harus diisi"),
  rekening: Yup.string().required("Nomor Rekening harus diisi"),
  email: Yup.string().email("Email tidak sesuai").required("Email harus diisi"),
  password: Yup.string().required("Password harus diisi"),
  tipeClient: Yup.string().required("Client harus diisi"),
});

const security = Yup.object({
  tipeClient: Yup.string().required("Client harus diisi"),
  tipeCluster: Yup.string().required("Cluster harus diisi"),
  fullname: Yup.string().required("Nama Lengkap harus diisi"),
  gender: Yup.string().required("Jenis kelamin harus diisi"),
  bornplace: Yup.string().required("Tempat tanggal lahir harus diisi"),
  nik: Yup.string().required("NIK harus diisi"),
  religion: Yup.string().required("Agama harus diisi"),
  address: Yup.string().required("Alamat harus diisi"),
  telephone: Yup.string().required("No Telepon harus diisi"),
  rekening: Yup.string().required("No Rekening harus diisi"),
  kota: Yup.string().required("Kota harus diisi"),
  province: Yup.string().required("Provinsi harus diisi"),
  post_code: Yup.string().required("Kode pos harus diisi"),
  email: Yup.string().email("Email tidak sesuai").required("Email harus diisi"),
  password: Yup.string().required("Password harus diisi"),
  day: Yup.string().required("Tanggal harus diisi"),
  month: Yup.string().required("Bulan harus diisi"),
  years: Yup.string().required("Tahun harus diisi"),
  // upload: Yup.string().required('Password harus diisi'),
});

export { client, chief, penghuni, security, admin };
