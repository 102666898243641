import {
  DELETE_PIC, GET_DETAIL_ABSENSI,
  GET_DETAIL_PIC, NAME_INPUT_KETERANGAN, NAME_KEHADIRAN, UPDATE_KETERANGAN_ABSENSI,
} from './actionTypes';

const initialState = {
  loading: false,
  detail: {},
  name: '',
  keterangan: '',
  absen_id: '',
};

const clientReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case GET_DETAIL_PIC:
      state = {
        ...state,
        detail: action.payload
      };
      break;
    case DELETE_PIC:
      state = {
        ...state,
        detail: {}
      };
      break;
    case GET_DETAIL_ABSENSI:
      state = {
        ...state,
        detail: action.payload
      };
      break;
    case UPDATE_KETERANGAN_ABSENSI:
      state = {
        ...state,
        detail: {}
      };
      break;
    case NAME_KEHADIRAN:
      state = {
        ...state,
        name: action.payload
      };
      break;
    case NAME_INPUT_KETERANGAN:
      state = {
        ...state,
        keterangan: action.payload.keterangan,
        absen_id: action.payload.absen_id
      };
      break;
  }

  return state;
};


export default clientReducer;
