/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from "react";
import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import logoLaporan from "assets/img/brand/TBWLogo.png";
import SecurityDetailPages from "./security-detail";
import Absensi from "./absensi";
import Penghuni from "./penghuni";
import PicPages from "./pic";
import { useParams } from "react-router";
import API from "service/api/config";
import ClusterDetailPages from "./cluster";
import { addDefaultSrc } from "helpers/brokenImg";
import { useFormik } from "formik";

function DetailClient() {
  const params = useParams();
  const [activeTab, setactiveTab] = React.useState("1");
  const [userDataClient, setUserDataClient] = React.useState([]);
  const role = localStorage.getItem("role");

  const formik = useFormik({
    initialValues: {
      name_cluster: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (role === "SUPERADMIN") {
      API.getClientParam(params?.client_id).then((res) => {
        setUserDataClient(res?.data?.data);
      });
    } else {
      API.getClientParam(params?.id).then((res) => {
        setUserDataClient(res?.data?.data);
      });
    }
  }, []);

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  return (
    <>
      <div className="wrap-text-label-head">
        <div className="wrap-head-laporan">
          <Row>
            <Col md="4">
              <img
                src={
                  `${process.env.REACT_APP_BE_URL}/${userDataClient[0]?.image_link}` ??
                  logoLaporan
                }
                onError={(e) => addDefaultSrc(e)}
                alt="img_laporan"
                className="img-thumbnail"
              />
            </Col>
            <Col>
              <div className="wrap-label-laporan">
                <span className="txt-head-label-laporan">
                  {userDataClient[0]?.client_name}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div />
      <Container>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "1" ? "actives-tab" : null}`}
              >
                {" "}
                Daftar Kehadiran
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "2" ? "actives-tab" : null}`}
              >
                Security
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "3",
              })}
              onClick={() => {
                toggle("3");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "3" ? "actives-tab" : null}`}
              >
                Cluster
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "4",
              })}
              onClick={() => {
                toggle("4");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "4" ? "actives-tab" : null}`}
              >
                Penghuni
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "5",
              })}
              onClick={() => {
                toggle("5");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "5" ? "actives-tab" : null}`}
              >
                PIC
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Absensi
                  data={params?.client_id ?? params?.id}
                  formik={formik}
                  userDataClient={userDataClient[0]}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <SecurityDetailPages data={params?.client_id ?? params?.id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <ClusterDetailPages
                  data={params?.client_id ?? params?.id}
                  userDataClient={userDataClient}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <Penghuni data={params?.client_id ?? params?.id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                <PicPages data={params?.client_id ?? params?.id} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default DetailClient;
