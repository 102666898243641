/* eslint-disable react/jsx-no-bind */
import React from 'react';
import './forgot.css';
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BaktiLogo from '../../../../assets/img/brand/Logo.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { actionForgotePassword } from 'store/auth/actions';

const ForgotPages = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.authReducer.loadingForgote);

  const formik = useFormik({
    initialValues: {
      email: '',
      mobile: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email format')
        .required('The email address is required'),
    }),
    onSubmit: async values => {
      const body = {
        email: values.email,
      };
      dispatch(
        actionForgotePassword(body)
        // actionSignin(body, role => {
        //   if (role === 'SUPERADMIN') {
        //     history.push('/superadmin/dashboard');
        //   } else {
        //     history.push(`/${role.toLowerCase()}/dashboard`);
        //   }
        // })
      );
    },
  });

  return (
    <>
      <div>
        <Row className='containerlogin'>
          <Col className={ 'txtLoginpages' } />
          <Col className={ 'ctnLoginpages' }>
            <div className={ 'wrapps' }>
              <div className='d-flex justify-content-center sign'>
                <div className={ 'wrpPages' }>
                  <div className={ 'formUs' }>
                    <div style={ { marginTop: '20px' } }>
                      <center>
                        <img src={ BaktiLogo } alt='logomonev' />
                        <span className='tit-login-card'>
                          Forgot Your Password
                        </span>
                      </center>
                    </div>
                    <form onSubmit={ formik.handleSubmit }>
                      <InputGroup className='wrap-pass'>
                        <Input
                          name='email'
                          className='registers'
                          placeholder='email'
                          onChange={ formik.handleChange }
                        />
                      </InputGroup>
                      <Col style={ { textAlign: 'center' } }>
                        { formik.touched.email && formik.errors.email ? (
                          <div style={ { color: 'red' } }>
                            { formik.errors.email }
                          </div>
                        ) : null }
                      </Col>
                      <div className='d-flex'>
                        <Button
                          className={ 'btnSubmit' }
                          color=''
                          type='submit'
                          disabled={ loading }
                        >
                          { loading ? (
                            <Spinner type='grow' color='light' size='sm' />
                          ) : (
                            'Reset Password'
                          ) }
                        </Button>
                      </div>
                    </form>
                  </div>

                  <div className='divid'>
                    Kembali Ke Halaman{ ' ' }
                    <Link className={ 'signUpStyle' } to='/login'>
                      { ' ' }
                      Login.{ ' ' }
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ForgotPages;
