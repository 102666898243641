import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';

import { History as history } from 'helpers';
import authReducer from './auth/reducer';
import mapsReducer from './maps/reducer';
import patroliReducer from './patroli/reducer';
import clusterReducer from './cluster/reducer';
import dashboardReducer from './dashboard/reducer';
import laporanReducer from './laporan/reducer';
import securityReducer from './security/reducer';
import clientReducer from './client/reducer';

const routeMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
  authReducer: authReducer,
  mapsReducer: mapsReducer,
  patroliReducer: patroliReducer,
  clusterReducer: clusterReducer,
  dashboardReducer: dashboardReducer,
  laporanReducer: laporanReducer,
  securityReducer: securityReducer,
  clientReducer: clientReducer,
	router: connectRouter(history),
});

const store = createStore(
	rootReducer,
  composeWithDevTools(applyMiddleware(thunk, routeMiddleware)),
	);

export { history, store };
