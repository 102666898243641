import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

function CardsHeader() {
  const data = useSelector(state => state.dashboardReducer.result);
  return (
    <>
      <div className='header pb-6'>
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-4' />
            <Row>
              <Col md='6' xl='3'>
                <div className='first-card card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-card mb-0'
                          style={ { width: '50%' } }
                        >
                          Laporan Hari Ini
                        </CardTitle>
                      </div>
                      <Col className='col-auto'>
                        <div
                          className='icon text-white shadow'
                          style={ { fontSize: '20px' } }
                        >
                          <i className='ni ni-bell-55' />
                        </div>
                      </Col>
                    </Row>
                    <p className='mt-3 mb-0 text-sm'>
                      <span className='text-success mr-2'>
                        <span className='h2 text-card-value mb-0'>{ data.laporan }</span>
                      </span>{ ' ' }
                    </p>
                  </CardBody>
                </div>
              </Col>
              <Col md='6' xl='3'>
                <div className='second-card card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-card mb-0'
                          style={ { width: '50%' } }
                        >
                          Security Terdaftar
                        </CardTitle>
                      </div>
                      <Col className='col-auto'>
                        <div
                          className='icon text-white shadow'
                          style={ { fontSize: '20px' } }
                        >
                          <i className='ni ni-badge' />
                        </div>
                      </Col>
                    </Row>
                    <p className='mt-3 mb-0 text-sm'>
                      <span className='text-success mr-2'>
                        <span className='h2 text-card-value mb-0'>{ data.security }</span>
                      </span>{ ' ' }
                    </p>
                  </CardBody>
                </div>
              </Col>
              <Col md='6' xl='3'>
                <div className='third-card card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-card mb-0'
                          style={ { width: '50%' } }
                        >
                          Client Terdaftar
                        </CardTitle>
                      </div>
                      <Col className='col-auto'>
                        <div
                          className='icon text-white shadow'
                          style={ { fontSize: '20px' } }
                        >
                          <i className='ni ni-briefcase-24' />
                        </div>
                      </Col>
                    </Row>
                    <p className='mt-3 mb-0 text-sm'>
                      <span className='text-success mr-2'>
                        <span className='h2 text-card-value mb-0'>{ data.client }</span>
                      </span>
                    </p>
                  </CardBody>
                </div>
              </Col>
              <Col md='6' xl='3'>
                <div className='fourth-card card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-card mb-0'
                          style={ { width: '50%' } }
                        >
                          Penghuni terdaftar
                        </CardTitle>
                      </div>
                      <Col className='col-auto'>
                        <div
                          className='icon text-white shadow'
                          style={ { fontSize: '20px' } }
                        >
                          <i className='ni ni-single-02' />
                        </div>
                      </Col>
                    </Row>
                    <p className='mt-3 mb-0 text-sm'>
                      <span className='text-success mr-2'>
                        <span className='h2 text-card-value mb-0'>{ data.penghuni }</span>
                      </span>
                    </p>
                  </CardBody>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
