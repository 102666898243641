import moment from 'moment';

const differenceTime = date => {
  var dateNow = moment(Date.now());
  const dates = moment(date ? date : dateNow);
  const diff = dateNow.diff(dates, 'seconds');

  if (diff <= 60) {
    return 'a moment ago';
  } else if (diff < 3600) {
    return `${(diff / 60).toFixed()} minutes ago`;
  } else if (diff <= 3600 * 24) {
    return `${(diff / 3600).toFixed()} hours ago`;
  } else if (diff <= 86400 * 30) {
    return `${(diff / 86400).toFixed()} days ago`;
  } else if (diff <= 2592000 * 12) {
    return `${(diff / 2592000).toFixed()} months ago`;
  } else {
    return 'long time ago';
  }
};

export default differenceTime;