/* eslint-disable react/jsx-no-bind */
import React from "react";
import ModalBuatLaporan from "components/Modal/BuatLaporan";
import "../../../../assets/css/main.css";
import "../../../../assets/css/component.css";
import "./circleLoader.css";

import { Container, Row, Col } from "reactstrap";

import TableArrow from "components/Molecules/Laporan/TableArrow";
import moment from "moment";

function Laporan(props) {
  const { data, isDisabled, endOfLaporan, isLoading } = props;
  const client_id = props.clientid;
  const [modalBuatLaporan, setModalBuatLaporan] = React.useState(false);

  const toggleModalBuatLaporan = () => {
    setModalBuatLaporan(!modalBuatLaporan);
  };

  return (
    <>
      <ModalBuatLaporan
        toggle={toggleModalBuatLaporan}
        modal={modalBuatLaporan}
        data={client_id}
      />
      <Container className="mt--2 " fluid>
        <div className="rows temuan-patroli-content">
          <div className="col">
            <div className="pb-6">
              <Row>
                <Col>
                  <button
                    style={{
                      backgroundColor: `${isDisabled ? "#d4e3fa" : ""}`,
                    }}
                    className="btn-head-table"
                    onClick={() => toggleModalBuatLaporan()}
                    disabled={isDisabled}
                  >
                    + Laporan
                  </button>
                </Col>
              </Row>
              {data?.map((val, idx) => {
                const date = moment(val.created_date).format("LLLL");
                return (
                  <TableArrow
                    key={idx}
                    title={val?.nama_pelapor?.name}
                    subTitle={val?.judul_laporan}
                    infoDate={date}
                    img={val?.photos}
                    content={val?.laporan}
                  />
                );
              })}
              {isLoading ? (
                <div
                  style={{
                    // backgroundColor: "blue",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  <div className="circle-loader"></div>
                </div>
              ) : (
                ""
              )}
              {endOfLaporan ? (
                <div
                  style={{
                    // backgroundColor: "blue",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  Sudah Tidak Ada Laporan{" "}
                </div>
              ) : (
                ""
              )}
              <hr />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Laporan;
