import { useFormik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import API from 'service/api/config/index';

function ModalTambahCluster(props) {
  const data = props?.cluster?.data;
  
  const formik = useFormik({
    initialValues: {
      clusterName: '',
    },
    onSubmit: async values => {
      try {
        const response = await API.postClientCluster({ cluster_name: values.clusterName, client_id: data.client_id });
        if (response.status === 201) {
          toast.success(response.data.message);
          props.setRerender(false);
          props.close();
          props.toggle();
        }
      } catch (err) {
        toast.error(err.data.message);
      }
    },
  });

  return (
    <div>
      <Modal
        size='md'
        isOpen={ props.modal }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle }>
          <div
            className='d-flex justify-content-left'
            style={ { width: '160px' } }
          >
            <span style={ { fontSize: '18px', marginLeft: '2%' } }>
              Tambah Cluster
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={ formik.handleSubmit }>
            <div className='wrap-form'>
              <Label>Nama Cluster</Label>
              <Input
                placeholder='Nama Cluster'
                type='text'
                name='clusterName'
                id='clusterName'
                onChange={ formik.handleChange }
              />
            </div>
            <div className='wrap-btn-modal-footer'>
              <button type='submit' className='btn btn-modal btn-rounded'>
                Tambahkan
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalTambahCluster;
