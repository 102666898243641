/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import '../../assets/css/main.css';
import { actionRegenerateQR } from '../../store/maps/actions';

function ModalRegenerateQR(props) {
  const dispatch = useDispatch();

  const regenerateQR = () => {
    const formData = {
      code: props.data.code,
    };

    dispatch(
      actionRegenerateQR(formData, () => {
        props.setReRender();
        props.toogle();
        props.close();
      })
    );
  };
  return (
    <div>
      <Modal
        size='md'
        isOpen={ props.modal }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle }>
          <div
            className='d-flex justify-content-left'
            style={ { width: '120px' } }
          >
            <span style={ { fontSize: '18px', marginLeft: '2%' } }>
              Regenerate QR Code
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <span>
              { ' ' }
              Apakah Anda Yakin Untuk Regenerate{ ' ' }
              <strong>
                { ' ' }
                { props.data !== undefined ? props.data.code : '' }{ ' ' }
              </strong>{ ' ' }
              ?{ ' ' }
            </span>
          </div>
        </ModalBody>
        <div className='footers-modal'>
          <Row>
            <Col>
              <button className='btn-submit-modals' onClick={ regenerateQR }>
                Regenerate
              </button>
            </Col>
            <Col>
              <button className='btn-cancel-modals' onClick={ props.toogle }>
                Tidak
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default ModalRegenerateQR;
