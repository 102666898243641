/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import { DELETE_PIC, UPDATE_KETERANGAN_ABSENSI } from './actionTypes';



export const actionDeletePIC = (body, callBack) => async dispatch => {
  try {
    const res = await API.deleteUser(body);
    if (res.status < 299) {
      dispatch({
        type: DELETE_PIC
      });
      toast.success('Sukses Hapus PIC');
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};

export const actionUpdateKeteranganAbsensi = (body, callBack) => async dispatch => {
  try {
    const res = await API.putAbsensi(body);
    if (res.status < 299) {
      dispatch({
        type: UPDATE_KETERANGAN_ABSENSI
      });
      toast.success('Sukses Perbarui Keterangan Absensi');
      callBack();
    }
  } catch (e) {
    if (e !== undefined) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    } else {
      toast.error('Opsss. Something When Wrong !!!');
    }
  }
};
