/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Modal } from 'reactstrap';
import '../../assets/css/main.css';
import IconClose from 'assets/img/icons/bi_x-lg-white.svg';

function ModalPhotos(props) {
  const { isDetailPhotos } = props;
  return (
    <Modal
      size='md'
      isOpen={ props.isOpen }
      toggle={ props.toggle }
      centered={ true }
      backdrop='static'
      className='special_modal'
    >
      <div
        className='d-flex justify-content-end p-2'
        style={ { cursor: 'pointer' } }
        onClick={ () => props.toggle() }
      >
        <img src={ IconClose } alt='icon close' />
      </div>
      <div className='text-center'>
        <img
          src={ `${process.env.REACT_APP_BE_URL}/` + isDetailPhotos }
          alt='img detail photo'
          className='img-fluid'
        />
      </div>
    </Modal>
  );
}

export default ModalPhotos;
