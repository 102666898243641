import DashboardAdmin from 'views/pages/dashboards/DashboardAdmin';
import LaporanPages from 'views/pages/laporan';

const routesClient = [
  {
    path: '/dashboard',
    icon: 'ni ni-tv-2 text-white',
    name: 'Dashboard',
    miniName: 'D',
    component: DashboardAdmin,
    layout: '/client',
    state: 'dashboardsCollapse',
    show: true,
  },
  {
    path: '/laporan',
    icon: 'ni ni-single-copy-04 text-white',
    name: 'Laporan',
    miniName: 'C',
    component: LaporanPages,
    layout: '/client',
    state: 'componentsCollapse',
    show: true,
  },
  // {
  //   path: '/penghuni',
  //   icon: 'fa fa-user text-white',
  //   name: 'Penghuni',
  //   miniName: 'P',
  //   component: PenghuniPages,
  //   layout: '/client',
  //   state: 'formsCollapse',
  //   show: true,
  // },
  // {
  //   path: '/security',
  //   icon: 'ni ni-badge text-white',
  //   name: 'Security',
  //   miniName: 'V',
  //   component: SecurityPages,
  //   layout: '/client',
  //   state: 'formsCollapse',
  //   show: true,
  // },
  // {
  //   path: '/profile',
  //   component: ProfilePages,
  //   name: '',
  //   layout: '/client',
  //   show: false,
  // },
  // {
  //   path: '/detail-client-:id',
  //   component: DetailClient,
  //   name: '',
  //   layout: '/client',
  //   show: false,
  // },
  // {
  //   path: '/profiles/:id',
  //   component: ProfileDetailPages,
  //   name: '',
  //   layout: '/client',
  //   show: false,
  // },
];

export default routesClient;
