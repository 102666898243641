/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-undef */
/* eslint-disable space-before-blocks */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import MyModal from 'components/Modal/MyModal';
import IconDownload from '../../../assets/img/DownloadXLS.svg';
import IconsXLS from '../../../assets/img/ph_file-xls-fill.svg';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { useFormik } from 'formik';

const ModalTitikPatroliDownload = (props) => {
    const { isOpen, toggle, clusterID } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDates, setEndDates] = useState(null);

    const formik = useFormik({
        initialValues: {
            startDate: null,
            endDate: null,
        },
        onSubmit: () => {}
    });
    
    const handleDaysDownload = val => {
        if (val === 'today') {
            let url;
            url = `${process.env.REACT_APP_BE_URL}/patroli/excel?tanggal=${val}&cluster_id=${clusterID}`;
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        } else if (val === '15') {
            let url;
            url = `${process.env.REACT_APP_BE_URL}/patroli/excel?tanggal=${val}&cluster_id=${clusterID}`;
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        } else if (val === '30'){
            let url;
            url = `${process.env.REACT_APP_BE_URL}/patroli/excel?tanggal=${val}&cluster_id=${clusterID}`;
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }
    };

    const handleDownload = () => {
        if (startDate && endDates){
            let start = moment(startDate).format('YYYY/MM/DD');
            let end = moment(endDates).format('YYYY/MM/DD');
            let date = `${start}-${end}`;
            let url;
            url = `${process.env.REACT_APP_BE_URL}/patroli/excel?tanggal=${date}&cluster_id=${clusterID}`;
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }
    };

  return (
    <MyModal
        modal={ isOpen }
        toggle={ toggle }
        title='Download XLS'
        size='lg'
    >
        <div className='d-flex justify-content-center'>
            <div className='mx-4'>
                <div className='my-2' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>Hari ini</div>
                <img src={ IconDownload } alt='icons download' style={{ cursor: 'pointer' }} onClick={() => handleDaysDownload('today') }/>
            </div>
            <div className='mx-4'>
                <div className='my-2' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>15 Hari</div>
                <img
                    src={ IconDownload }
                    alt='icons download'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDaysDownload('15') }
                />
            </div>
            <div className='mx-4'>
                <div className='my-2' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>30 Hari</div>
                <img src={ IconDownload } alt='icons download' style={{ cursor: 'pointer' }} onClick={() => handleDaysDownload('30') }/>
            </div>
        </div>
        <hr />
        <div className='d-flex justify-content-center align-items-center'>
            <div>
                <ReactDatePicker
                    selected={ startDate }
                    selectsStart
                    startDate={ startDate }
                    endDate={ endDates }
                    placeholderText='Tanggal'
                    onChange={ date => { setStartDate(date); formik.setFieldValue('startDate', date); } }
                    dateFormat='yyyy-MM-dd'
                    className='datepicks-default'
                />
            </div>
            <div className='mx-3' style={{ textAlign: 'center', color: '#2E2D2D', fontSize: 'bold' }}>s/d</div>
            <div>
                <ReactDatePicker
                    selected={ endDates }
                    onChange={ date => { setEndDates(date); formik.setFieldValue('endDate', date); } }
                    placeholderText='Tanggal'
                    dateFormat='yyyy-MM-dd'
                    className='datepicks-default'
                    selectsEnd
                    startDate={startDate}
                    endDate={ endDates }
                    minDate={startDate}
                />
            </div>
        </div>
        <div className='d-flex justify-content-center my-4'>
            {
                formik.values.startDate && formik.values.endDate ? (
                    <div className='btn-download-custom-active' onClick={handleDownload}>
                        Download <span><img className='ml-2' src={ IconsXLS } alt='icons' /></span>
                    </div>
                ) : (
                    <div className='btn-download-custom'>
                        Download <span><img className='ml-2' src={ IconsXLS } alt='icons' /></span>
                    </div>
                )
            }
        </div>
    </MyModal>
  );
};

export default ModalTitikPatroliDownload;