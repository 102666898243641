/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ReactDatePicker from 'react-datepicker';
// reactstrap components
import { Card, Container, Row, Col } from 'reactstrap';
import DetailModal from './DetailModal';
import { ButtonDark } from 'components/Buttons/indes';
import { useDispatch, useSelector } from 'react-redux';
import { actionPayroll } from 'store/security/actions';
import moment from 'moment';
import SelectCustome from 'components/Select/selectCustome';
import { useFormik } from 'formik';
import API from 'service/api/config';
import { ButtonXLS } from 'components/Buttons/indes';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import ModalSaveFormClient from 'components/Molecules/client/SaveFormClient';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{ ' ' }
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={ e => onSizePerPageChange(e.target.value) }
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{ ' ' }
        entries.
      </label>
    </div>
  ),
});

function PayrollPages() {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const componentRef = React.useRef(null);
  const [modalTambahClient, setModalTambahClient] = React.useState(false);
  const [dates, setDates] = useState(new Date());
  const [selectDate, setSelectDate] = useState('');
  const [listClient, setListClient] = useState([]);
  const [clientID, setClientID] = useState(0);
  const [selectFlag, setSelectFlag] = useState(false);
  let height = 1500;
  const [tableHeight, setTableHeight] = useState(false);
  const dataPayroll = useSelector(state => state.securityReducer.payroll);
  const useData = JSON.parse(localStorage.getItem('userData'));

  const toggleModalTambahClient = () => {
    setModalTambahClient(!modalTambahClient);
  };

  useEffect(() => {
    setTableHeight(height + 'px');
    if (useData.role.role_name === 'SUPERADMIN') {
      fetchListCLientName();
    } else {
      setSelectFlag(true);
    }
  }, []);

  const fetchListCLientName = useCallback(async() => {
    try {
      const res = await API.getClient();
      if (res.status < 299) {
        setListClient(res.data.data);
      }
    } catch (e) {
      if (e === undefined) {
        toast.error('Oppps Server has been down');
      }
    }
  }, []);

  const optionsTypeClient = listClient.map(val => {
    return {
      value: val.client_id,
      label: val.client_name,
    };
  });

  const handleDate = val => {
    setDates(val);
    const date = moment(val).format('YYYY-MM');
    setSelectDate(date);
    if (useData.role.role_name === 'SUPERADMIN') {
      dispatch(
        actionPayroll(`client_id=${clientID}`, `tanggal_gaji=${date}`, () => {})
      );
    } else {
      dispatch(
        actionPayroll(
          `client_id=${useData.client.client_id}`,
          `tanggal_gaji=${date}`,
          () => {}
        )
      );
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const handleSelectClient = val => {
    setClientID(val.value);
    dispatch(
      actionPayroll(
        `client_id=${val.value}`,
        `tanggal_gaji=${selectDate}`,
        () => {}
      )
    );
    setSelectFlag(true);
  };

  const handleBtnBankPermata = () => {
    let url;
    if (selectFlag) {
      if (useData.role.role_name === 'SUPERADMIN') {
        url = `${process.env.REACT_APP_BE_URL}/payroll/excel-permata?tanggal_gaji=${selectDate}&client_id=${clientID}`;
      } else {
        url = `${process.env.REACT_APP_BE_URL}/payroll/excel-permata?tanggal_gaji=${selectDate}&client_id=${useData.client.client_id}`;
      }
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleBtnExcel = () => {
    let url;
    if (selectFlag) {
      if (useData.role.role_name === 'SUPERADMIN') {
        url = `${process.env.REACT_APP_BE_URL}/payroll/excel?tanggal_gaji=${selectDate}&client_id=${clientID}`;
      } else {
        url = `${process.env.REACT_APP_BE_URL}/payroll/excel?tanggal_gaji=${selectDate}&client_id=${useData.client.client_id}`;
      }
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <>
      <ModalSaveFormClient
        toggle={ toggleModalTambahClient }
        modal={ modalTambahClient }
      />
      <Card body>
        <Container fluid>
          <Row>
            <div className='pr-2'>
              { useData.role.role_name === 'SUPERADMIN' ? (
                <SelectCustome
                  labelHidden={ true }
                  formik={ formik }
                  placeholder='Client'
                  options={ optionsTypeClient }
                  name='nameCluter'
                  isCustome={ true }
                  onChange={ value => handleSelectClient(value) }
                />
              ) : (
                <ButtonDark name={ useData.client?.client_name } />
              ) }
            </div>
            <Col xs={ 12 } sm={ 5 }>
              <div className='wrap-datepick'>
                <ReactDatePicker
                  selected={ dates }
                  onChange={ date => handleDate(date) }
                  dateFormat='MMMM yyyy'
                  className='datepicks'
                  disabled={ !selectFlag }
                  showMonthYearPicker
                />
              </div>
            </Col>
            <Col className='coloumn-table-head'>
              <div className='d-flex mb-3' style={ { width: '100%' } }>
                <div className='pr-2' style={ { width: '50%' } }>
                  <ButtonXLS
                    name='XLS Bank Permata'
                    onClick={ handleBtnBankPermata }
                  />
                </div>
                <div className='pr-2' style={ { width: '50%' } }>
                  <ButtonDark name='XLS' onClick={ handleBtnExcel } />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <ToolkitProvider
          data={ dataPayroll }
          keyField='name'
          columns={ [
            {
              dataField: 'user.nik',
              text: 'NIK',
              sort: false,
            },
            {
              dataField: 'user.name',
              text: 'NAMA',
              sort: false,
            },
            {
              dataField: 'user.role.role_name',
              text: 'ROLE',
              sort: false,
            },
            {
              dataField: 'nomor_rekening',
              text: 'NO REKENING',
              sort: false,
            },
            {
              dataField: 'tanggal_gaji',
              text: 'TANGGAL GAJIAN',
              sort: false,
            },
            {
              dataField: 'total_gaji',
              text: 'TOTAL GAJIAN',
              sort: false,
              formatter: cell => {
                return (
                  <NumberFormat
                    value={ cell }
                    prefix='Rp. '
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                  />
                );
              },
            },
          ] }
          search
        >
          { props => (
            <div className='py-4 table-responsive'>
              <BootstrapTable
                ref={ componentRef }
                { ...props.baseProps }
                bootstrap4={ true }
                pagination={ pagination }
                bordered={ false }
                loading={ true }
                maxHeight={ tableHeight }
                id='react-bs-table'
              />
            </div>
          ) }
        </ToolkitProvider>
      </Card>
      <DetailModal modal={ isModal } toggle={ () => setIsModal(!isModal) } />
    </>
  );
}

export default PayrollPages;
