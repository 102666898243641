/* eslint-disable quotes */
import Post from "./POST";
import Get from "./GET";
import Delete from "./Delete";
import Put from "./PUT";

// POST
const postLogin = (data, token) => Post("auth/login", data, token);
const postUser = (data, token) => Post("user", data, token);
const postUserRegister = (data, token) => Post("user/register", data, token);
const postClient = (data, token) => Post("/client", data, token);
const postClientCluster = (data) => Post("client/cluster", data);
const postAddLaporan = (data) => Post("laporan", data);
const postAddClient = (data) => Post("client", data);
const putMoveLocation = (data) => Put("user/client", data);
const postDashboard = (data) => Post("dashboard", data);
const postPatroliTitik = (data) => Post("patroli/titik", data);
const postforgote = (data) => Post("user/forgot", data);
const postResetPassword = (data) => Post("user/reset", data);
const putDownloadBankXLS = (data) => Post(`payroll/excel-permata?${data}`);
const postReadAllNotification = (data) => Post("notification", data);
const postPatroliDownloadXLS = (data) => Post("patroli/excel", data);

// PUT
const postChangePassword = (data) => Put("user/password", data);
const putProfiles = (data) => Put("user/profile", data);
const putPhotoProfile = (data) => Put("user/picture", data);
const putUpdateStatusPatroliTitik = (data) => Put("patroli/titik/status", data);
const putRegenerateQR = (data) => Put("patroli/titik/generate", data);
const putPatroliTitik = (data) => Put("patroli/titik", data);
const putUpdateClient = (client_id, data) =>
  Put("client/client/" + client_id, data);
const putClientCluster = (cluster_id, data) =>
  Put("client/cluster/" + cluster_id, data);
const putReadNotification = (data) => Put("notification", data);
const putEvaluasi = (data) => Put("evaluasi", data);
const putAbsensi = (data) => Put("absensi", data);
const putUbahJabatan = (data) => Put("user/role_user", data);
const putCredential = (data) => Put("user/credential", data);

// GET
const getUser = (user_id, data) => Get("user?user_id=" + user_id, data);
const getUserDetail = (role_name, data) =>
  Get("user?role_name=" + role_name, data);
const getUserData = (role_name, client_id, data) =>
  Get("user?role_name=" + role_name + "&client_id=" + client_id, data);
const getUserRole = (superAdmin, data) =>
  Get("user/role?superAdmin=" + superAdmin, data);
const getUserProfileDetails = (user_id) => Get(`user?user_id=${user_id}`);
const getClient = (data) => Get("client", data);
const getClientParam = (client_id, data) =>
  Get("client?client_id=" + client_id, data);
const getClientCluster = (client_name, titik, client_id) =>
  Get(
    `client/cluster?client_name=${client_name}&titik=${titik}&client_id=${client_id}`,
  );
const getClientClusterName = (client_name, data) =>
  Get("client/cluster?client_name=" + client_name, data);
const getAbsensi = (client_id, tanggal, cluster_id) =>
  Get(
    "user/absensi?client_id=" +
      client_id +
      "&tanggal=" +
      tanggal +
      "&cluster_id=" +
      cluster_id +
      "&kelengkapan=" +
      true,
  );
const getAbsensiKelengkapan = (query, data) => Get(query, data);
const getListLaporan = (client_id, data) =>
  Get("laporan?client_id=" + client_id, data);
const getListLaporanOnScroll = (client_id, take, page, data) =>
  Get(`laporan/v2?page=${page}&take=${take}&client_id=${client_id}`, data);
const getListTemuanLaporan = (role_name) => Get("user?role_name=" + role_name);
const getEvaluasi = (user_id, data) => Get("evaluasi?user=" + user_id, data);
const getPatroliTitik = (cluster_id) =>
  Get("patroli/titik?cluster_id=" + cluster_id);
const getPatroli = (url) => Get(`patroli?${url}`);
const getUserAbsensi = (url) => Get(`user/absensi?${url}`);
const getPayroll = (clientID, dates) => Get(`payroll?${dates}&${clientID}`);
const getNotification = (params) => Get(`notification?${params}`);
const getListJabatan = () => Get(`user/role?security=true`);
const getCluster = (params) => Get(`client/cluster${params}`);
const getUserPresence = (user_id, tanggal) =>
  Get(`user/presence?page=1&take=999&user_id=${user_id}&tanggal=${tanggal}`);

// DELETE
const deleteClient = (data) => Delete(`client/cluster/${data}`);
const deletePatroliTitik = (data) => Delete("patroli/titik", data);
const deleteUser = (user_id) => Delete("user?user_id=" + user_id);
const deleteEvaluasi = (id) => Delete(`evaluasi?evaluasi_id=${id}`);

const API = {
  postLogin,
  postUser,
  postUserRegister,
  postClient,
  postClientCluster,
  postAddLaporan,
  postAddClient,
  postPatroliTitik,
  postReadAllNotification,
  putMoveLocation,
  putProfiles,
  putPhotoProfile,
  putUpdateStatusPatroliTitik,
  putCredential,
  postDashboard,
  putRegenerateQR,
  putPatroliTitik,
  postforgote,
  postResetPassword,
  postChangePassword,
  putUpdateClient,
  putClientCluster,
  putDownloadBankXLS,
  putReadNotification,
  putEvaluasi,
  putAbsensi,
  putUbahJabatan,
  postPatroliDownloadXLS,
  getCluster,
  getUser,
  getUserDetail,
  getUserData,
  getUserRole,
  getUserProfileDetails,
  getClient,
  getClientParam,
  getClientCluster,
  getClientClusterName,
  getAbsensi,
  getListLaporan,
  getListTemuanLaporan,
  getAbsensiKelengkapan,
  getEvaluasi,
  getPatroliTitik,
  getUserAbsensi,
  getPatroli,
  getPayroll,
  getNotification,
  getListJabatan,
  getListLaporanOnScroll,
  getUserPresence,

  deleteClient,
  deletePatroliTitik,
  deleteUser,
  deleteEvaluasi,
};

export default API;
