/* eslint-disable no-undef */
/* eslint-disable space-before-blocks */
/* eslint-disable space-in-parens */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import TemuanPatroli from "./temuanPatroli";
import Laporan from "./laporan";
import API from "service/api/config";
import { toast } from "react-toastify";
import TitikPatroli from "./titikPatroli";
import { useDispatch, useSelector } from "react-redux";
import { actionListClusters } from "store/cluster/actions";
import { LAPORAN_CLIENT_ID } from "store/laporan/actionTypes";
import { SAVE_DATA_TITIK_PATROLI } from "store/laporan/actionTypes";
import { addDefaultSrc } from "helpers/brokenImg";
import { ROLE_SUPER_ADMIN } from "helpers/types";
import { ROLE_ADMIN, ROLE_CLIENT } from "helpers/types";

function LaporanPages() {
  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = React.useState("1");
  const [listClient, setListClient] = useState([]);
  const [clientid, setClientid] = useState("");
  const [images, setImages] = useState("");
  const [data, setData] = React.useState([]);
  const role = localStorage.getItem("role");
  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const idClient = useSelector((state) => state.laporanReducer.name_client);
  const [reRender, setReRender] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [endOfLaporan, setEndOfLaporan] = useState(false);

  const getClient = useCallback(async () => {
    try {
      const res = await API.getClient();
      if (res.status === 200) {
        if (role === ROLE_CLIENT) {
          setClientid(res?.data?.data[0].client_id);
        }
        setListClient(res.data.data);
      }
    } catch (e) {
      if (e === undefined) {
        toast.error("Oppps Server has been down");
      }
    }
  }, []);

  useEffect(() => {
    getClient();
    fetchListCluster();
  }, [reRender]);

  const fetchListCluster = useCallback(() => {
    if (
      userData.role.role_name === ROLE_SUPER_ADMIN ||
      userData.role.role_name === ROLE_ADMIN
    ) {
      if (idClient) {
        dispatch(actionListClusters(idClient, true, () => {}));
      }
    } else {
      dispatch(
        actionListClusters(userData.client?.client_name, true, () => {}),
      );
    }
  }, [idClient]);

  useEffect(() => {
    if (role === ROLE_CLIENT) {
      if (clientid) {
        API.getListLaporan(clientid).then((res) => {
          if (res.data.success) {
            setData(res.data?.data);
          } else {
            toast.error("Error Fetch!!!");
          }
        });
      }
    }
  }, []);

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const handleSelectCluster = (val) => {
    if (clientid != "") {
      setData([]);
      setPage(1);
      setEndOfLaporan(false);
    }
    setImages(val.image);
    // API.getListLaporan(val.value).then((res) => {
    //   if (res.data.success) {
    //     setData(res.data?.data);
    //   } else {
    //     toast.error("Error Fetch!!!");
    //   }
    // });
    fetchLaporan(val.value);
    API.getClientCluster(val.label, true).then((res) => {
      if (res.data.success) {
        dispatch({
          type: SAVE_DATA_TITIK_PATROLI,
          payload: res.data.data,
        });
      } else {
        toast.error("Error Fetch!!!");
      }
    });
    setReRender(!reRender);
    dispatch({
      type: LAPORAN_CLIENT_ID,
      payload: val.label,
    });
    setClientid(val.value);
    setImages(val?.image);
    setIsDisabled(false);
  };

  const optionsTypeClient = listClient.map((val) => {
    return {
      value: val.client_id,
      label: val.client_name,
      image: val.image_link,
    };
  });

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      color: "yellow",
    }),
  };

  const fetchLaporan = (client_id) => {
    setIsLoading(true);
    API.getListLaporanOnScroll(client_id, 10, page).then((res) => {
      if (res.data.success) {
        if (res.data?.data.length == 0) {
          setEndOfLaporan(true);
        } else {
          setData((prevData) => [...prevData, ...res.data?.data]);
          // setData(res.data?.data);
          setPage((prevPage) => prevPage + 1);
        }
      } else {
        toast.error("Error Fetch!!!");
      }
      setIsLoading(false);
    });
  };

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
        Math.ceil(document.documentElement.offsetHeight) ||
      isLoading
    ) {
      return;
    }
    if (!endOfLaporan) {
      fetchLaporan(clientid);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  return (
    <>
      <div className="wrap-text-label-head">
        <span className="txt-label-pages">Laporan</span>
        <div className="wrap-head-laporan">
          {role === ROLE_SUPER_ADMIN || role === ROLE_ADMIN ? (
            <Row>
              <Col md="4">
                <img
                  onError={addDefaultSrc}
                  src={`${process.env.REACT_APP_BE_URL}/${images}`}
                  alt="img_laporan"
                  className="img-thumbnail"
                />
              </Col>
              <Col>
                <div className="wrap-label-laporan">
                  <Select
                    options={optionsTypeClient}
                    name="tipeClient"
                    onChange={handleSelectCluster}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Pilih Client"
                    styles={style}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md="4">
                <img
                  src={
                    `${process.env.REACT_APP_BE_URL}/` +
                    userData?.client?.image_link
                  }
                  alt="img_laporan"
                />
              </Col>
              <Col>
                <div className="wrap-label-laporan">
                  <span className="txt-head-label-laporan">
                    {userData?.client?.client_name}
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <Container>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "1" ? "actives-tab" : null}`}
              >
                Laporan
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                actives: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <span
                className={`txt-lbl-sub ${activeTab === "2" ? "actives-tab" : null}`}
              >
                Temuan Patroli
              </span>
            </NavLink>
          </NavItem>
          {role !== "CLIENT" ? (
            <NavItem>
              <NavLink
                className={classnames({
                  actives: activeTab === "3",
                })}
                onClick={() => {
                  toggle("3");
                }}
              >
                <span
                  className={`txt-lbl-sub ${activeTab === "3" ? "actives-tab" : null}`}
                >
                  Titik Patroli
                </span>
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane
            tabId="1"
            className="p-3"
            style={{ backgroundColor: "#fff" }}
          >
            <Row>
              <Col sx="12">
                <Laporan
                  activeTab={activeTab}
                  data={data}
                  clientid={clientid}
                  isDisabled={isDisabled}
                  endOfLaporan={endOfLaporan}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tabId="2"
            className="p-3"
            style={{ backgroundColor: "#fff" }}
          >
            <Row>
              <Col sx="12">
                <TemuanPatroli activeTab={activeTab} />
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tabId="3"
            className="p-3"
            style={{ backgroundColor: "#fff" }}
          >
            <Row>
              <Col sx="12">
                <TitikPatroli />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default LaporanPages;
