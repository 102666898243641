/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';


export default function SelectCustome(props) {
  const { label, name, formik, labelHidden } = props;
  return (
    <div>
    <Label hidden={ labelHidden }>{ label }</Label>
    <Select
       defaultValue={ props.selected }
       name={ props.name }
       placeholder={ props.placeholder }
       options={ props.options }
       isDisabled={ props.isDisabled }
       isOptionDisabled={ props.disableProps }
       components={ {
         IndicatorSeparator: () => null,
       } }
       onChange={ props.isCustome ? (value) => props.onChange(value) : (value: any) => {
         props.formik.setFieldValue(props.name, value.value);
       } }
       styles={ {
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: '#ffffff',
            };
        },
        control: styles => ({
          ...styles,
          backgroundColor: '#1B2B4B',
          border: 'none',
          borderRadius: '0.25rem',
        }),
        menuList: base => ({
          ...base,
          padding: 0,
          backgroundColor: '#fff',
          color: '#9B9FB7',
          width: '250px'
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        input: styles => ({ ...styles, color: '#ffff' }),
        singleValue: styles => ({ ...styles, color: '#fff' })
      } }

    />
    { formik.touched[name] && formik.errors[name] ? (
      <div style={ { color: 'red' } }>{ formik.errors[name] }</div>
    ) : null }
  </div>
  );
}
