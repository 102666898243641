/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import API from 'service/api/config/index';
import FormPenghuni from './FormPenghuni';
import FormClient from './FormClient';
import FormAdmin from './FormAdmin';
import FormChief from './FormChief';

function Tambah() {
  const [listTipePengguna, setListTipePengguna] = useState([]);
  const [type, setType] = useState();
  const roles = localStorage.getItem('role');
  const [reRender, setReRender] = useState(true);

  useEffect(() => {
    getTipePengguna();
  }, [reRender]);

  const getTipePengguna = useCallback(() => {
    if (roles === 'SUPERADMIN') {
      API.getUserRole(true).then(res => {
        setListTipePengguna(res?.data?.data);
      });
    } else {
      API.getUserRole(false).then(res => {
        setListTipePengguna(res.data.data);
      });
    }
  }, []);

  const options = listTipePengguna.map(val => {
    return {
      value: val.role_id,
      label: val.role_name,
    };
  });

  return (
    <>
      <SimpleHeader name='Form components' parentName='Forms' />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='8'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <span>Tambah Pengguna</span>
                </CardHeader>
                <CardBody>
                  <Label>Tipe Pengguna</Label>
                  <Select
                    options={ options }
                    name='tipePengguna'
                    onChange={ val => {
                      setType(val.value);
                      setReRender(!reRender);
                    } }
                  />
                  <hr />
                  { /* { type === 4 ? (<FormScurity typeId={ type } />) : null } */ }
                  { type === 4 ? (
                    // page anggota
                    <FormChief typeId={ type } modeMultiple={ false } reRender={ reRender } />
                    // <FormSecurity typeId={ type } />
                  ) : type === 3 ? (
                    <FormClient typeId={ type } />
                  ) : type === 5 ? (
                    <FormPenghuni typeId={ type } />
                  ) : type === 6 ? (
                    // page danru
                    <FormChief typeId={ type } modeMultiple={ false } />
                    // <FormDanru typeId={ type } />
                  ) : type === 7 ? (
                    // Chief
                    <FormChief typeId={ type } modeMultiple={ true } />
                  ) : roles === 'SUPERADMIN' && type === 2 ? (
                    <FormAdmin typeId={ type } />
                  ) : ('') }
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tambah;
