/* eslint-disable react/jsx-no-bind */
import { ButtonDark } from 'components/Buttons/indes';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactDatePicker from 'react-datepicker';
import { Col, Modal } from 'reactstrap';
import { dataTable } from 'variables/general';

export default function DetailModal(props) {
  const [dates, setDates] = useState(new Date());

  const columns = [{
    dataField: 'id',
    text: 'Product ID'
  }, {
    dataField: 'name',
    text: 'Product Name'
  }, {
    dataField: 'price',
    text: 'Product Price'
  }];
  
  
  return (
    <div>
      <Modal size='xl' isOpen={ props.modal } toggle={ props.toggle } centered={ true } backdrop='static'>
        <div
          className='d-flex justify-content-end pr-3 pt-2'
          onClick={ props.toggle }
          style={ { cursor: 'pointer' } }
          >
          X
        </div>
        <div className='p-5'>
          <div className='d-flex'>
            <div>
              <img
                alt='...'
                className='avatar rounded-circle'
                src={ require('assets/img/theme/team-1.jpg').default }
              />
            </div>
            <Col>
              <div style={ { color: '#2E2D2D', fontSize: '16px' } }>Jane</div>
              <div>
                <span>Img ini</span>
                <span
                  style={ { paddingLeft: '5px', color: '#005CAA', fontSize: '14px' } }
                >
                  2293127327432
                </span>
              </div>
            </Col>
            <Col>
            <div className='wrap-datepick'>
              <ReactDatePicker
                selected={ dates }
                onChange={ date => setDates(date) }
                dateFormat='yyyy-MM-dd'
                className='datepicks'
              />
            </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-end align-items-center'>
                <div className='pr-2' style={ { width: '100%' } }>
                  <p>Export :</p>
                </div>
                <div className='pr-2' style={ { width: '100%' } }>
                  <ButtonDark
                    name='XLS'
                  />
                </div>
                <div className='pr-2' style={ { width: '100%' } }>
                  <ButtonDark
                    name='PDF'
                  />
                </div>
              </div>
            </Col>
          </div>
          <div className='mt-5'>
            <BootstrapTable keyField='id' data={ dataTable } columns={ columns } />
          </div>
        </div>
      </Modal>
    </div>
  );
}
