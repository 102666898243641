/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

export default function Section4(props) {
  const { formik, disable } = props;

  return (
    <Fragment>
      <div className='wrap-form'>
        <Label>Alamat</Label>
        <Input
          name='address'
          type='text'
          value={ formik.values.address }
          disabled={ disable }
          onChange={ formik.handleChange }
        />
        { formik.touched.address && formik.errors.address ? (
          <div style={ { color: 'red' } }>{ formik.errors.address }</div>
        ) : null }
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Label>Kota</Label>
            <Input
              name='city'
              type='text'
              value={ formik.values.city }
              disabled={ disable }
              onChange={ formik.handleChange }
            />
            { formik.touched.city && formik.errors.city ? (
              <div style={ { color: 'red' } }>{ formik.errors.city }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Provinsi</Label>
            <Input
              name='province'
              type='text'
              value={ formik.values.province }
              disabled={ disable }
              onChange={ formik.handleChange }
            />
            { formik.touched.province && formik.errors.addprovinceress ? (
              <div style={ { color: 'red' } }>{ formik.errors.province }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Kode Pos</Label>
            <Input
              name='code_pos'
              type='number'
              value={ formik.values.code_pos }
              disabled={ disable }
              onChange={ formik.handleChange }
            />
            { formik.touched.code_pos && formik.errors.code_pos ? (
              <div style={ { color: 'red' } }>{ formik.errors.code_pos }</div>
            ) : null }
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
