/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Label, CardFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useFormik } from 'formik';
import moment from 'moment';
import API from 'service/api/config';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import IconFieldCheck from '../../../assets/img/icons/Field_Checkbox.svg';
import { validateTambahPengguna } from 'validates';
import Inputs from 'components/Forms';

export default function FormAdmin(props) {
  const { typeId } = props;
  const [listClient, setListClient] = useState([]);
  // const [listCluster, setListCluster] = useState([]);
  const [uploadPhoto, setUploadPhoto] = useState('');
  const [reRender, setReRender] = useState(true);
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [years, setYears] = useState();
  const history = useHistory();
  const photoRef = useRef();

  useEffect(() => {
    getClusterPengguna();
    getClient();
  }, [reRender]);

  const onChangePhoto = e => {
    setUploadPhoto(e.target.files[0]);
  };

  const resetPhoto = () => {
    photoRef.current.value = '';
  };

  const optionsTypeClient = listClient.map(val => {
    return {
      value: val.client_id,
      label: val.client_name,
    };
  });

  const handleSelectCluster = val => {
    formik.setFieldValue('tipeClient', val.value);
    getClusterPengguna(val.label);
  };
  // to do ini ada setListcluster tp ga di pake dimana" pusing ajig
  const getClusterPengguna = val => {
    API.getClientClusterName(val).then(() => {
      // ini buat apa yah
      // setListCluster(res?.data?.data);
    });
  };

  const getClient = useCallback(() => {
    API.getClient().then(res => {
      setListClient(res.data.data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      tipePengguna: '',
      tipeClient: '',
      fullname: '',
      gender: '',
      bornplace: '',
      numberId: '',
      religion: '',
      address: '',
      telephone: '',
      rekening: '',
      kota: '',
      province: '',
      post_code: '',
      email: '',
      password: '',
      upload: '',
      day: '',
      month: '',
      years: '',
    },
    validationSchema: validateTambahPengguna.admin,
    onSubmit: async values => {
      try {
        let year = moment(years).format('YYYY');
        let months = moment(month).format('MM');
        let days = moment(day).format('DD');

        let dates = year + '-' + months + '-' + days;

        const formData = new FormData();
        formData.append('role_id', typeId);
        formData.append('name', values.fullname);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('gender', values.gender);
        formData.append('birth_plc', values.bornplace);
        formData.append('birth_dt', dates);
        formData.append('nik', values.numberId);
        formData.append('agama', values.religion);
        formData.append('phone', values.telephone);
        formData.append('alamat', values.address);
        formData.append('no_rekening', values.rekening);
        formData.append('kota', values.kota);
        formData.append('provinsi', values.province);
        formData.append('kode_pos', values.post_code);
        // formData.append('cluster_id', values.tipeCluster);
        formData.append('client_id', values.tipeClient);
        formData.append('image_link', uploadPhoto);

        const response = await API.postUser(formData);
        if (response.status === 201) {
          toast.success('Upload Successful!');
          setReRender(true);
          setTimeout(() => {
            history.go(0);
          }, 8000);
        }
      } catch (e) {
        if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  return (
    <form onSubmit={ formik.handleSubmit }>
      <div className='wrap-form'>
        <Inputs
          label='Nama Lengkap'
          placeholder='Your Name'
          type='text'
          name='fullname'
          formik={ formik }
        />
      </div>
      <div className='wrap-rad-btn'>
        <Label>Jenis Kelamin</Label>
        <Row>
          <Col md={ 6 }>
            <input
              className='input-rad-btn'
              type='radio'
              name='gender'
              value='Pria'
              onChange={ formik.handleChange }
            />
            <span className='txt-rad-btn'>Pria</span>
          </Col>
          <Col md={ 6 }>
            <input
              className='input-rad-btn'
              type='radio'
              name='gender'
              value='Wanita'
              onChange={ formik.handleChange }
            />
            <span className='txt-rad-btn'>Wanita</span>
          </Col>
        </Row>
        { formik.touched.gender && formik.errors.gender ? (
          <div style={ { color: 'red' } }>{ formik.errors.gender }</div>
        ) : null }
      </div>
      <hr />
      <div className='wrap-form'>
        <Inputs
          label='Tempat Tanggal Lahir'
          placeholder='Jl.Melawai'
          type='text'
          name='bornplace'
          formik={ formik }
        />
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Label>Tanggal</Label>
            <DatePicker
              name='day'
              selected={ day }
              onChange={ date => {
                setDay(date);
                formik.setFieldValue('day', date);
              } }
              dateFormat='dd'
              showDayDropdown
              className='input-date-picker-customs'
              placeholderText='Hari'
            />
            { formik.touched.day && formik.errors.day ? (
              <div style={ { color: 'red' } }>{ formik.errors.day }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Bulan</Label>
            <DatePicker
              name='month'
              selected={ month }
              onChange={ date => {
                setMonth(date);
                formik.setFieldValue('month', date);
              } }
              dateFormat='MM'
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
              className='input-date-picker-customs'
              placeholderText='Bulan'
            />
            { formik.touched.month && formik.errors.month ? (
              <div style={ { color: 'red' } }>{ formik.errors.month }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Tahun</Label>
            <DatePicker
              name={ years }
              selected={ years }
              onChange={ date => {
                setYears(date);
                formik.setFieldValue('years', date);
              } }
              showYearPicker
              dateFormat='yyyy'
              className='input-date-picker-customs'
              placeholderText='Tahun'
            />
            { formik.touched.years && formik.errors.years ? (
              <div style={ { color: 'red' } }>{ formik.errors.years }</div>
            ) : null }
          </div>
        </Col>
      </Row>
      <hr />
      <div className='wrap-form'>
        <Inputs
          label='NIK'
          formik={ formik }
          placeholder='9998211832812'
          type='number'
          name='numberId'
        />
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Inputs
              label='Agama'
              formik={ formik }
              placeholder='Islam'
              type='text'
              name='religion'
            />
          </div>
        </Col>
      </Row>
      <hr />
      <div className='wrap-form'>
        <Inputs
          formik={ formik }
          label='Alamat'
          placeholder=''
          type='text'
          name='address'
        />
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Inputs
              formik={ formik }
              label='Kota'
              placeholder=''
              type='text'
              name='kota'
            />
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Inputs
              label='Provinsi'
              formik={ formik }
              placeholder=''
              type='text'
              name='province'
            />
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Inputs
              label='Kode Pos'
              formik={ formik }
              placeholder=''
              type='number'
              name='post_code'
            />

          </div>
        </Col>
      </Row>
      <div className='wrap-form'>
        <Inputs
          label='No. Telepon'
          formik={ formik }
          placeholder=''
          type='number'
          name='telephone'
        />

      </div>
      <div className='wrap-form'>
        <Inputs
          label='No. Rekening'
          formik={ formik }
          placeholder=''
          type='number'
          name='rekening'
        />

      </div>
      <hr />
      <div className='wrap-form'>
        <Inputs
          label='Email'
          formik={ formik }
          placeholder=''
          type='text'
          name='email'
        />

      </div>
      <div className='wrap-form'>
        <Inputs
          label='Password'
          formik={ formik }
          placeholder=''
          type='password'
          name='password'
        />
      </div>
      <div className='wrap-form'>
        <Label className='tit-upload'>Photo Profile</Label>
        <div className='d-flex'>
          <div>
            <input
                name='upload'
                className='upload-doc'
                type='file'
                accept='image/*'
                onChange={ e => {
                  formik.handleChange(e);
                  onChangePhoto(e);
                } }
                ref={ photoRef }
            />
          </div>
          <div className='px-3'>
            <img
                style={ { cursor: 'pointer' } }
                src={  IconFieldCheck }
                alt='icon check'
                onClick={ () => resetPhoto() }
            />
          </div>
        </div>
      </div>
      <hr />
      <label>Penempatan</label>
      <Row>
        <Col>
          <label>Client</label>
          <Select
            options={ optionsTypeClient }
            name='tipeClient'
            onChange={ handleSelectCluster }
          />
        </Col>
        { /* <Col>
          <lable>Cluster</lable>
          <Select
            options={ optionsTypeCluster }
            name='tipeCluster'
            onChange={ val => {
              formik.setFieldValue('tipeCluster', val.value);
            } }
          />
          { formik.touched.tipeCluster && formik.errors.tipeCluster ? (
            <div style={ { color: 'red' } }>{ formik.errors.tipeCluster }</div>
          ) : null }
        </Col> */ }
      </Row>
      { formik.touched.tipeClient && formik.errors.tipeClient ? (
        <div style={ { color: 'red' } }>{ formik.errors.tipeClient }</div>
      ) : null }
      <CardFooter>
        <button type='submit' className='btn-tambah-pengguna'>
          <span>Tambah Pengguna</span>
        </button>
      </CardFooter>
    </form>
  );
}
