import React, { useState } from 'react';
import './register.css';
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Spinner,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BaktiLogo from '../../../../assets/img/brand/Logo.svg';
import { useFormik } from 'formik';

const RegisterPages = () => {
  // show password
  const [showPassword] = useState(false);

  // Notif
  const [alertLogin] = useState(false);
  const [alertLoginFail] = useState(false);
  const [alertLoginError] = useState(false);
  const [notifLogin] = useState(false);
  const [notifLoginFail] = useState(false);
  const [notifLoginError] = useState(false);
  const [msgErr] = useState('Login Gagal');

  // loading
  const [isLoading] = useState(false);
  // Show Password

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: () => {
      // to do logic
    },
  });

  return (
    <>
      <div>
        <Row className='containerlogin'>
          <Col className={ 'txtLoginpages' } />
          <Col className={ 'ctnLoginpages' }>
            <div className={ 'wrapps' }>
              <div className='d-flex justify-content-center sign'>
                <div className={ 'wrpPages' }>
                  <div className={ 'formUs' }>
                    <center>
                      <Alert
                        color={ alertLogin ? 'success' : 'success' }
                        isOpen={ notifLogin }
                        className='alertDetail'
                      >
                        Berhasil Login
                      </Alert>
                      <Alert
                        color={ alertLoginFail ? 'success' : 'danger' }
                        isOpen={ notifLoginFail }
                        className='alertDetail'
                      >
                        { alertLoginFail ? 'berhasil' : msgErr }
                      </Alert>
                      <Alert
                        color={ alertLoginError ? 'success' : 'danger' }
                        isOpen={ notifLoginError }
                        className='alertDetail'
                      >
                        { alertLoginError ? 'berhasil' : msgErr }
                      </Alert>
                    </center>

                    <div style={ { marginTop: '20px' } }>
                      <center>
                        <img src={ BaktiLogo } alt='logomonev' />
                        <span className='tit-login-card'>
                          D A S H B O A R D
                        </span>
                      </center>
                    </div>
                    <form onSubmit={ formik.handleSubmit }>
                      <InputGroup className='wrap-pass'>
                        <Input
                          name='username'
                          className='inputUname'
                          placeholder='username'
                          onChange={ formik.handleChange }
                        />
                      </InputGroup>
                      <Col style={ { textAlign: 'center' } }>
                        { formik.touched.username && formik.errors.username ? (
                          <div style={ { color: 'red', fontSize: '12px' } }>
                            { formik.errors.username }
                          </div>
                        ) : null }
                      </Col>
                      <InputGroup className='wrap-pass'>
                        <Input
                          name='email'
                          className='registers'
                          placeholder='email'
                          onChange={ formik.handleChange }
                        />
                      </InputGroup>
                      <Col style={ { textAlign: 'center' } }>
                        { formik.touched.email && formik.errors.email ? (
                          <div style={ { color: 'red', fontSize: '12px' } }>
                            { formik.errors.email }
                          </div>
                        ) : null }
                      </Col>
                      <InputGroup className='wrap-pass'>
                        <Input
                          name='password'
                          type={ showPassword ? 'text' : 'password' }
                          className='registers'
                          placeholder='password'
                          onChange={ formik.handleChange }
                        />
                      </InputGroup>
                      <Col style={ { textAlign: 'center' } }>
                        { formik.touched.password && formik.errors.password ? (
                          <div style={ { color: 'red', fontSize: '12px' } }>
                            { formik.errors.password }
                          </div>
                        ) : null }
                      </Col>
                      <InputGroup className='wrap-pass'>
                        <Input
                          name='confirmPassword'
                          type={ showPassword ? 'text' : 'password' }
                          className='registers'
                          placeholder='confirm password'
                          onChange={ formik.handleChange }
                        />
                      </InputGroup>
                      <Col style={ { textAlign: 'center' } }>
                        { formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                          <div style={ { color: 'red', fontSize: '12px' } }>
                            { formik.errors.confirmPassword }
                          </div>
                        ) : null }
                      </Col>
                      <div className='d-flex'>
                        <Button
                          className={ 'btnSubmit' }
                          color=''
                          type='submit'
                          disabled={ isLoading }
                        >
                          { isLoading ? (
                            <Spinner type='grow' color='light' size='sm' />
                          ) : (
                            'REGISTER'
                          ) }
                        </Button>
                      </div>
                    </form>
                  </div>
                  <div className='divid'>
                    Kembali Ke Halaman{ ' ' }
                    <Link className={ 'signUpStyle' } to='/login'>
                      { ' ' }
                      Login.{ ' ' }
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RegisterPages;
