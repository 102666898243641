/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_MARKER } from '../../store/cluster/actionTypes';
import iconGreen from '../../assets/img/icons/marker-green.png';
import iconBlue from '../../assets/img/icons/marker-blue.png';

export const Markers = props => {
  const [mergeMarker, setMergeMarker] = useState([]);
  const data = useSelector(state => state.clusterReducer.list_cluster);
  const markers = useSelector(state => state.clusterReducer.markers);
  const filteredData = useSelector(state => state.clusterReducer.filteredData);
  const newMarker = useSelector(state => state.clusterReducer.newMarker);
  const [dataMarker, setDataMarker] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // validater filter data marker by index
    if (filteredData.length > 0) {
      setDataMarker(filteredData);
    } else {
      setDataMarker(data);
    }

    if (newMarker.length > 0) {
      setMergeMarker([...markers, newMarker]);
    } else {
      setMergeMarker(markers);
    }
  }, [newMarker, data, markers]);

  const removeMarker = () => {
    dispatch({
      type: REMOVE_MARKER,
    });
  };

  return (
    <Fragment>

      { mergeMarker.map((marker, index) => (
        <Marker
          key={ index }
          offsetTop={ -48 }
          offsetLeft={ -24 }
          latitude={  marker[1] ? marker[1] : 0 }
          longitude={  marker[0] ? marker[0] : 0 }
        >
          { props.active && !dataMarker[index]?.cluster_id ?
              (
                <img src={ dataMarker[index]?.cluster_id ? iconGreen : iconBlue }
                     style={ { cursor: 'pointer' } }
                     onClick={ () => removeMarker(index) }
                />
          ) : (<img src={ dataMarker[index]?.cluster_id ? iconGreen : iconBlue }/>) }


        </Marker>
      )) }
    </Fragment>
  );
};
