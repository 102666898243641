/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { CardFooter } from "reactstrap";
import { useFormik } from "formik";
import moment from "moment";
import API from "service/api/config";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { validateTambahPengguna } from "validates";
import Section1 from "components/Molecules/TambahPengguna/Chief/Section1";
import Section2 from "components/Molecules/TambahPengguna/Chief/Section2";
import Section3 from "components/Molecules/TambahPengguna/Chief/Section3";
import Section4 from "components/Molecules/TambahPengguna/Chief/Section4";
import Section5 from "components/Molecules/TambahPengguna/Chief/Section5";

export default function FormChief(props) {
  const { typeId, modeMultiple, reRender } = props;
  const [listClient, setListClient] = useState([]);
  const [listCluster, setListCluster] = useState([]);
  const [uploadPhoto, setUploadPhoto] = useState("");
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [years, setYears] = useState(null);
  const [joinDay, setJoinDay] = useState(null);
  const [joinMonth, setJoinMonth] = useState(null);
  const [joinYear, setJoinYear] = useState(null);
  const history = useHistory();
  const photoRef = useRef();

  const formik = useFormik({
    initialValues: {
      tipePengguna: "",
      tipeClient: "",
      tipeCluster: "",
      fullname: "",
      gender: "",
      bornplace: "",
      numberId: "",
      religion: "",
      address: "",
      telephone: "",
      rekening: "",
      kota: "",
      province: "",
      post_code: "",
      email: "",
      password: "",
      upload: "",
      hari_bergabung: "",
      bulan_bergabung: "",
      tahun_bergabung: "",
      day: "",
      month: "",
      years: "",
      multiRole: false,
      no_kta: "",
      no_bpjs: "",
      no_bpjs_kesehatan: "",
      no_npwp: "",
      pendidikan_terakhir: "",
    },
    validationSchema: validateTambahPengguna.chief,
    onSubmit: async (values) => {
      try {
        let year = moment(years).format("YYYY");
        let months = moment(month).format("MM");
        let days = moment(day).format("DD");

        let dates = year + "-" + months + "-" + days;
        let joinDates =
          values.tahun_bergabung +
          "-" +
          values.bulan_bergabung +
          "-" +
          values.hari_bergabung;

        const formData = new FormData();
        formData.append("role_id", typeId);
        formData.append("name", values.fullname);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("gender", values.gender);
        formData.append("birth_plc", values.bornplace);
        formData.append("birth_dt", dates);
        formData.append("nik", values.numberId);
        formData.append("agama", values.religion);
        formData.append("phone", values.telephone);
        formData.append("alamat", values.address);
        formData.append("no_rekening", values.rekening);
        formData.append("kota", values.kota);
        formData.append("provinsi", values.province);
        formData.append("kode_pos", values.post_code);
        formData.append("client_id", values.tipeClient);
        formData.append("no_kta", values.no_kta);
        formData.append("no_bpjs", values.no_bpjs);
        formData.append("no_bpjs_kesehatan", values.no_bpjs_kesehatan);
        formData.append("no_npwp", values.no_npwp);
        formData.append("pendidikan_terakhir", values.pendidikan_terakhir);
        if (typeId === 4 || typeId === 6) {
          formData.append("cluster_id", values.tipeCluster);
        }
        formData.append("image_link", uploadPhoto);
        formData.append("join_dt", joinDates);

        const response = await API.postUser(formData);
        if (response.status === 201) {
          toast.success("Upload Successful!");
          setTimeout(() => {
            history.go(0);
          }, 8000);
        }
      } catch (e) {
        if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  useEffect(async () => {
    formik.resetForm();
    await formik.setFieldValue("multiRole", modeMultiple);
    getClusterPengguna();
    getClient();
  }, [typeId, reRender]);

  const onChangePhoto = (e) => {
    setUploadPhoto(e.target.files[0]);
  };

  const resetPhoto = () => {
    photoRef.current.value = "";
  };

  const optionsTypeClient = listClient.map((val) => {
    return {
      value: val.client_id,
      label: val.client_name,
    };
  });

  const optionsTypeCluster = listCluster.map((val) => {
    return {
      value: val.cluster_id,
      label: val.cluster_name,
    };
  });

  // function get data cluster to anggota n danru
  const handleSelectCluster = (val) => {
    formik.setFieldValue("tipeClient", val.value);
    if (typeId === 4 || typeId === 6) {
      getClusterPengguna(val.label);
    }
  };

  // ini digunakan untuk anggota dan danru
  const getClusterPengguna = (val) => {
    API.getClientClusterName(val).then((res) => {
      setListCluster(res?.data?.data);
    });
  };

  const getClient = useCallback(() => {
    API.getClient().then((res) => {
      setListClient(res.data.data);
    });
  }, []);

  const componentButton = () => {
    if (typeId === 4 || typeId === 6) {
      return (
        <button type="submit" className="btn-tambah-pengguna">
          <span>Tambah Pengguna</span>
        </button>
      );
    } else {
      return (
        <button type="submit" className="btn-tambah-pengguna">
          <span>Tambah Pengguna</span>
        </button>
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Section1 formik={formik} />
      <hr />
      <Section2
        formik={formik}
        day={day}
        setDay={setDay}
        month={month}
        setMonth={setMonth}
        years={years}
        setYears={setYears}
      />
      <hr />
      <Section3 formik={formik} />
      <hr />
      <Section4
        formik={formik}
        onChangePhoto={onChangePhoto}
        photoRef={photoRef}
        resetPhoto={resetPhoto}
      />
      <hr />
      <Section5
        formik={formik}
        optionsTypeClient={optionsTypeClient}
        handleSelectCluster={handleSelectCluster}
        joinDay={joinDay}
        setJoinDay={setJoinDay}
        joinMonth={joinMonth}
        setJoinMonth={setJoinMonth}
        joinYear={joinYear}
        setJoinYear={setJoinYear}
        optionsTypeCluster={optionsTypeCluster}
        typeId={typeId}
      />
      <CardFooter className="mt-4">{componentButton()}</CardFooter>
    </form>
  );
}
