import * as Yup from 'yup';

const pengguna = Yup.object({
  tipePengguna: Yup.string().required('Tipe Pengguna is a required field !'),
  fullname: Yup.string().required('Nama Lengkap is a required field !'),
  bornplace: Yup.string().required('Tempat Tanggal Lahir is a required field !'),
  numberId: Yup.string().required('NIK is a required field !'),
  religion: Yup.string().required('Agama is a required field !'),
  address: Yup.string().required('Alamat is a required field !'),
  kota: Yup.string().required('Kota is a required field !'),
  province: Yup.string().required('Provinsi is a required field !'),
  post_code: Yup.string().required('Kode Pos is a required field !'),
  telephone: Yup.string().required('No Telepon is a required field !'),
  rekening: Yup.string().required('Rekening is a required field !'),
  iduser: Yup.string().required('ID is a required field !'),
  password: Yup.string().min(8, 'Minimum 8 characters').required('The password is required'),
  tipeClient: Yup.string().required('Client is a required field !'),
  tipeCluster: Yup.string().required('Cluster is a required field !'),
  upload: Yup.string().required('Upload is a required field !'),
});

export default pengguna;