/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-sequences */
// nodejs library that concatenates classes
import classnames from 'classnames';

import '../../assets/css/main.css';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ModalLogout from 'components/Modal/Logout';
import { useEffect, useState } from 'react';
import { addDefaultSrc } from 'helpers/brokenImg';
import { socketClient } from '../../service/socket';
import differenceTime from 'helpers/differenceTime';
// import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { actionReadAllNotification, actionReadNotification } from '../../store/notification/actions';

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const data = JSON.parse(window.localStorage.getItem('userData'));
  const role = window.localStorage.getItem('role');
  const [LogoutModal, setLogoutModal] = useState(false);
  const [dataNotif, setDataNotif] = useState([]);
  const dispatch = useDispatch();

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function() {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function() {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };
  // function that on mobile devices makes the search close

  const toggleLogoutModal = () => {
    setLogoutModal(!LogoutModal);
  };

  const reDifferenceTime = () => {
    const newData = dataNotif.map(val => {
      return { ...val };
    });
    setDataNotif(newData);
  };

  const handleViewAll = () => {
    const body = {
      user_id: data?.user_id
    };

    dispatch(
        actionReadAllNotification(body, () => {})
    );
  };

  const handleRedirect = id => {
    const body = {
      notificationToUser_id: id
    };

    dispatch(
        actionReadNotification(body, () => {})
    );

  };

  useEffect(() => {
    socketClient.on('connect', () => {

      // send message with paylod user id
      socketClient.emit('msgToServer', { user_id: data?.user_id.toString() });

      // get notif by user id
      socketClient.on(data?.user_id.toString(), notif => {
        setDataNotif(notif);
      });
    });

    // // unsubscribe from event for preventing memory leaks
    return () => {
      socketClient.disconnect();
    };
  }, []);

  return (
    <>
      <ModalLogout
        toggle={ toggleLogoutModal }
        modal={ LogoutModal }
        // data= { client_id }
      />
      <Navbar className={ 'header-main navbar-top navbar-expand border-bottom' }>
        <Container fluid>
          <Collapse navbar isOpen={ true }>
            <Nav className='align-items-center ml-md-auto' navbar>
              <NavItem className='d-xl-none'>
                <div
                  className={ classnames(
                    'pr-3 sidenav-toggler',
                    { active: sidenavOpen },
                    { 'sidenav-toggler-dark': theme === 'dark' }
                  ) }
                  onClick={ toggleSidenav }
                >
                  <div className='sidenav-toggler-inner'>
                    <i className='sidenav-toggler-line' />
                    <i className='sidenav-toggler-line' />
                    <i className='sidenav-toggler-line' />
                  </div>
                </div>
              </NavItem>
              <NavItem className='d-sm-none'>
                <NavLink onClick={ openSearch }>
                  <i className='ni ni-zoom-split-in' />
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className='nav-link' color='' tag='a' onClick={ () => reDifferenceTime() }>
                  {
                    dataNotif.length !== 0 ? (
                        <>
                          <i className='ni ni-bell-55'/>
                        <span className='badge badge-danger badge-pill'>{ dataNotif.length }</span>

                        </>
                        )
                        :
                        <i className='ni ni-bell-55'/>
                  }
                </DropdownToggle>
                <DropdownMenu
                  className='dropdown-menu-xl py-0 overflow-hidden'
                  right
                >
                  <div className='px-3 py-3'>
                    <h6 className='text-sm text-muted m-0'>
                      You have <strong className='text-info'>{ dataNotif.length }</strong>{ ' ' }
                      notifications.
                    </h6>
                  </div>
                  <ListGroup flush style={ { height: '150px', overflow: 'scroll' } }>
                    { dataNotif.map((val, idx) => (
                        <ListGroupItem
                            className='list-group-item-action'
                            href='/superadmin/laporan'
                            onClick={ () => handleRedirect(val?.notificationToUser_id) }
                            tag='a'
                            key={ idx }
                        >
                          <Row className='align-items-center'>
                            <div className='col ml-2 '>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div className='text-right text-muted'>
                                  <small>{ differenceTime(val?.notification?.created_date) }</small>
                                </div>
                              </div>
                              <p className='text-sm mb-0'>
                                { val?.notification?.content ?? '' }
                              </p>
                            </div>
                          </Row>
                        </ListGroupItem>
                    )) }
                  </ListGroup>
                  <DropdownItem
                    className='text-center text-info font-weight-bold py-3'
                    href='/admin/notification'
                    // onClick={ () => history.push('/notification') }
                    onClick={ () => handleViewAll() }
                  >
                    View all
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className='align-items-center ml-auto ml-md-0' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='nav-link pr-0' color='' tag='a'>
                  <Media className='align-items-center'>
                    <span className='avatar avatar-sm rounded-circle'>
                      <img
                        onError={ e => addDefaultSrc(e) }
                        alt='profile'
                        src={
                          `${process.env.REACT_APP_BE_URL}/` + data.image_link
                        }
                      />
                    </span>
                    <Media className='ml-2 d-none d-lg-block'>
                      <span className='mb-0 text-sm font-weight-bold'>
                        { data.name }
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem onClick={ e => e.preventDefault() }>
                    <i className='ni ni-single-02' />
                    <Link
                      to={ `/${role.toLowerCase()}/profile` }
                      className='link-to'
                    >
                      <span href='/profile'>My profile</span>
                    </Link>
                  </DropdownItem>

                  { /* <DropdownItem>
                    <i className='ni ni-user-run' />
                    <button className='logouts'>
                      <span onClick={ () => toggleLogoutModal() } >Logout</span>
                    </button>
                  </DropdownItem> */ }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
