/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import { useFormik } from 'formik';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Label, Col, Row } from 'reactstrap';
import Select from 'react-select';
import API from 'service/api/config/index';

import { toast } from 'react-toastify';
import { validateProfiles } from 'validates';

function PindahLokasi(props) {
  const { data, setRender, reRender } = props;
  const [listClient, setListClient] = useState([]);
  const [listCluster, setListCluster] = useState([]);
  const [imgClient, setImgClient] = useState('');
  const [clientName, setCLientname] = useState('');
  const [clusterName, setClustername] = useState('');
  const [prev, setPrev] = useState(true);
  
  const formik = useFormik({
    initialValues: {
      tipeClient: '',
      tipeCluster: '',
      isNotChief: false,
    },
    validationSchema: validateProfiles.pindahLokasi,
    onSubmit: async values => {
      const body = {
        email: data.email,
        cluster_id: values.tipeCluster.toString(),
        client_id: values.tipeClient.toString(),
      };
      try {
        const response = await API.putMoveLocation(body);
        if (response.status === 200) {
          toast.success('Update Data Success !!');
          props.toggle();
          setRender();
          setPrev(true);
        }
      } catch (err) {
        toast.error('Fetch Error');
      }
    },
  });

  useEffect(async() => {
    if (Object.keys(data).length > 0) {
      if (data.role?.role_name !== 'CHIEF' && data.role?.role_name !== 'ADMIN') {
        await formik.setFieldValue('isNotChief', true);
      } else {
        formik.setFieldValue('isNotChief', false);
      }
    }
    getClient();
  }, [reRender, prev]);

  const getClient = useCallback(() => {
    API.getClient().then(res => {
      setListClient(res.data.data);
    });
  }, []);

  const getClusterPengguna = val => {
    API.getClientClusterName(val).then(res => {
      setListCluster(res.data.data);
    });
  };

  const optionsTypeClient = listClient.map(val => {
    return {
      value: val.client_id,
      label: val.client_name,
      img: val.image_link,
      client_name: val.client_name,
    };
  });

  const optionsTypeCluster = listCluster.map(val => {
    return {
      value: val.cluster_id,
      label: val.cluster_name,
      cluster_name: val.cluster_name,
    };
  });

  const handleSelectCluster = val => {
    setCLientname(val.client_name);
    setImgClient(val.img);
    formik.setFieldValue('tipeClient', val.value);
    getClusterPengguna(val.label);
  };
  
  const componentButton = () => {
    if (prev) {
      if (formik.values.isNotChief) {
        if (formik.values.tipeClient && formik.values.tipeCluster) {
          return (
            <button
              onClick={ () => setPrev(!prev) }
              type='button'
              className='btn btn-rounded'
              style={ { backgroundColor: '#6174DD', color: '#fff' } }
            >
              Selanjutnya
            </button>
          );
        } else {
          return (
            <button
              type='submit'
              className='btn btn-rounded'
              style={ { backgroundColor: '#6174DD', color: '#fff' } }
            >
              Selanjutnya
            </button>
          );
        }
      } else {
        if (!formik.values.tipeClient) {
          return (
            <button
              type='submit'
              className='btn btn-rounded'
              style={ { backgroundColor: '#6174DD', color: '#fff' } }
            >
              Selanjutnya
            </button>
          );
        } else {
          return (
            <button
              onClick={ () => setPrev(!prev) }
              type='button'
              className='btn btn-rounded'
              style={ { backgroundColor: '#6174DD', color: '#fff' } }
            >
              Selanjutnya
            </button>
          );
        }
      }
    } else {
      return null;
    }
  };

  return (
    <div>
      <Modal
        size='md'
        isOpen={ props.modal }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle }>
          <div
            className='d-flex justify-content-left'
            style={ { width: '160px' } }
          >
            <span style={ { fontSize: '18px', marginLeft: '2%' } }>
              Pindah Lokasi
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={ formik.handleSubmit }>
            <div className='wrap-body-content'>
              { prev ? (
                <Row>
                  <Col>
                    <Label>Client</Label>
                    <Select
                      options={ optionsTypeClient }
                      name='tipeClient'
                      onChange={ e => {
                        formik.setFieldValue('tipeClient', e.value);
                        handleSelectCluster(e);
                      } }
                    />
                    { formik.touched.tipeClient && formik.errors.tipeClient ? (
                      <div style={ { color: 'red' } }>
                        { formik.errors.tipeClient }
                      </div>
                    ) : null }
                  </Col>
                  { data?.role?.role_name !== 'CHIEF' &&
                  data?.role?.role_name !== 'ADMIN' ? (
                    <Col>
                      <Label>Cluster</Label>
                      <Select
                        options={ optionsTypeCluster }
                        name='tipeCluster'
                        onChange={ val => {
                          setClustername(val.cluster_name);
                          formik.setFieldValue('tipeCluster', val.value);
                        } }
                      />
                      { formik.touched.tipeCluster &&
                      formik.errors.tipeCluster ? (
                        <div style={ { color: 'red' } }>
                          { formik.errors.tipeCluster }
                        </div>
                      ) : null }
                    </Col>
                  ) : null }
                </Row>
              ) : (
                <Fragment>
                  <div className='text-center wrp-header-label'>
                    <span>Anda yakin ingin pindahkan lokasi?</span>
                  </div>
                  <Row>
                    <Col>
                      <div className='text-center'>
                        <div className='wrp-imge-pindah'>
                          <img
                            src={
                              process.env.REACT_APP_BE_URL +
                              '/' +
                              data?.client?.image_link
                            }
                            width={ 100 }
                            alt='imageChange1'
                          />
                        </div>
                        <span style={ { display: 'block' } }>
                          { data?.client ? data?.client?.client_name : '' }
                        </span>
                        <span>
                          { data?.client ? data?.cluster?.cluster_name : '' }
                        </span>
                      </div>
                    </Col>
                    <Col className='text-center'>
                      <div className='mt-5'>
                        <i className='fas fa-arrow-right' />
                      </div>
                    </Col>
                    <Col>
                      <div className='text-center'>
                        <div className='wrp-imge-pindah'>
                          <img
                            src={ process.env.REACT_APP_BE_URL + '/' + imgClient }
                            width={ 100 }
                            alt='imageChange1'
                          />
                        </div>
                        <span style={ { display: 'block' } }>{ clientName }</span>
                        <span>{ clusterName }</span>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) }

              <hr />
              <div className='wrp-btn-modals'>
                { !prev ? (
                  <div>
                    <button
                      onClick={ () => {
                        formik.resetForm();
                        setPrev(!prev);
                      } }
                      type='submit'
                      className='btn btn-back-modal btn-rounded'
                    >
                      Kembali
                    </button>
                    <button
                      style={ { backgroundColor: '#6174DD', color: '#fff' } }
                      type='submit'
                      className='btn btn-rounded'
                    >
                      Pindahkan
                    </button>
                  </div>
                ) : null }
              </div>
            </div>
            {
              componentButton()
            }
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PindahLokasi;
