/* eslint-disable no-undef */
import React, { useState } from "react";
import "./login.css";
import { Row, Col, Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import GardaLogo from "../../../../assets/img/brand/Logo.svg";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { actionSignin } from "store/auth/actions";
import { ButtonSpiner } from "components/Buttons/indes";

const LoginPages = (props) => {
  const history = useHistory();
  const [isLoading] = useState(false);
  const [showPassword] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.authReducer.loading);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      mobile: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("The email address is required"),
      password: Yup.string().required("The password is required"),
    }),
    onSubmit: async (values) => {
      const body = {
        email: values.email,
        password: values.password,
        mobile: false,
      };
      dispatch(
        actionSignin(body, (role) => {
          localStorage.setItem("role", role);
          const { from } = props.location.state || { from: { pathname: "/" } };
          history.push(from);
          window.location.reload();
        }),
      );
    },
  });

  return (
    <>
      <div>
        <ToastContainer />
        <Row className="containerlogin">
          <Col className={"txtLoginpages"} />
          <Col className={"ctnLoginpages"}>
            <div className={"wrapps"}>
              <div className="d-flex justify-content-center sign">
                <div className={"wrpPages"}>
                  <div className={"formUs"}>
                    <div style={{ marginTop: "20px" }}>
                      <center>
                        <img src={GardaLogo} alt="logomonev" />
                        <span className="tit-login-card">
                          D A S H B O A R D
                        </span>
                      </center>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <Input
                        name="email"
                        className="inputUname"
                        placeholder="email"
                        onChange={formik.handleChange}
                      />
                      <Col style={{ textAlign: "center" }}>
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </Col>

                      <Input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="inputUname"
                        placeholder="password"
                        onChange={formik.handleChange}
                      />
                      <Col style={{ textAlign: "center" }}>
                        {formik.touched.password && formik.errors.password ? (
                          <div style={{ color: "red", textAlign: "center" }}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </Col>
                      <div className={"wrap-forgot"}>
                        <span className={"forgotPass"}>
                          <Row>
                            <Col>
                              <span
                                style={{ color: "#000", fontSize: "14px" }}
                              />
                            </Col>
                            <Col>
                              <Link
                                className={"signUpStyle"}
                                to="/forgot-password"
                              >
                                lupa Password ?
                              </Link>
                            </Col>
                          </Row>
                        </span>
                      </div>
                      <div className="d-flex">
                        {loading ? (
                          <ButtonSpiner />
                        ) : (
                          <Button
                            className={"btnSubmit"}
                            color=""
                            type="submit"
                            disabled={isLoading}
                          >
                            LOGIN
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                  {/* <div className='divid'>*/}
                  {/*  belum memiliki akun?{ ' ' }*/}
                  {/*  <Link className={ 'signUpStyle' } to='/register'>*/}
                  {/*    { ' ' }*/}
                  {/*    Register.{ ' ' }*/}
                  {/*  </Link>*/}
                  {/* </div>*/}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginPages;
