/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import API from "service/api/config/index";
import { toast } from "react-toastify";
import ModalChangePassword from "./ModalChangePassword";
import { validateProfiles } from "validates";
import Section1 from "components/Molecules/Profile/Section1";
import Section2 from "components/Molecules/Profile/Section2";
import Section3 from "components/Molecules/Profile/Section3";
import Section4 from "components/Molecules/Profile/Section4";
import { addDefaultSrc } from "helpers/brokenImg";

function ProfilePages() {
  const id = localStorage.getItem("user_id");
  const [dataProfile, setDataProfile] = useState({});
  const [disable, setDisabled] = useState(true);
  const avatarRef = useRef();
  const [day, setDay] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const [years, setYears] = useState(new Date());
  const [reRender, setRerender] = useState(false);
  const [tempImages, setTempImages] = useState("");
  const [email, setEmail] = useState("");
  const [isChangePassword, setIsChangePassword] = useState(false);

  const role = localStorage.getItem("role");

  const formik = useFormik({
    initialValues: {
      fullname: "",
      gender: "",
      brith_place: "",
      day: "",
      month: "",
      years: "",
      nik: "",
      religion: "",
      address: "",
      city: "",
      province: "",
      code_pos: "",
      telphone: "",
      rekening: "",
      email: "",
      upload: "",
    },
    validationSchema: validateProfiles.profile,
    onSubmit: async (values) => {
      let year = moment(years).format("YYYY");
      let months = moment(month).format("MM");
      let days = moment(day).format("DD");
      let dates = year + "-" + months + "-" + days;

      const body = {
        name: values.fullname,
        email: values.email,
        gender: values.gender,
        birth_plc: values.brith_place,
        birth_dt: dates,
        nik: values.nik,
        agama: values.religion,
        phone: values.telphone,
        alamat: values.address,
        no_rekening: values.rekening,
        kota: values.city,
        provinsi: values.province,
        kode_pos: values.code_pos,
      };
      try {
        const response = await API.putProfiles(body);
        if (response.status === 200) {
          setRerender(!reRender);
          setDisabled(true);
          toast.success("Success data update !!");
        }
      } catch (e) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      }
    },
  });

  const changeAvatar = () => {
    let avatar_profile = avatarRef.current.files[0];
    if (avatar_profile !== undefined && avatar_profile !== tempImages) {
      setTempImages(avatar_profile);
    }
  };

  useEffect(() => {
    fetchGetProfile();
  }, [reRender]);

  const fetchGetProfile = useCallback(async () => {
    try {
      const response = await API.getUserProfileDetails(id);
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setDataProfile(response.data?.data[0]);
        }
        formik.setFieldValue("fullname", response.data.data[0].name);
        formik.setFieldValue("gender", response.data.data[0].gender);
        formik.setFieldValue("brith_place", response.data.data[0].birth_plc);
        formik.setFieldValue("nik", response.data.data[0].nik);
        formik.setFieldValue("religion", response.data.data[0].agama);
        formik.setFieldValue("address", response.data.data[0].alamat);
        formik.setFieldValue(
          "city",
          response.data.data[0].kota === "null"
            ? ""
            : response.data.data[0].kota,
        );
        formik.setFieldValue(
          "province",
          response.data.data[0].provinsi === "null"
            ? ""
            : response.data.data[0].provinsi,
        );
        formik.setFieldValue(
          "code_pos",
          response.data.data[0].kode_pos === "null"
            ? ""
            : response.data.data[0].kode_pos,
        );
        formik.setFieldValue("telphone", response.data.data[0].phone);
        formik.setFieldValue("rekening", response.data.data[0].no_rekening);
        formik.setFieldValue("email", response.data.data[0].email);
        if (response.data.data[0].birth_dt) {
          formik.setFieldValue("day", response.data.data[0].birth_dt);
          formik.setFieldValue("month", response.data.data[0].birth_dt);
          formik.setFieldValue("years", response.data.data[0].birth_dt);
          setDay(new Date(response.data.data[0].birth_dt));
          setMonth(new Date(response.data.data[0].birth_dt));
          setYears(new Date(response.data.data[0].birth_dt));
        }
        setEmail(response.data.data[0].email);
      }
    } catch (e) {
      if (e.status === 401) {
        toast.error(e.data.message);
      } else if (e.status === 500) {
        toast.error(e.data.message);
      } else if (e.status === 400) {
        toast.error(e.data.message);
      }
    }
  }, []);

  const uploadPhotoProfile = async (e) => {
    const formDataPhoto = new FormData();
    formDataPhoto.append("email", email);
    formDataPhoto.append("image_link", e.target.files[0]);
    try {
      const response = await API.putPhotoProfile(formDataPhoto);
      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error("upload fail !!");
    }
  };

  return (
    <>
      <SimpleHeader name="Form components" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="8">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Row>
                    <div className="px-2">Profile</div>
                    <div className="ml-2">
                      <button
                        onClick={() => setDisabled(!disable)}
                        type="button"
                        className="btn btn-edit-profile btn-rounded"
                      >
                        Edit
                      </button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <form onSubmit={formik.handleSubmit}>
                    <Section1 formik={formik} disable={disable} />
                    <hr />
                    <Section2
                      formik={formik}
                      disable={disable}
                      day={day}
                      setDay={setDay}
                      month={month}
                      setMonth={setMonth}
                      years={years}
                      setYears={setYears}
                    />
                    <hr />
                    <Section3 formik={formik} disable={disable} />
                    <hr />
                    <Section4 formik={formik} disable={disable} />
                    <Row>
                      {!disable ? (
                        <div className="mx-3">
                          <button
                            type="submit"
                            className="btn btn-edit-profile btn-rounded"
                          >
                            Save
                          </button>
                        </div>
                      ) : null}
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col>
            <Row>
              <Col lg="12" md="12" className="mb-lg-0 mb-4">
                <Card className="wrap-profile-card">
                  <div className="mx-auto white">
                    <img
                      src={
                        tempImages === ""
                          ? dataProfile?.image_link &&
                            `${process.env.REACT_APP_BE_URL}/` +
                              dataProfile?.image_link
                          : URL.createObjectURL(tempImages)
                      }
                      alt="avatar"
                      onError={(e) => addDefaultSrc(e)}
                      className="rounded-circle img-fluid"
                      height={100}
                      width={100}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      name="upload"
                      className="img-uploads"
                      onChange={(e) => {
                        formik.handleChange(e);
                        changeAvatar();
                        // onChangePhoto(e);
                        uploadPhotoProfile(e);
                      }}
                      ref={avatarRef}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className="text-center image-uploads"
                      onClick={() => avatarRef.current.click()}
                    >
                      <a href="#upload" role="button">
                        <i
                          className="fa fa-camera icon-cameras"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    {formik.touched.upload && formik.errors.upload ? (
                      <div style={{ color: "red" }}>{formik.errors.upload}</div>
                    ) : null}
                  </div>

                  <CardBody>
                    <h4 className="font-weight-bold txt-name-personal">
                      {dataProfile.name}
                    </h4>
                    <h4 className="txt-name-personal">
                      {dataProfile?.role?.role_name}
                    </h4>
                    <hr />
                    <div className="wrap-label-email-detail">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <span>{dataProfile.email}</span>
                    </div>
                    <div className="wrap-label-profile-detail">
                      <i className="fa fa-phone" aria-hidden="true" />
                      <span>{dataProfile.phone}</span>
                    </div>
                    {role === "CLIENT" ? (
                      <div className="wrap-label-place-detail">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        <span>{dataProfile?.client?.client_name ?? "-"}</span>
                      </div>
                    ) : (
                      <div className="wrap-label-place-detail">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        <span>{dataProfile.alamat}</span>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <div className="ml-2 d-flex">
                  <div className="mr-2">
                    <div
                      className="button-custome"
                      onClick={() => setIsChangePassword(!isChangePassword)}
                    >
                      Ubah Password
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalChangePassword
          toggle={() => setIsChangePassword(!isChangePassword)}
          modal={isChangePassword}
        />
      </Container>
    </>
  );
}

export default ProfilePages;
