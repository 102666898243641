/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
// import { toast } from 'react-toastify';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import IconSuccessCheck from 'assets/img/icons/Success_Checkbox.svg';
import IconFieldCheck from 'assets/img/icons/Field_Checkbox.svg';
import { addDefaultSrc } from 'helpers/brokenImg';
import { useSelector } from 'react-redux';

function ModalViewItemDaftarKehadiran(props) {
  const { toggle, isOpen, allKelengkapan } = props;
  const name = useSelector(state => state.clientReducer.name);

  return (
      <Modal
        size='sm'
        isOpen={ isOpen }
        toggle={ toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle }>
          <div
            className='d-flex justify-content-left'
            style={ { width: '180px' } }
          >
            <span style={ { fontSize: '18px', marginLeft: '2%' } }>
              Item Kelengkapan
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='my-4 mx-4'>
            <div className='my-5 box-name d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <div className='px-3'>
                    <img
                      src={ `${process.env.REACT_APP_BE_URL}/${allKelengkapan?.image_link}` }
                      alt='avatar'
                      onError={ e => addDefaultSrc(e) }
                      className='avatar avatar-md rounded-circle'
                      height={ 100 }
                      width={ 100 }
                    />
                </div>
                <div className='px-3'>
                  <p className='name'>{ name }</p>
                </div>
              </div>
            </div>
            <Row>
              <Col>
                {
                  Object.keys(allKelengkapan).map(key => {
                    if (key !== 'image_link' && key !== 'kelengkapan_id') {
                      let title = key.replace('_', ' ');
                      return (
                        <>
                          <div className=''>
                            <FormGroup check inline>
                              <div className='pr-2'>
                                <img
                                  src={ allKelengkapan[key] ? IconSuccessCheck : IconFieldCheck }
                                  alt='icon check' />
                              </div>
                              <Label check>{ title }</Label>
                            </FormGroup>
                          </div>
                        </>
                      );
                    }
                  })
                }
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
  );
}

export default ModalViewItemDaftarKehadiran;
