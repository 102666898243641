import { FORGOTE, FORGOTE_ERROR, FORGOTE_SUCCESS, LOGOUT, RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS, SIGNIN, SIGNIN_ERROR, SIGNIN_SUCCESS } from './actionTypes';

const initialState = {
  loading: false,
  btnLoading: false,
  loadingResetPassword: false,
  data: '',
  loadingForgote: false
};

const authReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case SIGNIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SIGNIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload
      };
      break;
    case SIGNIN_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT:
      state = {
        ...state,
        btnLoading: false,
      };
      break;
    case FORGOTE:
      state = {
        ...state,
        loadingForgote: true,
      };
      break;
    case FORGOTE_SUCCESS:
      state = {
        ...state,
        loadingForgote: false,
      };
      break;
    case FORGOTE_ERROR:
      state = {
        ...state,
        loadingForgote: false,
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        loadingResetPassword: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loadingResetPassword: false,
      };
      break;
    case RESET_PASSWORD_ERROR:
      state = {
        ...state,
        loadingResetPassword: false,
      };
      break;
      
  }

  return state;
};


export default authReducer;
