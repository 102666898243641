/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, Container, Row, Col } from "reactstrap";

import API from "service/api/config";
import { Link } from "react-router-dom";
import ModalSaveFormClient from "components/Molecules/client/SaveFormClient";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const detailProfile = (user_id) => {
  return (
    <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal",
        margin: "10px",
      }}
    >
      <button
        style={{
          backgroundColor: "transparent",
          outline: "none",
          border: "none",
        }}
      >
        <Link
          to={`profiles/${user_id}`}
          style={{ color: "#6174DD", fontWeight: "bold" }}
        >
          Lihat Profile
        </Link>
      </button>
    </div>
  );
};

function AdminPages() {
  const componentRef = React.useRef(null);
  const [modalTambahClient, setModalTambahClient] = React.useState(false);
  const [userData, setUserData] = useState([]);
  const { SearchBar } = Search;
  const toggleModalTambahClient = () => {
    setModalTambahClient(!modalTambahClient);
  };

  useEffect(() => {
    API.getUserDetail("ADMIN").then((res) => {
      setUserData(res?.data?.data);
    });
  }, []);

  return (
    <>
      <div className="wrap-text-label-head">
        <span className="txt-label-pages">Admin</span>
      </div>
      <ModalSaveFormClient
        toggle={toggleModalTambahClient}
        modal={modalTambahClient}
      />
      <div className="wrap-pages">
        <Container className="mt--6" fluid>
          <Card className="wrapp-table">
            <ToolkitProvider
              data={userData}
              keyField={userData}
              columns={[
                {
                  dataField: "name",
                  text: "NAMA",
                  sort: true,
                },
                {
                  dataField: "phone",
                  text: "NO TELEPON",
                  sort: true,
                },
                {
                  dataField: "client.client_name",
                  text: "CLIENT",
                  sort: true,
                },
                {
                  dataField: "user_id",
                  text: "",
                  sort: false,
                  formatter: (user_id) => detailProfile(user_id),
                  headerAttrs: { width: 50 },
                  attrs: { width: 50, class: "EditRow" },
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  <Container fluid>
                    <Row>
                      <Col xs={12} sm={5}>
                        <button className="btn-head-table mb-3">
                          <Link
                            to={"tambah-pengguna"}
                            style={{ color: "#fff", fontWeight: "bold" }}
                            className="txt-lbl-btn-head"
                          >
                            + Admin
                          </Link>
                        </button>
                      </Col>
                      <Col className="mb-3"></Col>
                      <Col className="mb-3">
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <SearchBar {...props.searchProps} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <BootstrapTable
                    ref={componentRef}
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                    id="react-bs-table"
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card>
          {/* </div>
            </Row> */}
        </Container>
      </div>
    </>
  );
}

export default AdminPages;
