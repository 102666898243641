import DashboardAdmin from "views/pages/dashboards/DashboardAdmin.js";
import ClientPages from "views/pages/client/index.js";
import LaporanPages from "views/pages/laporan";
import SecurityPages from "views/pages/security";
import Tambah from "views/pages/forms/Tambah";
import ProfilePages from "views/pages/profile";
import DetailClient from "views/pages/client/details";
import ProfileDetailPages from "views/pages/profile/detail/profiledetail";
import PageNotif from "views/pages/notif";

const routes = [
  {
    path: "/dashboard",
    icon: "ni ni-tv-2 text-white",
    name: "Dashboard",
    miniName: "D",
    component: DashboardAdmin,
    layout: "/admin",
    state: "dashboardsCollapse",
    show: true,
  },
  {
    path: "/laporan",
    icon: "ni ni-single-copy-04 text-white",
    name: "Laporan",
    miniName: "C",
    component: LaporanPages,
    layout: "/admin",
    state: "componentsCollapse",
    show: true,
  },
  {
    path: "/client",
    icon: "ni ni-briefcase-24 text-white",
    name: "Client",
    miniName: "T",
    component: ClientPages,
    layout: "/admin",
    state: "examplesCollapse",
    show: true,
  },
  {
    path: "/security",
    icon: "ni ni-badge text-white",
    name: "Security",
    miniName: "V",
    component: SecurityPages,
    layout: "/admin",
    state: "formsCollapse",
    show: true,
  },
  // {
  //   path: "/tambah-pengguna",
  //   icon: "ni ni-fat-add text-white",
  //   name: "Tambah Pengguna",
  //   miniName: "TBH",
  //   component: Tambah,
  //   layout: "/admin",
  //   state: "formsCollapse",
  //   show: true,
  // },
  {
    path: "/profile",
    component: ProfilePages,
    name: "",
    layout: "/admin",
    show: false,
  },
  {
    path: "/detail-client-:id",
    component: DetailClient,
    name: "",
    layout: "/admin",
    show: false,
  },
  {
    path: "/profiles/:id",
    component: ProfileDetailPages,
    name: "",
    layout: "/admin",
    show: false,
  },
  {
    path: "/notification",
    component: PageNotif,
    name: "",
    layout: "/admin",
    show: false,
  },
];

export default routes;
