/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import IconFieldCheck from '../../../../assets/img/icons/Field_Checkbox.svg';

export default function Section4(props) {
  const { formik, onChangePhoto, photoRef, resetPhoto } = props;

  return (
    <Fragment>
      <div className='wrap-form'>
        <Label>Email</Label>
        <Input
          placeholder=''
          type='text'
          name='email'
          onChange={ formik.handleChange }
        />
        { formik.touched.email && formik.errors.email ? (
          <div style={ { color: 'red' } }>{ formik.errors.email }</div>
        ) : null }
      </div>
      <div className='wrap-form'>
        <Label>Password</Label>
        <Input
          placeholder=''
          type='password'
          name='password'
          onChange={ formik.handleChange }
        />
        { formik.touched.password && formik.errors.password ? (
          <div style={ { color: 'red' } }>{ formik.errors.password }</div>
        ) : null }
      </div>
      <div className=''>

        <Label className='tit-upload'>Photo Profile</Label>
        <div className='d-flex'>
          <div>
              <input
                name='upload'
                className='upload-doc'
                type='file'
                accept='image/*'
                onChange={ e => {
                  formik.handleChange(e);
                  onChangePhoto(e);
                } }
                ref={ photoRef }
              />
          </div>
          <div className='px-3'>
              <img
                  style={ { cursor: 'pointer' } }
                  src={  IconFieldCheck }
                  alt='icon check'
                  onClick={ () => resetPhoto() }
              />
          </div>
        </div>
        { formik.touched.upload && formik.errors.upload ? (
          <div style={ { color: 'red' } }>{ formik.errors.upload }</div>
        ) : null }
      </div>
    </Fragment>
  );
}
