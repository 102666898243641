/* eslint-disable no-undef */
import React from 'react';
// import { toast } from 'react-toastify';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import IconSuccessCheck from 'assets/img/icons/Success_Checkbox.svg';
import IconFieldCheck from 'assets/img/icons/Field_Checkbox.svg';

function ModalViewItem(props) {
  const dataProps = props.data;
  
  return (
    <div>
      <Modal
        size='sm'
        isOpen={ props.modal }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle }>
          <div
            className='d-flex justify-content-left'
            style={ { width: '180px' } }
          >
            <span style={ { fontSize: '18px', marginLeft: '2%' } }>
              Item Kelengkapan
            </span>
          </div>
        </ModalHeader>
        <ModalBody>x
          <div className='my-4 mx-4'>
            <Row>
              <Col>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.seragam_pdh_pdl ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Seragam PDH/PDL</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.sepatu_pdh_pdl ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Sepatu PDH/PDL</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.topi ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Topi</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.talikur ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Talikur</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.peluit ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Peluit</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.sabuk ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Sabuk</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                   <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.sabuk_ringkolep ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Sabuk Ringkopel</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.kaos_dalam ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Kaos Dalam Security</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.borgol ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Borgol</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.knoper ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Knoper</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.id_card ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>ID Card</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.name_tag ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Name Tag</Label>
                  </FormGroup>
                </div>
                <div className=''>
                  <FormGroup check inline>
                    <div className='pr-2'>
                      <img
                        src={ dataProps?.kelengkapan?.rompi_lalin ? IconSuccessCheck : IconFieldCheck }
                        alt='icon check' />
                    </div>
                    <Label check>Rompi Lalin</Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalViewItem;
