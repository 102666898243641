/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";

export default function Section2(props) {
  const {
    formik,
    disable,
    day,
    setDay,
    month,
    setMonth,
    years,
    setYears,
    typeRole,
  } = props;

  return (
    <Fragment>
      <div className="wrap-form">
        <Label>No. Telepon</Label>
        <Input
          name="telephone"
          type="number"
          value={formik.values.telephone}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.telephone && formik.errors.telephone ? (
          <div style={{ color: "red" }}>{formik.errors.telephone}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>KTA</Label>
        <Input
          name="no_kta"
          type="text"
          value={formik.values.no_kta}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.no_kta && formik.errors.no_kta ? (
          <div style={{ color: "red" }}>{formik.errors.no_kta}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>No BPJS</Label>
        <Input
          name="no_bpjs"
          type="text"
          value={formik.values.no_bpjs}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.no_bpjs && formik.errors.no_bpjs ? (
          <div style={{ color: "red" }}>{formik.errors.no_bpjs}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>No BPJS Kesehatan</Label>
        <Input
          name="no_bpjs_kesehatan"
          type="text"
          value={formik.values.no_bpjs_kesehatan}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.no_bpjs_kesehatan && formik.errors.no_bpjs_kesehatan ? (
          <div style={{ color: "red" }}>{formik.errors.no_bpjs_kesehatan}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>No NPWP</Label>
        <Input
          name="no_npwp"
          type="text"
          value={formik.values.no_npwp}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.no_npwp && formik.errors.no_npwp ? (
          <div style={{ color: "red" }}>{formik.errors.no_npwp}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>Pendidikan Terakhir</Label>
        <Input
          name="pendidikan_terakhir"
          type="text"
          value={formik.values.pendidikan_terakhir}
          disabled={disable}
          onChange={formik.handleChange}
        />
        {formik.touched.pendidikan_terakhir &&
        formik.errors.pendidikan_terakhir ? (
          <div style={{ color: "red" }}>
            {formik.errors.pendidikan_terakhir}
          </div>
        ) : null}
      </div>

      {typeRole !== "PENGHUNI" && typeRole !== "CLIENT" ? (
        <div>
          <div className="wrap-form">
            <Label>Tempat Tanggal Lahir</Label>
            <Input
              name="brith_place"
              type="text"
              value={formik.values.brith_place}
              disabled={disable}
              onChange={formik.handleChange}
            />
            {formik.touched.brith_place && formik.errors.brith_place ? (
              <div style={{ color: "red" }}>{formik.errors.brith_place}</div>
            ) : null}
          </div>
          <Row>
            <Col>
              <div className="">
                <Label>Hari</Label>
                <DatePicker
                  name="day"
                  selected={day}
                  onChange={(date) => {
                    setDay(date);
                    formik.setFieldValue("day", date);
                  }}
                  dateFormat="dd"
                  showDayDropdown
                  disabled={disable}
                  className="input-date-picker-customs"
                  placeholderText="Hari"
                />
                {formik.touched.day && formik.errors.day ? (
                  <div style={{ color: "red" }}>{formik.errors.day}</div>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="">
                <Label>Bulan</Label>
                <DatePicker
                  name="month"
                  selected={month}
                  onChange={(date) => {
                    setMonth(date);
                    formik.setFieldValue("month", date);
                  }}
                  dateFormat="MM"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showFourColumnMonthYearPicker
                  disabled={disable}
                  className="input-date-picker-customs"
                />
                {formik.touched.month && formik.errors.month ? (
                  <div style={{ color: "red" }}>{formik.errors.month}</div>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="">
                <Label>Tahun</Label>
                <DatePicker
                  name="years"
                  selected={years}
                  onChange={(date) => {
                    setYears(date);
                    formik.setFieldValue("years", date);
                  }}
                  showYearPicker
                  dateFormat="yyyy"
                  disabled={disable}
                  className="input-date-picker-customs"
                />
                {formik.touched.years && formik.errors.years ? (
                  <div style={{ color: "red" }}>{formik.errors.years}</div>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </Fragment>
  );
}
