import * as Yup from 'yup';

const pindahLokasi = Yup.object({
  tipeClient: Yup.string().required('Client harus diisi'),
  tipeCluster: Yup.mixed()
                  .when('isNotChief', {
                    is: true,
                    then: Yup.mixed()
                    .required('Cluster harus diisi')
                  }),
});

const profile = Yup.object({
  fullname: Yup.string().required('Nama Lengkap harus diisi'),
  gender: Yup.string().required('Jenis Kelamin Lengkap harus diisi'),
  telphone: Yup.string().required('No Telepon harus diisi'),
  email: Yup.string().required('Email harus diisi'),
  brith_place: Yup.string().required('Tempat Tanggal Lahir harus diisi'),
  day: Yup.string().required('Hari harus diisi'),
  month: Yup.string().required('Bulan harus diisi'),
  years: Yup.string().required('Tahun harus diisi'),
  nik: Yup.string().required('NIK harus diisi'),
  religion: Yup.string().required('Agama harus diisi'),
  address: Yup.string().required('Alamat harus diisi'),
  // rekening: Yup.string().required('Required'),
});

const profileDetail = Yup.object({
  fullname: Yup.string().required('Nama Lengkap harus diisi'),
  gender: Yup.string().required('Jenis Kelamin Lengkap harus diisi'),
  telephone: Yup.string().required('No Telepon harus diisi'),
  email: Yup.string().required('Email harus diisi'),
  brith_place: Yup.string().required('Tempat Tanggal Lahir harus diisi'),
  day: Yup.string().required('Hari harus diisi'),
  month: Yup.string().required('Bulan harus diisi'),
  years: Yup.string().required('Tahun harus diisi'),
  nik: Yup.string().required('NIK harus diisi'),
  religion: Yup.string().required('Agama harus diisi'),
  address: Yup.string().required('Alamat harus diisi'),
  city: Yup.string().required('Kota harus diisi'),
  province: Yup.string().required('Provinsi harus diisi'),
  post_code: Yup.string().required('Kode Pos harus diisi'),
  no_kta: Yup.string().test('Silahkan masukan angka', (value) => {
    let a = String(value).replaceAll('.', '');
    return !isNaN(Number(a));
  }).required('No KTA harus diisi'),
});

const profileDetailClient = Yup.object({
  fullname: Yup.string().required('Nama Lengkap harus diisi'),
  telephone: Yup.string().required('No Telepon harus diisi'),
  email: Yup.string().required('Email harus diisi'),
});

const profileDetailPenghuni = Yup.object({
  fullname: Yup.string().required('Nama Lengkap harus diisi'),
  telephone: Yup.string().required('No Telepon harus diisi'),
  email: Yup.string().required('Email harus diisi'),
  address: Yup.string().required('Alamat harus diisi'),
});

export  {
  pindahLokasi,
  profile,
  profileDetail,
  profileDetailClient,
  profileDetailPenghuni
};