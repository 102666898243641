import {
  ADD_CLUSTER,
  SAVE_DATA_CLUSTER,
  ADD_MARKER,
  REMOVE_MARKER,
  EDIT_CLUSTER,
  CANCEL_MAP_CLUSTER,
  UPDATE_CLUSTER, DELETE_CLUSTER, GET_DETAIL_CLUSTER
} from './actionTypes';

const initialState = {
  loading: false,
  list_cluster: [],
  newMarker: [],
  markers: [],
  filteredData: [],
  detail: {}
};

const clusterReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case SAVE_DATA_CLUSTER:
      const markers = action.payload.map(val => [
        val.longitude,
        val.latitude,
      ]);

      state = {
        ...state,
        list_cluster: action.payload,
        markers: markers
      };
      break;
    case ADD_CLUSTER:
      state = {
        ...state,
        newMarker: []
      };
      break;
    case UPDATE_CLUSTER:
      state = {
        ...state,
        markers: state.list_cluster.map(val => [
          val.longitude,
          val.latitude,
        ]),
        filteredData: [],
        newMarker: []
      };
      break;
    case DELETE_CLUSTER:
      state = {
        ...state,
      };
      break;
    case ADD_MARKER:
      state = {
        ...state,
        newMarker: action.payload.marker
      };
      break;
    case REMOVE_MARKER:
      state = {
        ...state,
        newMarker: []
      };
      break;
    case EDIT_CLUSTER:
      const filterArr = state.list_cluster.filter(val => val.cluster_id !== action.payload.cluster_id);
      const editMarkers = filterArr.map(val => [
        val.longitude,
        val.latitude,
      ]);

      state = {
        ...state,
        filteredData: filterArr,
        detail: action.payload,
        markers: editMarkers,
        newMarker: [action.payload.longitude, action.payload.latitude]
      };
      break;
    case GET_DETAIL_CLUSTER:
      state = {
        ...state,
        detail: action.payload,
      };
      break;
    case CANCEL_MAP_CLUSTER:
      const dataMarker = state.list_cluster.map(val => [
        val.longitude,
        val.latitude,
      ]);

      state = {
        ...state,
        markers: dataMarker,
        filteredData: [],
        newMarker: [],
        detail: {}
      };
      break;

  }

  return state;
};


export default clusterReducer;
