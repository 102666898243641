import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateKeteranganAbsensi } from '../../store/client/actions';

function EditKeterangan(props) {
  const { modal, toggle, setReRender } = props;
  const dispatch = useDispatch();
  const detail_absensi = useSelector(state => state.clientReducer.detail);
  const formik = useFormik({
    initialValues: {
      keterangan: '',
    },
    onSubmit: values => {
      const body = {
        absensi_id: detail_absensi?.absensi_id,
        keterangan: values.keterangan
      };

      !modal;
      dispatch(actionUpdateKeteranganAbsensi(body, () => {
        setReRender();
        toggle();
      }));
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue('keterangan', detail_absensi?.desc_absensi);

  }, [modal]);


  return (
    <div>
      <Modal
        size='md'
        isOpen={ modal }
        toggle={ toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle }>
          <div
            className='d-flex justify-content-left'
            style={ { width: '160px' } }
          >
            <span style={ { fontSize: '18px', marginLeft: '2%' } }>
              Edit Keterangan
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={ formik.handleSubmit }>
            <div className='wrap-form'>
              <Label>Keterangan</Label>
              <Input
                type='textarea'
                name='keterangan'
                id='keterangan'
                onChange={ formik.handleChange }
                value={ formik.values.keterangan }
              />
            </div>
            <div className='wrap-btn-modal-footer'>
              <button type='submit' className='btn btn-modal btn-rounded'>
                Simpan
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default EditKeterangan;
