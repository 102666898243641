/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import { useEffect, useState, useRef } from "react";
import { Card, CardHeader, Container, Media, Row, Col } from "reactstrap";
import API from "service/api/config";
import { Link } from "react-router-dom";
import ModalSaveFormClient from "components/Molecules/client/SaveFormClient";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { addDefaultSrc } from "helpers/brokenImg";
import IconDocument from "assets/img/fluent_document.svg";
import Paginations from "components/Tabels/Paginations";
import { ROLE_SUPER_ADMIN } from "helpers/types";

function PicPages(props) {
  const [userDataClient, setUserDataClient] = useState([]);
  const componentRef = useRef(null);
  const [clientData, setClientData] = useState({});
  const [reRender, setRerender] = useState(false);
  const [modalSaveFormClient, setModalSaveFormClient] = useState(false);
  const { SearchBar } = Search;

  const toggleModalSaveFormClient = () => {
    setModalSaveFormClient(!modalSaveFormClient);
  };

  const handleTambahClient = () => {
    toggleModalSaveFormClient();
    setClientData({});
  };

  const handleEditClient = (props, val) => {
    toggleModalSaveFormClient();
    setClientData(val);
  };

  const role = localStorage.getItem("role");
  const data = JSON.parse(localStorage.getItem("userData"));
  const clientID = data?.client?.client_id;

  useEffect(() => {
    if (role === "CLIENT") {
      API.getClientParam(clientID).then((res) => {
        setUserDataClient(res?.data?.data);
      });
    } else {
      API.getClient().then((res) => {
        setUserDataClient(res?.data?.data);
      });
    }
  }, [role, clientID, reRender]);

  return (
    <>
      <div className="wrap-text-label-head">
        <span className="txt-label-pages">Client</span>
      </div>
      <ModalSaveFormClient
        toggle={toggleModalSaveFormClient}
        modal={modalSaveFormClient}
        data={clientData}
        setRerender={() => setRerender(true)}
        setClientData={setClientData}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                search
                data={userDataClient}
                keyField="client_index"
                columns={[
                  {
                    dataField: "image_link",
                    text: "PERUSAHAAN",
                    sort: false,
                    formatter: (cell, row) => {
                      return (
                        <Media className="align-items-center">
                          <div
                            className="avatar "
                            style={{
                              backgroundColor: "transparent",
                              height: "50px",
                              marginRight: "20px",
                            }}
                          >
                            <img
                              onError={(e) => addDefaultSrc(e)}
                              alt="img"
                              src={`${process.env.REACT_APP_BE_URL}/` + cell}
                              className="img-thumbnail"
                            />
                          </div>
                          <Media>
                            <span className="name mb-0 text-sm">
                              {row.client_name}
                            </span>
                          </Media>
                        </Media>
                      );
                    },
                  },
                  {
                    dataField: "client_email",
                    text: "EMAIL",
                    sort: false,
                  },
                  {
                    dataField: "client_phone",
                    text: "NO TELEPON",
                    sort: false,
                  },
                  {
                    dataField: "doc_penunjang",
                    text: "DOCUMENT",
                    sort: false,
                    formatter: (cell) => {
                      return (
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_BE_URL}/${cell}`}
                          style={{ cursor: "pointer" }}
                          rel="noreferrer"
                        >
                          <img
                            src={IconDocument}
                            alt="img document"
                            onError={addDefaultSrc}
                          />
                        </a>
                      );
                    },
                  },
                  {
                    dataField: "client_id",
                    text: "",
                    sort: false,
                    formatter: (cell, row) => {
                      return (
                        <Row>
                          <div
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              lineHeight: "normal",
                            }}
                          >
                            <button
                              style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none",
                              }}
                            >
                              {role !== "ADMIN" && (
                                <span
                                  style={{
                                    color: "#9B9FB7",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => handleEditClient(props, row)}
                                >
                                  Edit
                                </span>
                              )}
                            </button>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              lineHeight: "normal",
                            }}
                          >
                            <button
                              style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none",
                              }}
                            >
                              <Link
                                to={"detail-client-" + cell}
                                style={{ color: "#6174DD", fontWeight: "bold" }}
                              >
                                Detail
                              </Link>
                            </button>
                          </div>
                        </Row>
                      );
                    },
                  },
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <CardHeader className="border-0">
                      <Row>
                        <Col>
                          <Row>
                            <div className="px-3">Client</div>
                            {role === ROLE_SUPER_ADMIN ? (
                              <div>
                                {role !== "ADMIN" && (
                                  <button
                                    className="btn-head-table"
                                    onClick={() => handleTambahClient()}
                                  >
                                    + Client
                                  </button>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        <Col></Col>
                        <Col>
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <SearchBar {...props.searchProps} />
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={Paginations}
                        bordered={false}
                        id="react-bs-table"
                      />
                    </CardHeader>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PicPages;
