/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

export default function Section4(props) {
  const { formik, disable, typeRole } = props;
  return (
    <Fragment>
      {
        typeRole !== 'CLIENT' ? (
            <div className='wrap-form'>
              <Label>Alamat</Label>
              <Input
                name='address'
                type='text'
                value={ formik.values.address }
                disabled={ disable }
                onChange={ formik.handleChange }
              />
              { formik.touched.address && formik.errors.address ? (
                <div style={ { color: 'red' } }>{ formik.errors.address }</div>
              ) : null }
            </div>
        ) : null
      }
      {
        typeRole !== 'PENGHUNI' && typeRole !== 'CLIENT' ? (
          <Row>
              <Col>
                <div className='wrap-form'>
                  <Label>Kota</Label>
                  <Input
                    name='city'
                    type='text'
                    value={ formik.values.city }
                    disabled={ disable }
                    onChange={ formik.handleChange }
                  />
                  { formik.touched.city && formik.errors.city ? (
                    <div style={ { color: 'red' } }>{ formik.errors.city }</div>
                  ) : null }
                </div>
              </Col>
              <Col>
                <div className='wrap-form'>
                  <Label>Provinsi</Label>
                  <Input
                    name='province'
                    type='text'
                    value={ formik.values.province }
                    disabled={ disable }
                    onChange={ formik.handleChange }
                  />
                  { formik.touched.province && formik.errors.addprovinceress ? (
                    <div style={ { color: 'red' } }>{ formik.errors.province }</div>
                  ) : null }
                </div>
              </Col>
              <Col>
                <div className='wrap-form'>
                  <Label>Kode Pos</Label>
                  <Input
                    name='post_code'
                    type='number'
                    value={ formik.values.post_code }
                    disabled={ disable }
                    onChange={ formik.handleChange }
                  />
                  { formik.touched.post_code && formik.errors.post_code ? (
                    <div style={ { color: 'red' } }>{ formik.errors.post_code }</div>
                  ) : null }
                </div>
              </Col>
            </Row>
        ) : null
      }
    </Fragment>
  );
}
