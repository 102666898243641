import {
  DELETE_SECURITY,
  GET_DETAIL_SECURITY,
  SAVE_DATA_PAYROLL
} from './actionTypes';

const initialState = {
  loading: false,
  payroll: [],
  detail: {}
};

const securityReducer = (state = initialState,  action: { type: any; payload: any }) => {
  switch (action.type) {
    case SAVE_DATA_PAYROLL:
      state = {
        ...state,
        payroll: action.payload
      };
      break;
    case GET_DETAIL_SECURITY:
      state = {
        ...state,
        detail: action.payload
      };
      break;
    case DELETE_SECURITY:
      state = {
        ...state,
        detail: {}
      };
      break;
  }

  return state;
};


export default securityReducer;
