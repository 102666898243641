// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  // Breadcrumb,
  // BreadcrumbItem,
  // Button,
  Container,
  Row,
  // Col,
} from 'reactstrap';

function TimelineHeader() {
  return (
    <>
      <div className='header header-dark pb-2 content__title content__title--calendar'>
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-4' />
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
