export const ADD_MARKER = 'ADD_MARKER';
export const REMOVE_MARKER = 'REMOVE_MARKER';
export const GET_DETAIL = 'GET_DETAIL';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const DELETE_PATROL_POINT = 'DELETE_PATROL_POINT';
export const SAVE_PATROL_POINT = 'SAVE_PATROL_POINT';
export const REGENERATE_QR_CODE = 'REGENERATE_QR_CODE';
export const EDIT_PATROL_POINT = 'EDIT_PATROL_POINT';
export const CANCEL_EDIT_PATROL_POINT = 'CANCEL_EDIT_PATROL_POINT';
export const UPDATE_PATROL_POINT = 'UPDATE_PATROL_POINT';

