import React, { Fragment } from "react";
import { Col, Input, Label, Row } from "reactstrap";

export default function Section1(props) {
  const { formik } = props;
  return (
    <Fragment>
      <div className="wrap-form">
        <Label>Nama Lengkap</Label>
        <Input
          placeholder="Your Name"
          type="text"
          name="fullname"
          id="fullname"
          onChange={formik.handleChange}
        />
        {formik.touched.fullname && formik.errors.fullname ? (
          <div style={{ color: "red" }}>{formik.errors.fullname}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>KTA</Label>
        <Input
          placeholder="KTA"
          type="text"
          name="no_kta"
          id="no_kta"
          onChange={formik.handleChange}
        />
        {formik.touched.no_kta && formik.errors.no_kta ? (
          <div style={{ color: "red" }}>{formik.errors.no_kta}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>No BPJS</Label>
        <Input
          placeholder="No BPJS"
          type="text"
          name="no_bpjs"
          id="no_bpjs"
          onChange={formik.handleChange}
        />
        {formik.touched.no_bpjs && formik.errors.no_bpjs ? (
          <div style={{ color: "red" }}>{formik.errors.no_bpjs}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>No BPJS Kesehatan</Label>
        <Input
          placeholder="No BPJS Kesehatan"
          type="text"
          name="no_bpjs_kesehatan"
          id="no_bpjs_kesehatan"
          onChange={formik.handleChange}
        />
        {formik.touched.no_bpjs_kesehatan && formik.errors.no_bpjs_kesehatan ? (
          <div style={{ color: "red" }}>{formik.errors.no_bpjs_kesehatan}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>NPWP</Label>
        <Input
          placeholder="NPWP"
          type="text"
          name="no_npwp"
          id="no_npwp"
          onChange={formik.handleChange}
        />
        {formik.touched.no_npwp && formik.errors.no_npwp ? (
          <div style={{ color: "red" }}>{formik.errors.no_npwp}</div>
        ) : null}
      </div>
      <div className="wrap-form">
        <Label>Pendidikan Terakhir</Label>
        <Input
          placeholder="Pendidikan Terakhir"
          type="text"
          name="pendidikan_terakhir"
          id="pendidikan_terakhir"
          onChange={formik.handleChange}
        />
        {formik.touched.pendidikan_terakhir &&
        formik.errors.pendidikan_terakhir ? (
          <div style={{ color: "red" }}>
            {formik.errors.pendidikan_terakhir}
          </div>
        ) : null}
      </div>
      <div className="mb-2">Jenis Kelamin</div>
      <div className="wrap-rad-btn">
        <Row>
          <Col md={6}>
            <input
              className="input-rad-btn"
              type="radio"
              name="gender"
              value="Pria"
              onChange={formik.handleChange}
            />
            <span className="txt-rad-btn">Pria</span>
          </Col>
          <Col md={6}>
            <input
              className="input-rad-btn"
              type="radio"
              name="gender"
              value="Wanita"
              onChange={formik.handleChange}
            />
            <span className="txt-rad-btn">Wanita</span>
          </Col>
        </Row>
        {formik.touched.gender && formik.errors.gender ? (
          <div style={{ color: "red" }}>{formik.errors.gender}</div>
        ) : null}
      </div>
    </Fragment>
  );
}
