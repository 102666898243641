/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

export default function Section3(props) {
  const {
    formik,
    disable,
    typeRole,
    day,
    setDay,
    month,
    setMonth,
    years,
    setYears,
  } = props;

  return (
    <Fragment>
      { typeRole !== 'PENGHUNI' && typeRole !== 'CLIENT' ? (
        <div>
          <div className='wrap-form'>
            <Label>NIK</Label>
            <Input
              name='nik'
              type='number'
              value={ formik.values.nik }
              disabled={ disable }
              onChange={ formik.handleChange }
              className='input-date-picker-customs'
            />
            { formik.touched.nik && formik.errors.nik ? (
              <div style={ { color: 'red' } }>{ formik.errors.nik }</div>
            ) : null }
          </div>
          <Row>
            <Col>
              <div className='wrap-form'>
                <Label>Agama</Label>
                <Input
                  name='religion'
                  type='text'
                  value={ formik.values.religion }
                  disabled={ disable }
                  onChange={ formik.handleChange }
                />
                { formik.touched.religion && formik.errors.religion ? (
                  <div style={ { color: 'red' } }>{ formik.errors.religion }</div>
                ) : null }
              </div>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <div className='wrap-form'>
                <Label>Nomer Rekening</Label>
                <Input
                  name='no_rekening'
                  type='text'
                  value={ formik.values.no_rekening }
                  disabled={ disable }
                  onChange={ formik.handleChange }
                />
                { formik.touched.no_rekening && formik.errors.no_rekening ? (
                  <div style={ { color: 'red' } }>{ formik.errors.no_rekening }</div>
                ) : null }
              </div>
            </Col>
            <Col />
          </Row>
          <Label>Tanggal Bergabung</Label>
          <Row>
            <Col>
              <div className=''>
                <Label>Hari</Label>
                <DatePicker
                  name='day_join'
                  selected={ day }
                  onChange={ date => {
                    setDay(date);
                    formik.setFieldValue('day_join', date);
                  } }
                  dateFormat='dd'
                  showDayDropdown
                  disabled={ disable }
                  className='input-date-picker-customs'
                  placeholderText='Hari'
                />
                { formik.touched.day_join && formik.errors.day_join ? (
                  <div style={ { color: 'red' } }>{ formik.errors.day_join }</div>
                ) : null }
              </div>
            </Col>
            <Col>
              <div className=''>
                <Label>Bulan</Label>
                <DatePicker
                  name='month_join'
                  selected={ month }
                  onChange={ date => {
                    setMonth(date);
                    formik.setFieldValue('month_join', date);
                  } }
                  dateFormat='MM'
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showFourColumnMonthYearPicker
                  disabled={ disable }
                  className='input-date-picker-customs'
                />
                { formik.touched.month_join && formik.errors.month_join ? (
                  <div style={ { color: 'red' } }>{ formik.errors.month_join }</div>
                ) : null }
              </div>
            </Col>
            <Col>
              <div className=''>
                <Label>Tahun</Label>
                <DatePicker
                  name='years_join'
                  selected={ years }
                  onChange={ date => {
                    setYears(date);
                    formik.setFieldValue('years_join', date);
                  } }
                  showYearPicker
                  dateFormat='yyyy'
                  disabled={ disable }
                  className='input-date-picker-customs'
                />
                { formik.touched.years_join && formik.errors.years_join ? (
                  <div style={ { color: 'red' } }>{ formik.errors.years_join }</div>
                ) : null }
              </div>
            </Col>
          </Row>
        </div>
      ) : null }
    </Fragment>
  );
}
