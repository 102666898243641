/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import DetailTitikPatroli from './DetailTitikPatroli';
import API from '../../../../service/api/config';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { actionDetail } from '../../../../store/maps/actions';
import { ROLE_ADMIN } from 'helpers/types';
import { ROLE_SUPER_ADMIN } from 'helpers/types';

export default function TitikPatroli() {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [dataPointPatrol, setDataPointPatrol] = useState([]);
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const client_name = useSelector(state => state.laporanReducer.name_client);
  let role = userData.role.role_name;
  const dataTitikPatroli = useSelector(state => state.laporanReducer.titik_patroli);
  const [detailCluster, setDetailCluster] = useState({});
  
  // Detail Button Component
  const detailHandleEvaluasi = (value, row) => {
    const getDetail = () => {
      setDetailCluster(row);
      dispatch(
        actionDetail(row.cluster_id, () => {
          setIsModal(!isModal);
        })
      );
    };

    return (
      <div
        style={ { textAlign: 'center', cursor: 'pointer', lineHeight: 'normal' } }
      >
        <button
          style={ {
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
          } }
          onClick={ getDetail }
        >
          <span style={ { color: '#6174DD', fontWeight: 'bold' } }>Detail</span>
        </button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'cluster_name',
      text: 'Cluster',
    },
    {
      dataField: 'titik_terdaftar',
      text: 'Titik Terdaftar',
    },
    {
      dataField: '',
      text: '',
      sort: false,
      formatter: detailHandleEvaluasi,
      headerAttrs: { width: 50 },
      attrs: { width: 150, class: 'EditRow' },
    },
  ];

  const getClientCluster = useCallback(async() => {
    try {
      var response;
      if (userData.role.role_name !== 'SUPERADMIN') {
        response = await API.getClientCluster(
          userData.client?.client_name,
          true
        );
      } else {
        response = await API.getClientCluster(
          client_name,
          true
        );
      }
      if (response.status < 299) setDataPointPatrol(response.data.data);
    } catch (err) {
      if (err !== undefined) {
        toast.error(err.data.message);
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
  }, []);

  useEffect(() => {
    if (userData.role.role_name === 'ADMIN' || userData.role.role_name === 'CLIENT') {
      getClientCluster();
    }
  }, []);

  return (
    <Fragment>
      <BootstrapTable keyField='id' data={ role === ROLE_SUPER_ADMIN || role === ROLE_ADMIN  ? dataTitikPatroli  : dataPointPatrol } columns={ columns } />
      <DetailTitikPatroli modal={ isModal } toggle={ () => setIsModal(!isModal)  } detailCluster={ detailCluster } />
    </Fragment>
  );
}
