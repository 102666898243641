/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import IconClose from 'assets/img/icons/bi_x-lg.svg';
import { addDefaultSrc } from 'helpers/brokenImg';
import IconQuestion from 'assets/img/Icon-question.svg';
import { ButtonDefault } from 'components/Buttons/indes';
import { actionDeleteEvaluasi } from 'store/security/actions';
import { useState } from 'react';
import { useEffect } from 'react';

function ModalDeleteEvaluasi(props) {
  const { detail, setReRender } = props;
  const dispatch = useDispatch();
  const [evaluasiID, setEvaluasiID] = useState('');

  useEffect(() => {
    setEvaluasiID(detail.evaluasi_id);
  }, [props.isOpen]);

  const deleteEvaluasi = () => {
    dispatch(
      actionDeleteEvaluasi(evaluasiID, () => {
        props.toggle();
        setReRender();
      })
    );
  };

  return (
    <Fragment>
      <Modal
        size='md'
        isOpen={ props.isOpen }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <div className='p-4'>
          <div className='d-flex'>
            <div className='flex-grow-1'>
              <div className='d-flex'>
                <div
                  style={ { color: '#2E2D2D', fontSize: '16px' } }
                >
                  Hapus Evaluasi
                </div>
              </div>
            </div>
            <div onClick={ () => props.toggle() } style={ { cursor: 'pointer' } }>
              <img src={ IconClose } alt='icon' />
            </div>
          </div>
        </div>
        <ModalBody>
          <div className='my-3 text-center'>
            <img onError={ e => addDefaultSrc(e) } src={ IconQuestion } alt='Icon' />
            <div className='py-3'>
              { detail.desc_info }
            </div>
          </div>
          <div>
            <ButtonDefault
              name='Hapus'
              color='#fff'
              background='#FF7A80'
              onClick={ deleteEvaluasi }
            />
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default ModalDeleteEvaluasi;
