/* eslint-disable no-undef */
import React, { useState } from 'react';
import './login.css';
import { Row, Col, Button } from 'reactstrap';
import GardaLogo from '../../../../assets/img/brand/Logo.svg';
import { useFormik } from 'formik';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonSpiner } from 'components/Buttons/indes';
import InputsAuth from 'components/Forms/inputAuth';
import { actionResetPassword } from 'store/auth/actions';

function useQuery() {
  const search = useLocation().search;
  return new URLSearchParams(search).get('token');
}

function ResetPassword() {
  let token = useQuery();
  const history = useHistory();
  const [isLoading] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.authReducer.loadingResetPassword);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('The password is required'),
      confirmPassword: Yup.string()
        .required('The password confirmation is required')
        .oneOf([Yup.ref('password'), null], 'The password confirmation is not match with new password')
    }),
    onSubmit: async values => {
      const body = {
        token: token,
        password: values.password,
      };
      dispatch(
        actionResetPassword(body, () => {
          history.push('/login');
        })
      );
    },
  });

  return (
    <>
      <div>
        <ToastContainer />
        <Row className='containerlogin'>
          <Col className={ 'txtLoginpages' } />
          <Col className={ 'ctnLoginpages' }>
            <div className={ 'wrapps' }>
              <div className='d-flex justify-content-center sign'>
                <div className={ 'wrpPages' }>
                  <div className={ 'formUs' }>
                    <div style={ { marginTop: '20px' } }>
                      <center>
                        <img src={ GardaLogo } alt='logomonev' />
                      </center>
                      <div className='d-flex justify-content-center mt-4'>
                        <span className='title-register'>
                          RESET PASSWORD
                        </span>
                      </div>
                    </div>
                    <form onSubmit={ formik.handleSubmit }>
                      <div className='d-flex justify-content-center'>
                        <InputsAuth
                          formik={ formik }
                          placeholder='new password'
                          name='password'
                          type='password'
                        />
                      </div>
                      <div className='d-flex justify-content-center'>
                        <InputsAuth
                          formik={ formik }
                          placeholder='old password'
                          name='confirmPassword'
                          type='password'
                        />
                      </div>
                      <div className='d-flex'>
                        {
                          loading ? <ButtonSpiner /> :
                          <Button
                            className={ 'btnSubmit' }
                            color=''
                            type='submit'
                            disabled={ isLoading }
                          >
                            Reset Password
                          </Button>
                        }
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPassword;
