/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import '../../../../assets/css/main.css';
import '../../../../assets/css/component.css';
import { Container, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import TableArrowTerlapor from 'components/Molecules/Laporan/TableArrowTerlapor';
import { useFormik } from 'formik';
import ReactDatePicker from 'react-datepicker';
import SelectCustome from 'components/Select/selectCustome';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetPatroli } from 'store/patroli/actions';
import ModalTitikPatroliDownload from 'components/Molecules/Laporan/ModalTitikPatroliDownload';

function TemuanPatroli(props) {
  const dispatch = useDispatch();
  const { activeTab } = props;
  const [alert] = React.useState(null);
  const [dates, setDates] = useState(new Date());
  const listCluster = useSelector(state => state.clusterReducer.list_cluster);
  const dataPatroli = useSelector(state => state.patroliReducer.patroli);
  const total = useSelector(state => state.patroliReducer.total);
  const [isClusterId, setIsClusterId] = useState(0);
  const [isShowButtonDate, setIsShowButtonDate] = useState(true);
  const [isOpenDownload, setIsOpenDownload] = useState(false);

  useEffect(() => {
    if (listCluster.length > 0) {
      setIsClusterId(listCluster[0]?.cluster_id);
    }
  }, [activeTab, listCluster]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const optionListCluster = listCluster.map(val => {
    return {
      value: val.cluster_id,
      label: val.cluster_name,
    };
  });

  const handleButtonCluster = val => {
    setIsShowButtonDate(false);
    setIsClusterId(val.value);
    const date = moment(dates).format('YYYY-MM-DD');
    dispatch(actionGetPatroli(date, val.value));
  };

  const handleDate = val => {
    setDates(val);
    const date = moment(val).format('YYYY-MM-DD');
    dispatch(actionGetPatroli(date, isClusterId));
  };

  return (
    <Container className='mt--2 ' fluid>
      <div className='rows temuan-patroli-content'>
        <div className='col'>
          <div className='pb-6'>
            <Row className='align-items-center'>
              <div>
                <SelectCustome
                  labelHidden={ true }
                  formik={ formik }
                  placeholder='Cluster'
                  options={ optionListCluster }
                  name='nameCluter'
                  isCustome={ true }
                  onChange={ value => handleButtonCluster(value) }
                />
              </div>
              <div>
                <Button
                  color='primary'
                  onClick={ () => setIsOpenDownload(!isOpenDownload) }
                  disabled={!!isShowButtonDate}
                  >Download</Button>
              </div>
              <Col>
                <div className='wrap-datepick'>
                  <ReactDatePicker
                    selected={ dates }
                    onChange={ date => handleDate(date) }
                    dateFormat='yyyy-MM-dd'
                    className='datepicks'
                    disabled={ !!isShowButtonDate }
                  />
                </div>
              </Col>
              <Col>
                <span className='title-text'>Total Patroli</span>
                <span
                  className='px-2'
                  style={ {
                    color: '#6174DD',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  } }
                >
                  { total }
                </span>
              </Col>
            </Row>
            { dataPatroli.map((val, idx) => {
              return <TableArrowTerlapor data={ val } key={ idx } />;
            }) }
          </div>
        </div>
      </div>
      <ModalTitikPatroliDownload isOpen={ isOpenDownload } toggle={ () => setIsOpenDownload(!isOpenDownload) } clusterID={isClusterId} />
    </Container>
  );
}

export default TemuanPatroli;
