/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Modal, Row } from 'reactstrap';
import Select from 'react-select';
import ModalDeletePatrol from '../../../../components/Modal/DeletePatrol';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ButtonAdd } from 'components/Buttons/indes';
import FormAdd from './FormAdd';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateStatus } from '../../../../store/maps/actions';

import IconEdit from 'assets/img/icons/ant-design_edit-filled.png';
import IconTrash from 'assets/img/icons/bpmn_trash.svg';
import Map from 'components/Map';
import IconDownload from 'assets/img/icons/bi_cloud-download.svg';
import IconRefresh from 'assets/img/icons/ei_refresh.svg';
import ModalRegenerateQR from '../../../../components/Modal/RegenerateQRCode';
import { EDIT_PATROL_POINT } from '../../../../store/maps/actionTypes';

export default function DetailTitikPatroli(props) {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [regenerateModal, setRegenerateModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const detailPatrolPoint =  useSelector(state => state.mapsReducer.data);
  const [action, setAction] = useState('');

  const options = [
    { value: 'Aktif', label: 'Aktif', color: '#54D68E' },
    { value: 'Non Aktif', label: 'Non Aktif', color: '#9B9FB7' },
  ];

  const toogleDeleteModal = row => {
    setRowData(row);
    setDeleteModal(!deleteModal);
  };

  const tooglRegenerateModal = row => {
    setRowData(row);
    setRegenerateModal(!regenerateModal);
  };

  const handleEdit = row => {
    dispatch({
      type: EDIT_PATROL_POINT,
      payload: row
    });

    setActive(!active);
    setAction('Edit');
  };

  const updateStatus = (row, val) => {
    const objIndex = detailPatrolPoint.findIndex(val => val.titik_id === row.titik_id);
    detailPatrolPoint[objIndex].status = val.value;

    const formData = {
      'code': row.code,
      'status': val.value
    };

    dispatch(
        actionUpdateStatus(formData, () => {
          setReRender(!reRender);
        })
    );
  };

  const handleDownloadQRCode = row => {
    const url = `${process.env.REACT_APP_BE_URL}/patroli/titik/download/?code=` + row.code;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
  };

  const componentButton = (cell, row) => {
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',

      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    return (
      <div className='d-flex justify-content-center align-items-center'>
        <div className='mr-2 ml-1'>
          <Select
              defaultValue={
            options.filter(option =>
                option.value === row.status)
          }
            options={ options }
            components={ {
              IndicatorSeparator: () => null,
            } }
            styles={ {
              control: (styles, { }) => ({
                ...styles,
                borderWidth: '1px',
                borderRadius: '0.25rem',
                border: 'none'
              }),
              placeholder: styles => ({ ...styles, ...dot('#ccc') }),
              input: styles => ({ ...styles, paddingTop: '0.25rem', paddingBottom: '0.25rem' }),
              singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
            } }
            onChange={ val => updateStatus(row, val) }
          />
        </div>
        <div className='mr-2 ml-2'>
            <img
                style={ { cursor: 'pointer' } }
                src={ IconDownload } alt='icon'
                onClick={ () => handleDownloadQRCode(row) }
            />
        </div>
        <div className='mr-2 ml-2'>
            <img
                style={ { cursor: 'pointer' } }
                src={ IconRefresh } alt='icon'
                onClick={ () => tooglRegenerateModal(row) }
            />
        </div>
        <div className='mr-2 ml-2'>

          <img
              src={ IconEdit }
              style={ { cursor: 'pointer' } }
              onClick={ () => handleEdit(row) }
          />
        </div>
        <div className='mr-2 ml-2'>
          <img
              style={ { cursor: 'pointer' } }
              src={ IconTrash }
              onClick={ () => toogleDeleteModal(row) }
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'nama_titik',
      text: 'Titik',
    },
    {
      dataField: 'titik',
      text: 'QR CODE',
      formatter: componentButton,
    },
  ];

  useEffect(() => {
  }, [reRender, detailPatrolPoint]);

  return (
      <>
        <ModalDeletePatrol
            toogle={ toogleDeleteModal }
            modal={ deleteModal }
            data={ rowData }
            setReRender={ setReRender }
            rerender={ reRender }
            close={ () => props.toggle() }
         />
        <ModalRegenerateQR
            toogle={ tooglRegenerateModal }
            modal={ regenerateModal }
            data={ rowData }
            setReRender={ setReRender }
            rerender={ reRender }
            close={ () => props.toggle() }
        />
        <Modal
            size='xl'
            isOpen={ props.modal }
            toggle={ props.toggle }
            centered={ true }
            backdrop='static'
        >
          <Row>
            <Col>
              <Map reRender={ reRender } active={ active } action={ action }/>
            </Col>
            <Col>
              <div
                  className='d-flex justify-content-end pr-4 pt-3'
                  onClick={ () =>  { props.toggle(); setActive(false); } }
                  style={ { cursor: 'pointer' } }
              >
                <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                      d='M1.61647 1.61647C1.85088 1.38213 2.16877 1.25049 2.50022 1.25049C2.83168 1.25049 3.14956 1.38213 3.38397 1.61647L10.0002 8.23272L16.6165 1.61647C16.7318 1.49709 16.8697 1.40186 17.0222 1.33635C17.1747 1.27084 17.3387 1.23635 17.5047 1.23491C17.6707 1.23347 17.8353 1.2651 17.9889 1.32795C18.1425 1.3908 18.2821 1.48361 18.3995 1.60098C18.5168 1.71835 18.6096 1.85791 18.6725 2.01153C18.7353 2.16515 18.767 2.32975 18.7655 2.49573C18.7641 2.6617 18.7296 2.82572 18.6641 2.97823C18.5986 3.13073 18.5034 3.26867 18.384 3.38397L11.7677 10.0002L18.384 16.6165C18.6117 16.8522 18.7377 17.168 18.7348 17.4957C18.732 17.8235 18.6005 18.137 18.3687 18.3687C18.137 18.6005 17.8235 18.732 17.4957 18.7348C17.168 18.7377 16.8522 18.6117 16.6165 18.384L10.0002 11.7677L3.38397 18.384C3.14822 18.6117 2.83247 18.7377 2.50472 18.7348C2.17697 18.732 1.86346 18.6005 1.6317 18.3687C1.39994 18.137 1.26848 17.8235 1.26563 17.4957C1.26278 17.168 1.38877 16.8522 1.61647 16.6165L8.23272 10.0002L1.61647 3.38397C1.38213 3.14956 1.25049 2.83168 1.25049 2.50022C1.25049 2.16877 1.38213 1.85088 1.61647 1.61647Z'
                      fill='black'
                  />
                </svg>
              </div>
              <div style={ { padding: '10px' } }>
                <div className='mt-1 mb-2'>
                  <p>Titik Patroli</p>
                </div>
                <div className='mb-4 d-flex align-items-center'>
                  <div className='mr-3'>
                    <span>{ props?.detailCluster?.client?.client_name }</span>
                    <span className='pl-1 pr-1'>-</span>
                    <span>{ props?.detailCluster?.cluster_name }</span>
                  </div>
                  <div>
                    <ButtonAdd
                        name='Tambah Titik'
                        onClick={ () => {
                          setActive(!active);
                          setAction('Add');
                        } }
                        disabled={ !!active }
                    />
                  </div>
                </div>
                {
                  active ? (
                      <div>
                        <FormAdd
                            setReRender ={ () => setReRender(!reRender) }
                            setActive={ () => setActive(!active) }
                            clusterId ={ props?.detailCluster?.cluster_id }
                            action={ action }
                            setAction ={ () => setActive('') }
                            toggle={ props.toggle }
                        />
                      </div>
                  ) : (
                      <div>
                        <BootstrapTable
                            keyField='id'
                            data={ detailPatrolPoint }
                            columns={ columns }
                            reRender={ () => setReRender(!reRender) }
                        />
                      </div>
                  )
                }
              </div>
            </Col>
          </Row>
        </Modal>
      </>
  );
}


