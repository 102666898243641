/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

export default function Section2(props) {
  const {
    formik,
    day,
    setDay,
    month,
    setMonth,
    years,
    setYears
   } = props;
  return (
    <Fragment>
      <div className='wrap-form'>
        <Label>Tempat Tanggal Lahir</Label>
        <Input
          placeholder='Jl.Kemang Utara'
          type='text'
          name='bornplace'
          onChange={ formik.handleChange }
        />
        { formik.touched.bornplace && formik.errors.bornplace ? (
          <div style={ { color: 'red' } }>{ formik.errors.bornplace }</div>
        ) : null }
      </div>
      <Row>
        <Col>
          <div className='wrap-form'>
            <Label>Hari</Label>
            <DatePicker
              name='day'
              selected={ day }
              onChange={ date => {
                setDay(date);
                formik.setFieldValue('day', date);
              } }
              placeholderText='Hari'
              dateFormat='dd'
              showDayDropdown
              className='input-date-picker-customs'
            />
            { formik.touched.day && formik.errors.day ? (
              <div style={ { color: 'red' } }>{ formik.errors.day }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Bulan</Label>
            <DatePicker
              name='month'
              selected={ month }
              onChange={ date => {
                setMonth(date);
                formik.setFieldValue('month', date);
              } }
              dateFormat='MM'
              placeholderText='Bulan'
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
              className='input-date-picker-customs'
            />
            { formik.touched.month && formik.errors.month ? (
              <div style={ { color: 'red' } }>{ formik.errors.month }</div>
            ) : null }
          </div>
        </Col>
        <Col>
          <div className='wrap-form'>
            <Label>Tahun</Label>
            <DatePicker
              name='years'
              selected={ years }
              onChange={ date => {
                setYears(date);
                formik.setFieldValue('years', date);
              } }
              showYearPicker
              placeholderText='Tahun'
              dateFormat='yyyy'
              className='input-date-picker-customs'
            />
            { formik.touched.years && formik.errors.years ? (
              <div style={ { color: 'red' } }>{ formik.errors.years }</div>
            ) : null }
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
