import * as Yup from 'yup';

const clientAdd = Yup.object({
  client_name: Yup.string().required('Nama Perusahaan harus diisi'),
  client_email: Yup.string().email('Invalid email address').required('Email harus diisi'),
  client_phone: Yup.string().required('No Telphone harus diisi'),
  alamat: Yup.string().required('Alamat harus diisi'),
  kota: Yup.string().required('Kota harus diisi'),
  provinsi: Yup.string().required('Provinsi harus diisi'),
  kode_pos: Yup.string().required('Kode Pos harus diisi'),
  document_penunjang: Yup.string().required('Document Penunjang harus diisi'),
  image_link: Yup.string().required('Logo Perusahaan harus diisi'),
  jam_kerja: Yup.string().required('Jam Kerja harus diisi'),
  gaji_pokok: Yup.string().required('Gaji Pokok Sebelum 2 Tahun harus diisi'),
  gaji_pokok_2: Yup.string().required('Gaji Pokok Sebelum 2 Tahun harus diisi'),
  tunjangan_jabatan_danru: Yup.string().required('Tunjangan Jabatan Danru harus diisi'),
  tunjangan_jabatan_chief: Yup.string().required('Tunjangan Jabatan Chief harus diisi'),
  tunjangan_makan: Yup.string().required('Tunjangan Makan harus diisi'),
  tunjangan_kehadiran: Yup.string().required('Tunjangan Kehadiran harus diisi'),
  tunjangan_ops: Yup.string().required('Tunjangan Ops harus diisi'),
  tunjangan_lembur: Yup.string().required('Tunjangan Lembur harus diisi'),
  tunjangan_lain: Yup.string().required('Tunjangan Lain harus diisi'),
  bpjs_kesehatan: Yup.string().required('BPJS Kesehatan harus diisi'),
  bpjs_ketenagakerjaan: Yup.string().required('BPJS Ketenagakerjaan harus diisi'),
  potongan_absen: Yup.string().required('Potongan Absen harus diisi'),
  potongan_terlambat: Yup.string().required('Potongan Terlambat harus diisi'),
  potongan_seragam: Yup.string().required('Potongan Seragam harus diisi'),
  potongan_sertifikat: Yup.string().required('Potongan Sertifikat harus diisi'),
});

export default clientAdd;