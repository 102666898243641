/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import API from 'service/api/config';
import { LAPORAN_SUCCESS } from './actionTypes';


export const actionLaporan = (id, date, callBack) => async dispatch => {
    try {
      const res = await API.getListLaporan(id, date);
      if (res.status < 299) {
        dispatch({
          type: LAPORAN_SUCCESS,
          payload: res.data?.data
        });
        callBack(res.data?.data);
      }
    } catch (e) {
      if (e !== undefined) {
        if (e.status === 401) {
          toast.error(e.data.message);
        } else if (e.status === 500) {
          toast.error(e.data.message);
        } else if (e.status === 400) {
          toast.error(e.data.message);
        }
      } else {
        toast.error('Opsss. Something When Wrong !!!');
      }
    }
};

