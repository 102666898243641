import React, { Fragment } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

export default function Section1(props) {
  const { formik, disable } = props;
  return (
    <Fragment>
      <Label>Nama Lengkap</Label>
      <Input
        name='fullname'
        placeholder='Your Name'
        type='text'
        value={ formik.values.fullname }
        disabled={ disable }
        onChange={ formik.handleChange }
      />
      { formik.touched.fullname && formik.errors.fullname ? (
        <div style={ { color: 'red' } }>{ formik.errors.fullname }</div>
      ) : null }
      <div className='my-3'>Jenis Kelamin</div>
      <Row>
        <Col md={ 6 }>
          <input
            className='input-rad-btn'
            type='radio'
            name='gender'
            value='Pria'
            defaultChecked={ formik.values.gender === 'Pria' ? 'Pria' : null }
            onChange={ formik.handleChange }
            disabled={ disable }
          />
          <span className='txt-rad-btn'>Pria</span>
        </Col>
        <Col md={ 6 }>
          <input
            className='input-rad-btn'
            type='radio'
            name='gender'
            value='Wanita'
            defaultChecked={ formik.values.gender === 'Wanita' ? 'Wanita' : null }
            onChange={ formik.handleChange }
            disabled={ disable }
          />
          <span className='txt-rad-btn'>Wanita</span>
        </Col>
      </Row>
      { formik.touched.gender && formik.errors.gender ? (
        <div style={ { color: 'red' } }>{ formik.errors.gender }</div>
      ) : null }
    </Fragment>
  );
}
