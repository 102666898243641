/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Row,
  Col,
} from 'reactstrap';
import ModalTambahCluster from './TambahCluster';
import API from 'service/api/config';
import moment from 'moment';
import ModalEditEvaluasi from 'components/Molecules/Scurity/ModalEditEvaluasi';
import ModalDeleteEvaluasi from 'components/Molecules/Scurity/DeleteEvaluasi';
import { addDefaultSrc } from 'helpers/brokenImg';

function ModalEditClient(props) {
  const userData = props?.data;
  const [modalTambahCluster, setModalTambahCluster] = useState(false);
  const [evaluasi, setEvaluasi] = useState([]);
  const [user, setUser] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [detailEditEvaluasi, setDetailEditEvaluasi] = useState({});
  const [isOpenEditEvaluasi, setIsOpenEditEvaluasi] = useState(false);
  const [isOpenDeleteEvaluasi, setIsOpenDeleteEvaluasi] = useState(false);

  const toggleModalTambahCluster = () => {
    setModalTambahCluster(!modalTambahCluster);
  };
  useEffect(() => {
    if (Object.keys(userData).length) {
      API.getUserProfileDetails(props?.data).then(res => {
        setUser(res?.data?.data);
      });
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      API.getEvaluasi(userData).then(res => {
        setEvaluasi(res?.data?.data);
      });
    }
  }, [userData, reRender]);

  const handleEdit = val => {
    setDetailEditEvaluasi(val);
    setIsOpenEditEvaluasi(true);
  };

  const handleDelete = val => {
    setDetailEditEvaluasi(val);
    setIsOpenDeleteEvaluasi(true);
  };

  return (
    <div>
      <ModalTambahCluster
        toggle={ toggleModalTambahCluster }
        modal={ modalTambahCluster }
        cluster={ props }
      />
      <ModalEditEvaluasi
        isOpen={ isOpenEditEvaluasi }
        toggle={ () => setIsOpenEditEvaluasi(!isOpenEditEvaluasi) }
        detail={ detailEditEvaluasi }
        setReRender={ () => setReRender(!reRender) }
      />
      <ModalDeleteEvaluasi
        isOpen={ isOpenDeleteEvaluasi }
        toggle={ () => setIsOpenDeleteEvaluasi(!isOpenDeleteEvaluasi) }
        detail={ detailEditEvaluasi }
        setReRender={ () => setReRender(!reRender) }
      />
      <Modal
        size='lg'
        isOpen={ props.modal }
        toggle={ props.toggle }
        centered={ true }
        backdrop='static'
      >
        <ModalHeader toggle={ props.toggle } />
        <ModalBody>
          <div className='wrap-content-edit-modal'>
            <Row xs='12'>
              <Col>
                <div className='wrap-head-modal-table'>
                  <div className='img-modal-edit-client'>
                    <img
                      onError={ e => addDefaultSrc(e) }
                      src={
                        `${process.env.REACT_APP_BE_URL}/` + user[0]?.image_link
                      }
                      alt='img_logo_client'
                    />
                  </div>
                  <div className='txt-modal-eval'>
                    <span>{ user[0]?.name }</span>
                    <span style={ { fontSize: '10px' } }>
                      { user[0]?.role?.role_name }
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className='txt-modal-edit-client'>
                  <span>Evaluasi</span>
                </div>
              </Col>
            </Row>
          </div>

          <Table striped>
            <thead>
              <tr>
                <th style={ { width: '10%' } }>Tanggal</th>
                <th style={ { width: '20%' } }>Evaluasi Oleh</th>
                <th style={ { width: '50%' } }>Evaluasi</th>
                <th />
              </tr>
            </thead>
            <tbody>
              { evaluasi?.map((val, idx) => {
                return (
                  <tr key={ idx }>
                    <td scope='row'>
                      { moment(val.createDateTime).format('YYYY-MM-DD') }
                    </td>
                    <td>{ val?.evaluated_by?.name }</td>
                    <td>{ val.desc_info }</td>
                    <td>
                      <button
                        onClick={ () => handleEdit(val) }
                        style={ {
                          backgroundColor: 'transparent',
                          outline: 'none',
                          border: 'none',
                        } }
                      >
                        <i
                          className='fas fa-edit'
                          style={ { color: 'rgb(155, 159, 183)' } }
                        />
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={ () => handleDelete(val) }
                        style={ {
                          backgroundColor: 'transparent',
                          outline: 'none',
                          border: 'none',
                        } }
                      >
                        <i
                          className='fas fa-trash-alt'
                          style={ { color: 'rgb(155, 159, 183)' } }
                        />
                      </button>
                    </td>
                  </tr>
                );
              }) }
            </tbody>
          </Table>
        </ModalBody>
        { /* <ModalFooter className='btn-modal-wrapper'>
          <div className='wrap-btn-modal-footer'>
            <button
              type='button'
              onClick={ onSubmitPengguna }
              className='btn btn-modal btn-rounded'
            >
              Simpan
            </button>
          </div>
        </ModalFooter> */ }
      </Modal>
    </div>
  );
}

export default ModalEditClient;
